import lesson_34_kaiwa from "../../../assets/mnn/34-kaiwa.mp3";
import lesson_34_mondai_1 from "../../../assets/mnn/34-mondai-1.mp3";
import lesson_34_mondai_2 from "../../../assets/mnn/34-mondai-2.mp3";

import { MnnAudioFileType } from "../mnnAudioFiles";

export const mnnLesson34: MnnAudioFileType[] = [
  {
    fileName: '会話',
    file: lesson_34_kaiwa,
    lessonNumber: 34,
    type: '教科書',
  },
  {
    fileName: '問題　１番',
    file: lesson_34_mondai_1,
    lessonNumber: 34,
    type: '教科書',
  },
  {
    fileName: '問題　２番',
    file: lesson_34_mondai_2,
    lessonNumber: 34,
    type: '教科書',
  },
];