import React, { useEffect, useState, useMemo } from 'react';
import { Grid, Button } from "@mui/material";
import { JP, RUS, Accent, GrammarTable, StyledDialogTitle } from "../../style";
import { SelectInput } from "../select-input/SelectInput";

export const ArticlesPage: React.FC = () => {
  return (
    <Grid
      container
      alignItems='center'
      columnSpacing={2}
      rowSpacing={2}
    >
      <Grid item xs={12}>
        <JP>〜てはいけない</JP>{' '}<RUS>запрет</RUS><br />
        <JP>〜<Accent>なく</Accent>てはいけない</JP>{' '}<RUS>долженствование (синоним <JP>〜なければ　ならない</JP>)</RUS><br />
        <br />
        <JP>いけない</JP>{' '}<RUS>меняется на</RUS>{' '}<JP>だめ</JP>
        <br /><br />
        <JP>
          すいすい・すらすら・すたすた・すやすや
        </JP><br /><br />
        <JP>
          面接のとき質問に<Accent>すらすら</Accent>答えられた。
        </JP><br />
        <JP>→<Accent>すらすら</Accent></JP>{' '}
        <RUS>smoothly, easily, readily;</RUS>{' '}
        <JP>物事（ものごと）が滞りなく（とどこおりなく）なめらかに進行（しんこう）するさま。＜すらすら答える＞</JP>

        <br /><br />
        <JP><Accent>すいすい</Accent>泳げるようになった。</JP><br />
        <JP>→<Accent>すいすい</Accent></JP>{' '}
        <RUS>lightly and smoothly (of movements), swiftly, glidingly;</RUS>{' '}
        <JP>いかにも軽やか（かるやか）に、すばやく動く（うごく）さま。</JP>
        <br />
      </Grid>
    </Grid>
  );
};