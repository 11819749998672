import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ChildRouteType, RouteType, RoutesContext } from "./RoutesContext";
import { Loader } from "../components/loader/Loader";
import { AccessCheck } from "./AccessCheck";
import { RequireAuth } from "./RequireAuth";
import { PersistLogin } from "../components/login/PersistLogin";
import { MainContainer } from "../components/MainContainer";

const MainLazy = React.lazy(() => {
  return import('../Main').then(module => ({
    default: module.Main,
  }));
});

export const RoutesWrapper: React.FC = () => {
  const renderChildRoute = (
    route: ChildRouteType,
    i: number
  ): React.ReactNode => {
    return (
      <Route
        key={`${route.path}-${i}`}
        path={route.path}
        element={
          route.hasSuspense ? (
            <Suspense fallback={<Loader type='ThreeDots' />}>
              {route.element}
            </Suspense>
          ) : (
            route.element
          )
        }
      >
        <>
          {route.childRoutes &&
            route.childRoutes.map((anotherChildRoute, i) => {
              // Recursion, each route can be nested with child routes
              return renderChildRoute(anotherChildRoute, i);
            })
          }
        </>
      </Route>
    );
  };

  const renderRoutes = (routes: RouteType[]): React.ReactNode => {
    return routes.map((route, i) => {
      const {path, content, hasSuspense, roles} = route;
      return (

          <Route key={`${route.path}-${i}`} element={<RequireAuth allowedRoles={roles} />}>
            <Route
              path={path}
              element={
                hasSuspense ? (
                  <Suspense fallback={<Loader type='ThreeDots' />}>
                    {
                      // TODO Add AuthorisedComponent
                    }

                    {content || null}

                  </Suspense>
                ) : (
                  <div>
                    {
                      // TODO Add AuthorisedComponent
                    }
                    {content || null}
                  </div>
                )
              }
            >
              <>
                {route.childRoutes &&
                  route.childRoutes.map((childRoute, i) => {
                    return renderChildRoute(childRoute, i);
                  })
                }
              </>
            </Route>
          </Route>

      );
    });
  }

  return (
    <>
      <RoutesContext.Consumer>
        {({routes}) => {
          return (
            <Routes>
              <Route path='/' element={<MainLazy />}>
                <Route path='/' element={<MainContainer />} />
                <Route element={<PersistLogin />}>
                  {renderRoutes(routes)}
                </Route>
              </Route>
            </Routes>
          );
        }}
      </RoutesContext.Consumer>
    </>
  );
};