import lesson_38_kaiwa from "../../../assets/mnn/38-kaiwa.mp3";
import lesson_38_mondai_1 from "../../../assets/mnn/38-mondai-1.mp3";
import lesson_38_mondai_2 from "../../../assets/mnn/38-mondai-2.mp3";

import { MnnAudioFileType } from "../mnnAudioFiles";

export const mnnLesson38: MnnAudioFileType[] = [
  {
    fileName: '会話',
    file: lesson_38_kaiwa,
    lessonNumber: 38,
    type: '教科書',
  },
  {
    fileName: '問題　１番',
    file: lesson_38_mondai_1,
    lessonNumber: 38,
    type: '教科書',
  },
  {
    fileName: '問題　２番',
    file: lesson_38_mondai_2,
    lessonNumber: 38,
    type: '教科書',
  },
];