import React, { useState, useMemo, useCallback } from 'react';
import { Grid, Avatar } from "@mui/material";
import _find from 'lodash/find';


import tenki from "../../assets/tenki.jpeg";

import { JP, Accent, StyledWordsTable, Level } from "../../style";
import { Line } from './Line';
import { kanjiList } from "./list";
import {WordType, TextbookMenuType, KanjiType} from "./types";
import { KanjiCard } from "./KanjiCard";
import { SelectInput } from "../select-input/SelectInput";
import { onlyUnique } from "../../utils/helpers";

const textbooks: TextbookMenuType[] = [
  {
    name: 'Kanji Book',
    lessons: [
      {
        lessonNumber: 9,
        kanjiList: ['行', '来', '帰', '食', '飲', '見', '聞', '読', '書', '話', '買', '教'],
      },
      {
        lessonNumber: 10,
        kanjiList: ['朝', '昼', '夜', '晩', '夕', '方', '午', '前', '後', '毎', '週', '曜'],
      },
      {
        lessonNumber: 17,
        kanjiList: ['出', '入', '乗', '降', '着', '渡', '通', '走', '歩', '止', '動', '働'],
      }
    ],
  },
  {
    name: 'Genki Kanji (512)',
    lessons: [
      {
        lessonNumber: 1,
        kanjiList: ['一','二','三','四','五','六','七','八','九','十','百','千','万','円','口','目'],
      }
    ],
  },
  {
    name: 'N2漢字５５０',
    lessons: [
      {
        lessonNumber: 1,
        kanjiList: ['仏','仲','似','仮','伸',],
      },
      {
        lessonNumber: 2,
        kanjiList: ['伺','依','俳','候','倒',],
      },
      {
        lessonNumber: 3,
        kanjiList: ['偶','偉','傷','催','促',],
      },
      {
        lessonNumber: 4,
        kanjiList: ['傾','僚','僕','象','象',],
      },
      {
        lessonNumber: 22,
        kanjiList: ['穏','隠',],
      },
    ],
  },
  {
    name: 'Compass Japanese Intermediate',
    lessons: [
      {
        lessonNumber: 1,
        kanjiList: ['活','便','利','変','動','画','電','選',],
      },
    ],
  },
  {
    name: 'ストーリで覚える漢字３００',
    lessons: [
      {
        lessonNumber: 1,
        kanjiList: ['一','二','三','山','川','目','口','人','本','休','田','力','男','女','安'],
      },
      {
        lessonNumber: 2,
        kanjiList: ['上','下','中','大','太','小','少','入','出','子','学','四','五','六','七','八','九','十','古'],
      },
      {
        lessonNumber: 3,
        kanjiList: ['百','千','万','円','日','月','明','立','音','暗','火','水','土','国','全','金','工','左','右','友'],
      },
      {
        lessonNumber: 4,
        kanjiList: ['何','手','切','分','今','半','止','正','歩','足','走','起','夕','外','多','名','夜','生'],
      },
    ],
  },
  {
    name: 'スピードマスターN3',
    lessons: [
      {
        lessonNumber: 1,
        kanjiList: ['飛','機','空','港','預','込','荷','検','査',],
      },
      {
        lessonNumber: 2,
        kanjiList: ['満','席','番','号','路','側','座','窓',],
      },
    ],
  },
];

export const Kanji: React.FC = () => {
  const [selectedTextbook, setSelectedTextbook] = useState<TextbookMenuType | null>(textbooks[0] || null);
  //const [lessonNumbers, setLessonNumbers] = useState<number[]>([]);
  const [selectedLessonNumber, setSelectedLessonNumber] = useState<number | null>(null);

  const handleSelectedTextbookChange = (value: string): void => {
    const list: TextbookMenuType[] = [];
    textbooks.map((item) => {
      if (item.name === value) {
        list.push(item);
      }
    });
    setSelectedTextbook(list[0]);
  };

  const handleSelectedLessonNumberChange = useCallback((value: string): void => {
    if (selectedTextbook) {
      setSelectedLessonNumber(parseInt(value));
    } else {
      setSelectedLessonNumber(null);
    }
  }, [selectedTextbook]);

  const visibleKanjiList = useMemo(() => {
    // Get selected kanji list
    //kanjiList
    let selectedKanjiList: KanjiType[] = [];
    if (selectedTextbook) {
      selectedTextbook.lessons.map((item) => {
        if (item.lessonNumber === selectedLessonNumber) {
          // Target lesson
          item.kanjiList.map((kanji) => {
            const found = _find(kanjiList, (item) => {
              return item.kanji === kanji;
            });
            if (found) {
              selectedKanjiList.push(found);
            } else {
              console.error(`Cannot find ${kanji} kanji in Lesson ${selectedLessonNumber}.`);
            }
          });
        }
      });
      return selectedKanjiList;
    } else return [];
  }, [selectedLessonNumber, selectedTextbook]);

  const textbooksNames = useMemo(() => {
    const list: string[] = [];
    textbooks.map((item) => {
      list.push(item.name);
    });
    return list.filter(onlyUnique);
  }, [selectedTextbook]);

  const lessonNumbers = useMemo(() => {
    if (selectedTextbook) {
      const lessonNumbers = selectedTextbook.lessons.map((item) => {
        return item.lessonNumber;
      });
      return lessonNumbers.filter(onlyUnique);
    } else return [];
  }, [selectedTextbook]);

  //console.log('selectedLessonNumber', selectedLessonNumber);
  //console.log('visibleKanjiList', visibleKanjiList);

  return (
    <>
      <Grid
        container
        wrap='wrap'
        alignItems='stretch'
        alignContent='center'
        columnSpacing={2}
        rowSpacing={2}
      >
        {
          // TODO добавить уровень иероглифа
        }
        <Grid item xs={12}>
          <SelectInput
            label='教科書'
            list={textbooksNames}
            selectedValue={selectedTextbook?.name || ''}
            handleSelectedValueChange={handleSelectedTextbookChange}
          />
        </Grid>
        {
          (lessonNumbers.length > 0) &&
          <Grid item xs={12}>
            <SelectInput
              label='課'
              list={lessonNumbers}
              selectedValue={selectedLessonNumber?.toString() || ''}
              handleSelectedValueChange={handleSelectedLessonNumberChange}
            />
          </Grid>
        }
        <Grid item xs={12}>
          Общее количество кандзи: {kanjiList.length}<br />
          Выбрано кандзи: {visibleKanjiList.length}
        </Grid>
        {
          visibleKanjiList.map((item, i) => {
            return (
              <KanjiCard
                key={item.kanji}
                index={i}
                totalLength={visibleKanjiList.length}
                kun={item.kun}
                on={item.on}
                kanji={item.kanji}
                images={item.images}
                kanjiImage={item.kanjiImage}
                kanjiImageFrames={item.kanjiImageFrames}
                words={item.words}
                level={item.level}
              />
            )
          })
        }
      </Grid>
    </>
  );
};