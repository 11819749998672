import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const にしては: OneGrammarType = {
  name: '〜にしては',
  level: 'N2',
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <RUS>
          Переводится как <b>для (кого-то, такого как)</b>, англ. <b>for someone who ...</b><br />
          <JP>
            前から持っていたイメージと実際が違うことを表す表現。
          </JP>
          <br />
          <RUS>
           Грамматика противопоставления (частица <JP>は</JP> является противопоставлением),
           мы противопоставляем наше предыдущее представление о чём-то тому, что есть сейчас.
          </RUS>
          <br />
          Used to express the difference between the previous image one has and the reality.
          <br />
          Существительное (например, <JP>森さん</JP>)<JP>＋にしては</JP><br />
          <br />
        </RUS>
      ),
      translation: (
        <RUS>
          <JP>物事（ものごと）</JP> things, everything<br/>
          <JP>議論（ぎろん）</JP> argument, споры<br/>
          <JP>対立（たいりつ）</JP> confrontation, противостояние<br/>
          <JP>代表（だいひょう）</JP> representative, delegate<br/>
        </RUS>
      ),
    },
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <>
          <JP>
            初めて<Accent>にしては</Accent>、
            上手ですね。
          </JP><br />
          <RUS>
            You're really good for a first-timer.
          </RUS>
        </>
      ),
      translation: (
        <RUS>
          <JP>初めて（はじめて）</JP> for the first time<br/>
        </RUS>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <>
          <JP>
            A:いつも派手な森さん<Accent>にしては</Accent>、
            今日はずいぶん地味な服だね。<br />
            B:今日はアルバイトの面接に行くんだって。
          </JP><br />
          <RUS>
            A: He usually dresses quite flamboyantly, but Mori-san's outfit today is really subdued.<br />
            B: He said he's going to a part-time job interview today.
          </RUS>
        </>
      ),
      translation: (
        <RUS>
          <JP>派手（はで）な</JP> showy, loud (кричащая одежда)<br/>
          <JP>地味（じみ）な</JP> plain<br/>
          <JP>森（もり）さん</JP> <br/>
          <JP>服（ふく）</JP> одежда<br/>
          <JP>面接（めんせつ）</JP> собеседование<br/>
        </RUS>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 3,
      content: (
        <>
          <JP>
            あの子たち、高校生<Accent>にしては</Accent>、
            顔が大人っぽいけど、しゃべると、やっぱり高校生だね。
          </JP><br />
          <RUS>
            Those kids look pretty mature for high school students,
            but when you tray talking to them, it's clear that they're still kids.
          </RUS>
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 4,
      content: (
        <>
          <JP>
            これ、ピカソの絵？ピカソ<Accent>にしては</Accent>、
            けっこう普通の絵だね。
          </JP><br />
          <RUS>
            This is a Picasso painting? It's kind of pedestrian for a Picasso.
          </RUS>
        </>
      ),
    },
  ],
};