import { KanjiType } from "./types";
import haru from '../../assets/kanji/haru.gif';
import haruFrames from '../../assets/kanji/haru_frames.png';
import yomu from '../../assets/kanji/yomu.gif';
import yomuFrames from '../../assets/kanji/yomu_frames.png';
import koto from "../../assets/kanji/koto.gif";
import kotoFrames from "../../assets/kanji/koto_frames.png";
import kaku from '../../assets/kanji/kaku.gif';
import kakuFrames from '../../assets/kanji/kaku_frames.png';
import miru from '../../assets/kanji/miru.gif';
import miruFrames from '../../assets/kanji/miru_frames.png';
import iku from '../../assets/kanji/iku.gif';
import ikuFrames from '../../assets/kanji/iku_frames.png';
import kuru from '../../assets/kanji/kuru.gif';
import kuruFrames from '../../assets/kanji/kuru_frames.png';
import kaeru from '../../assets/kanji/kaeru.gif';
import kaeruFrames from '../../assets/kanji/kaeru_frames.png';
import taberu from '../../assets/kanji/taberu.gif';
import taberuFrames from '../../assets/kanji/taberu_frames.png';
import nomu from '../../assets/kanji/nomu.gif';
import nomuFrames from '../../assets/kanji/nomu_frames.png';
import kiku from '../../assets/kanji/kiku.gif';
import kikuFrames from '../../assets/kanji/kiku_frames.png';
import hanasu from '../../assets/kanji/hanasu.gif';
import hanasuFrames from '../../assets/kanji/hanasu_frames.png';
import kau from '../../assets/kanji/kau.gif';
import kauFrames from '../../assets/kanji/kau_frames.png';
import oshieru from '../../assets/kanji/oshieru.gif';
import oshieruFrames from '../../assets/kanji/oshieru_frames.png';
import asa from '../../assets/kanji/asa.gif';
import asaFrames from '../../assets/kanji/asa_frames.png';
import hiru from '../../assets/kanji/hiru.gif';
import hiruFrames from '../../assets/kanji/hiru_frames.png';
import ban from '../../assets/kanji/ban.gif';
import banFrames from '../../assets/kanji/ban_frames.png';
import yuugata from '../../assets/kanji/yuugata.gif';
import yuugataFrames from '../../assets/kanji/yuugata_frames.png';
import yoru from '../../assets/kanji/yoru.gif';
import yoruFrames from '../../assets/kanji/yoru_frames.png';
import houhou from '../../assets/kanji/houhou.gif';
import houhouFrames from '../../assets/kanji/houhou_frames.png';
import gogo from '../../assets/kanji/gogo.gif';
import gogoFrames from '../../assets/kanji/gogo_frames.png';
import mae from '../../assets/kanji/mae.gif';
import maeFrames from '../../assets/kanji/mae_frames.png';
import ato from '../../assets/kanji/ato.gif';
import atoFrames from '../../assets/kanji/ato_frames.png';
import 働 from '../../assets/kanji/働.gif';
import 働Frames from '../../assets/kanji/働_frames.png';
import 動 from '../../assets/kanji/動.gif';
import 動Frames from '../../assets/kanji/動_frames.png';
import 一 from '../../assets/kanji/n5/一.gif';
import 一Frames from '../../assets/kanji/n5/一frames.png';
import 一Genki from '../../assets/kanji/n5/一genki.png';
import 二 from '../../assets/kanji/n5/二.gif';
import 二Frames from '../../assets/kanji/n5/二frames.png';
import 二Genki from '../../assets/kanji/n5/二genki.png';
import 三 from '../../assets/kanji/n5/三.gif';
import 三Frames from '../../assets/kanji/n5/三frames.png';
import 三Genki from '../../assets/kanji/n5/三genki.png';
import 四 from '../../assets/kanji/n5/四.gif';
import 四Frames from '../../assets/kanji/n5/四frames.png';
import 五 from '../../assets/kanji/n5/五.gif';
import 五Frames from '../../assets/kanji/n5/五frames.png';
import 六 from '../../assets/kanji/n5/六.gif';
import 六Frames from '../../assets/kanji/n5/六frames.png';
import 七 from '../../assets/kanji/n5/七.gif';
import 七Frames from '../../assets/kanji/n5/七frames.png';
import 八 from '../../assets/kanji/n5/八.gif';
import 八Frames from '../../assets/kanji/n5/八frames.png';
import 九 from '../../assets/kanji/n5/九.gif';
import 九Frames from '../../assets/kanji/n5/九frames.png';
import 十 from '../../assets/kanji/n5/十.gif';
import 十Frames from '../../assets/kanji/n5/十frames.png';
import 百 from '../../assets/kanji/n5/百.gif';
import 百Frames from '../../assets/kanji/n5/百frames.png';
import 千 from '../../assets/kanji/n5/千.gif';
import 千Frames from '../../assets/kanji/n5/千frames.png';
import 万 from '../../assets/kanji/n5/万.gif';
import 万Frames from '../../assets/kanji/n5/万frames.png';
import 円 from '../../assets/kanji/n5/円.gif';
import 円Frames from '../../assets/kanji/n5/円frames.png';

import 山 from '../../assets/kanji/n5/山.gif';
import 山Frames from '../../assets/kanji/n5/山frames.png';
import 川 from '../../assets/kanji/n5/川.gif';
import 川Frames from '../../assets/kanji/n5/川frames.png';
import 人 from '../../assets/kanji/n5/人.gif';
import 人Frames from '../../assets/kanji/n5/人frames.png';
import 休 from '../../assets/kanji/n5/休.gif';
import 休Frames from '../../assets/kanji/n5/休frames.png';
import 力 from '../../assets/kanji/n5/力.gif';
import 力Frames from '../../assets/kanji/n5/力frames.png';
import 男 from '../../assets/kanji/n5/男.gif';
import 男Frames from '../../assets/kanji/n5/男frames.png';
import 女 from '../../assets/kanji/n5/女.gif';
import 女Frames from '../../assets/kanji/n5/女frames.png';
import 安 from '../../assets/kanji/n5/安.gif';
import 安Frames from '../../assets/kanji/n5/安frames.png';
import 本 from '../../assets/kanji/n5/本.gif';
import 本Frames from '../../assets/kanji/n5/本frames.png';
import 田 from '../../assets/kanji/n5/田.gif';
import 田Frames from '../../assets/kanji/n5/田frames.png';
import 目 from '../../assets/kanji/n5/目.gif';
import 目Frames from '../../assets/kanji/n5/目frames.png';
import 目Genki from '../../assets/kanji/n5/目genki.png';
import 口 from '../../assets/kanji/n5/口.gif';
import 口Frames from '../../assets/kanji/n5/口frames.png';
import 口Genki from '../../assets/kanji/n5/口genki.png';
import 穏 from '../../assets/kanji/n2/穏.gif';
import 穏Frames from '../../assets/kanji/n2/穏frames.png';
import 隠 from '../../assets/kanji/n2/隠.gif';
import 隠Frames from '../../assets/kanji/n2/隠frames.png';

import 仏 from '../../assets/kanji/n2/仏.gif';
import 仏Frames from '../../assets/kanji/n2/仏frames.png';
import 仲 from '../../assets/kanji/n2/仲.gif';
import 仲Frames from '../../assets/kanji/n2/仲frames.png';
import 似 from '../../assets/kanji/n2/似.gif';
import 似Frames from '../../assets/kanji/n2/似frames.png';
import 仮 from '../../assets/kanji/n2/仮.gif';
import 仮Frames from '../../assets/kanji/n2/仮frames.png';
import 伸 from '../../assets/kanji/n2/伸.gif';
import 伸Frames from '../../assets/kanji/n2/伸frames.png';
import 伺 from '../../assets/kanji/n2/伺.gif';
import 伺Frames from '../../assets/kanji/n2/伺frames.png';
import 依 from '../../assets/kanji/n2/依.gif';
import 依Frames from '../../assets/kanji/n2/依frames.png';
import 俳 from '../../assets/kanji/n2/俳.gif';
import 俳Frames from '../../assets/kanji/n2/俳frames.png';
import 候 from '../../assets/kanji/n2/候.gif';
import 候Frames from '../../assets/kanji/n2/候frames.png';
import 倒 from '../../assets/kanji/n2/倒.gif';
import 倒Frames from '../../assets/kanji/n2/倒frames.png';

import 飛 from '../../assets/kanji/n3/飛.gif';
import 飛Frames from '../../assets/kanji/n3/飛frames.png';
import 機 from '../../assets/kanji/n3/機.gif';
import 機Frames from '../../assets/kanji/n3/機frames.png';
import 空 from '../../assets/kanji/n3/空.gif';
import 空Frames from '../../assets/kanji/n3/空frames.png';
import 港 from '../../assets/kanji/n3/港.gif';
import 港Frames from '../../assets/kanji/n3/港frames.png';
import 預 from '../../assets/kanji/n3/預.gif';
import 預Frames from '../../assets/kanji/n3/預frames.png';
import 込 from '../../assets/kanji/n3/込.gif';
import 込Frames from '../../assets/kanji/n3/込frames.png';
import 荷 from '../../assets/kanji/n3/荷.gif';
import 荷Frames from '../../assets/kanji/n3/荷frames.png';
import 査 from '../../assets/kanji/n3/査.gif';
import 査Frames from '../../assets/kanji/n3/査frames.png';
import 検 from '../../assets/kanji/n3/検.gif';
import 検Frames from '../../assets/kanji/n3/検frames.png';
import 検keyToKanji from '../../assets/kanji/n3/検key_to_kanji.png';
import 満 from '../../assets/kanji/n3/満.gif';
// TODO 満
import 席 from '../../assets/kanji/n3/席.gif';
import 席Frames from '../../assets/kanji/n3/席frames.png';
import 番 from '../../assets/kanji/n3/番.gif';
import 番Frames from '../../assets/kanji/n3/番frames.png';
import 号 from '../../assets/kanji/n3/号.gif';
import 号Frames from '../../assets/kanji/n3/号frames.png';
import 路 from '../../assets/kanji/n3/路.gif';
import 路Frames from '../../assets/kanji/n3/路frames.png';
import 側 from '../../assets/kanji/n3/側.gif';
import 側Frames from '../../assets/kanji/n3/側frames.png';
import 座 from '../../assets/kanji/n3/座.gif';
import 座Frames from '../../assets/kanji/n3/座frames.png';
import 窓 from '../../assets/kanji/n3/窓.gif';
import 窓Frames from '../../assets/kanji/n3/窓frames.png';

// TODO Add order
// TODO Check for duplicates
export const kanjiList: KanjiType[] = [
  {
    kanji: '倒',
    level: 2,
    kanjiImage: 倒,
    kanjiImageFrames: 倒Frames,
    words: [
      {
        word: '倒す　たおす（他）',
        translation: 'knock over',
        level: 2,
      },
      {
        word: '倒れる　たおれる（自）',
        translation: 'fall over',
        level: 2,
      },
      {
        word: '倒産　とうさん',
        translation: 'bankruptcy',
        level: 2,
      },
      {
        word: '面倒　めんどう',
        translation: 'trouble',
        level: 2,
      },
      {
        word: '面倒くさい　めんどうくさい',
        translation: 'bothersome',
        level: 2,
      },
    ],
    textbooks: [],
    images: [
    ],
  },
  {
    kanji: '候',
    level: 2,
    kanjiImage: 候,
    kanjiImageFrames: 候Frames,
    words: [
      {
        word: '気候　きこう',
        translation: 'climate',
        level: 2,
      },
      {
        word: '候補　こうほ',
        translation: 'candidate',
        level: 2,
      },
      {
        word: '候補者　こうほしゃ',
        translation: 'candidate',
        level: 2,
      },
      {
        word: '天候　てんこう',
        translation: 'weather',
        level: 2,
      },
    ],
    textbooks: [],
    images: [
    ],
  },
  {
    kanji: '俳',
    level: 2,
    kanjiImage: 俳,
    kanjiImageFrames: 俳Frames,
    words: [
      {
        word: '俳優　はいゆう',
        translation: 'actor',
        level: 2,
      },
      {
        word: '俳句　はいく',
        translation: 'haiku poem',
        level: 2,
      }
    ],
    textbooks: [],
    images: [
    ],
  },
  {
    kanji: '依',
    level: 2,
    kanjiImage: 依,
    kanjiImageFrames: 依Frames,
    words: [
    ],
    textbooks: [],
    images: [
    ],
  },
  {
    kanji: '伺',
    level: 2,
    kanjiImage: 伺,
    kanjiImageFrames: 伺Frames,
    words: [
    ],
    textbooks: [],
    images: [
    ],
  },
  {
    kanji: '伸',
    level: 2,
    kanjiImage: 伸,
    kanjiImageFrames: 伸Frames,
    words: [
    ],
    textbooks: [],
    images: [
    ],
  },
  {
    kanji: '仮',
    level: 2,
    kanjiImage: 仮,
    kanjiImageFrames: 仮Frames,
    words: [
    ],
    textbooks: [],
    images: [
    ],
  },
  {
    kanji: '似',
    level: 2,
    kanjiImage: 似,
    kanjiImageFrames: 似Frames,
    words: [
    ],
    textbooks: [],
    images: [
    ],
  },
  {
    kanji: '仲',
    level: 2,
    kanjiImage: 仲,
    kanjiImageFrames: 仲Frames,
    words: [
    ],
    textbooks: [],
    images: [
    ],
  },
  {
    kanji: '仏',
    level: 2,
    kanjiImage: 仏,
    kanjiImageFrames: 仏Frames,
    words: [
    ],
    textbooks: [],
    images: [
    ],
  },
  {
    kanji: '穏',
    level: 2,
    kun: ['おだ',],
    on: ['おん',],
    kanjiImage: 穏,
    kanjiImageFrames: 穏Frames,
    words: [
      {
        word: '穏やか　おだやか',
        level: 2,
        translation: 'calm',
      },
    ],
    textbooks: [],
    images: [

    ],
  },
  {
    kanji: '隠',
    level: 2,
    kun: ['おだ',],
    on: ['おん',],
    kanjiImage: 隠,
    kanjiImageFrames: 隠Frames,
    words: [
      {
        word: '隠す　かくす（他動詞）',
        level: 2,
        translation: 'to hide',
      },
      {
        word: '隠れる　かくれる（自動詞）',
        level: 2,
        translation: 'to be hidden',
      },
    ],
    textbooks: [],
    images: [

    ],
  },
  {
    kanji: '一',
    level: 5,
    kun: ['ひと',],
    on: ['いち','いっ','いつ',],
    kanjiImage: 一,
    kanjiImageFrames: 一Frames,
    words: [],
    textbooks: [],
    images: [
      一Genki,
    ],
  },
  {
    kanji: '二',
    level: 5,
    kun: ['ふた',],
    on: ['に',],
    kanjiImage: 二,
    kanjiImageFrames: 二Frames,
    words: [],
    textbooks: [],
    images: [
      二Genki,
    ],
  },
  {
    kanji: '三',
    level: 5,
    kun: ['みっ','み',],
    on: ['さん',],
    kanjiImage: 三,
    kanjiImageFrames: 三Frames,
    words: [],
    textbooks: [],
    images: [
      三Genki,
    ],
  },
  {
    kanji: '四',
    level: 5,
    kun: ['よん','よっ','よ',],
    on: ['し',],
    kanjiImage: 四,
    kanjiImageFrames: 四Frames,
    words: [],
    textbooks: [],
    images: [

    ],
  },
  {
    kanji: '五',
    level: 5,
    kun: ['ご',],
    on: ['いつ',],
    kanjiImage: 五,
    kanjiImageFrames: 五Frames,
    words: [],
    textbooks: [],
    images: [

    ],
  },
  {
    kanji: '六',
    level: 5,
    kun: ['むっ','むい','む',],
    on: ['ろく','ろっ',],
    kanjiImage: 六,
    kanjiImageFrames: 六Frames,
    words: [],
    textbooks: [],
    images: [

    ],
  },
  {
    kanji: '七',
    level: 5,
    kun: ['なな','なの',],
    on: ['しち',],
    kanjiImage: 七,
    kanjiImageFrames: 七Frames,
    words: [],
    textbooks: [],
    images: [

    ],
  },
  {
    kanji: '八',
    level: 5,
    kun: ['はち','はっ',],
    on: ['やっ','よう','や',],
    kanjiImage: 八,
    kanjiImageFrames: 八Frames,
    words: [],
    textbooks: [],
    images: [

    ],
  },
  {
    kanji: '九',
    level: 5,
    kun: ['ここの',],
    on: ['きゅう','く',],
    kanjiImage: 九,
    kanjiImageFrames: 九Frames,
    words: [],
    textbooks: [],
    images: [

    ],
  },
  {
    kanji: '十',
    level: 5,
    kun: ['とお','と',],
    on: ['じゅう','じっ','じゅっ',],
    kanjiImage: 十,
    kanjiImageFrames: 十Frames,
    words: [],
    textbooks: [],
    images: [

    ],
  },
  {
    kanji: '百',
    level: 5,
    kun: [],
    on: ['ひゃく','びゃく','ぴゃく','ひゃっ',],
    kanjiImage: 百,
    kanjiImageFrames: 百Frames,
    words: [],
    textbooks: [],
    images: [

    ],
  },
  {
    kanji: '千',
    level: 5,
    kun: ['ち',],
    on: ['せん','ぜん',],
    kanjiImage: 千,
    kanjiImageFrames: 千Frames,
    words: [],
    textbooks: [],
    images: [

    ],
  },
  {
    kanji: '万',
    level: 5,
    kun: [],
    on: ['まん','ばん',],
    kanjiImage: 万,
    kanjiImageFrames: 万Frames,
    words: [],
    textbooks: [],
    images: [

    ],
  },
  {
    kanji: '円',
    level: 5,
    kun: ['まる',],
    on: ['えん',],
    kanjiImage: 円,
    kanjiImageFrames: 円Frames,
    words: [],
    textbooks: [],
    images: [

    ],
  },
  {
    kanji: '山',
    level: 5,
    kanjiImage: 山,
    kanjiImageFrames: 山Frames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '川',
    level: 5,
    kanjiImage: 川,
    kanjiImageFrames: 川Frames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '人',
    level: 5,
    kanjiImage: 人,
    kanjiImageFrames: 人Frames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '休',
    level: 5,
    kanjiImage: 休,
    kanjiImageFrames: 休Frames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '力',
    level: 5,
    kanjiImage: 力,
    kanjiImageFrames: 力Frames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '男',
    level: 5,
    kanjiImage: 男,
    kanjiImageFrames: 男Frames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '女',
    level: 5,
    kanjiImage: 女,
    kanjiImageFrames: 女Frames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '安',
    level: 5,
    kanjiImage: 安,
    kanjiImageFrames: 安Frames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '本',
    level: 5,
    kanjiImage: 本,
    kanjiImageFrames: 本Frames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '田',
    level: 5,
    kanjiImage: 田,
    kanjiImageFrames: 田Frames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '目',
    level: 5,
    kun: ['め','ま',],
    on: ['もく','ぼく',],
    kanjiImage: 目,
    kanjiImageFrames: 目Frames,
    words: [],
    textbooks: [],
    images: [
      目Genki,
    ],
  },
  {
    kanji: '口',
    level: 5,
    kun: ['くち','ぐち',],
    on: ['こう','く',],
    kanjiImage: 口,
    kanjiImageFrames: 口Frames,
    words: [],
    textbooks: [],
    images: [
      口Genki,
    ],
  },


  {
    kanji: '書',
    level: 5,
    kanjiImage: kaku,
    kanjiImageFrames: kakuFrames,
    words: [],
    textbooks: [
      {
        name: 'Kanji Book',
        lesson: 9,
      }
    ],
  },
  {
    kanji: '見',
    level: 5,
    kanjiImage: miru,
    kanjiImageFrames: miruFrames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '行',
    level: 5,
    kanjiImage: iku,
    kanjiImageFrames: ikuFrames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '来',
    level: 5,
    kanjiImage: kuru,
    kanjiImageFrames: kuruFrames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '帰',
    level: 5,
    kanjiImage: kaeru,
    kanjiImageFrames: kaeruFrames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '食',
    level: 5,
    kanjiImage: taberu,
    kanjiImageFrames: taberuFrames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '飲',
    level: 5,
    kanjiImage: nomu,
    kanjiImageFrames: nomuFrames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '聞',
    level: 5,
    kanjiImage: kiku,
    kanjiImageFrames: kikuFrames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '話',
    level: 5,
    kanjiImage: hanasu,
    kanjiImageFrames: hanasuFrames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '買',
    level: 5,
    kanjiImage: kau,
    kanjiImageFrames: kauFrames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '教',
    level: 5,
    kanjiImage: oshieru,
    kanjiImageFrames: oshieruFrames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '朝',
    level: 5,
    kun: ['あさ'],
    on: ['ちょう'],
    kanjiImage: asa,
    kanjiImageFrames: asaFrames,
    words: [
      {
        word: '朝　あさ',
        level: 1,
        translation: 'утро (morning)',
      },
      {
        word: '朝食　ちょうしょく',
        level: 1,
        translation: 'завтрак (breakfast)',
      },
      {
        word: '朝日　あさひ',
        level: 1,
        translation: 'утреннее солнце (the morning sun)',
      }
    ],
    textbooks: [],
  },
  {
    kanji: '昼',
    level: 5,
    kun: ['ひる'],
    on: ['ちゅう'],
    kanjiImage: hiru,
    kanjiImageFrames: hiruFrames,
    words: [
      {
        word: '昼　ひる',
        level: 1,
        translation: 'полдень (noon, midday)',
      },
      {
        word: '昼食　ちゅうしょく',
        level: 1,
        translation: 'обед (lunch)',
      },
      {
        word: '昼寝　ひるね',
        level: 1,
        translation: 'полуденный сон (a nap, a siesta)',
      },
      {
        word: '昼休み　ひるやすみ',
        level: 1,
        translation: 'перерыв на обед (a lunch break)',
      },
    ],
    textbooks: [],
  },
  {
    kanji: '晩',
    level: 5,
    kanjiImage: ban,
    kanjiImageFrames: banFrames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '夕',
    level: 5,
    kanjiImage: yuugata,
    kanjiImageFrames: yuugataFrames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '夜',
    level: 5,
    kun: ['よる、よ'],
    on: ['や'],
    kanjiImage: yoru,
    kanjiImageFrames: yoruFrames,
    words: [
      {
        word: '夜　よる',
        level: 1,
        translation: 'ночь (night)',
      },
      {
        word: '今夜　こんや',
        level: 1,
        translation: 'сегодня ночью (tonight)',
      },
      {
        word: '夜食　やしょく',
        level: 1,
        translation: 'ночной приём пищи (a midnight meal)',
      },
    ],
    textbooks: [],
  },
  {
    kanji: '方',
    level: 5,
    kanjiImage: houhou,
    kanjiImageFrames: houhouFrames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '午',
    level: 5,
    kanjiImage: gogo,
    kanjiImageFrames: gogoFrames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '前',
    level: 5,
    kanjiImage: mae,
    kanjiImageFrames: maeFrames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '働',
    level: 5,
    kanjiImage: 働,
    kanjiImageFrames: 働Frames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '動',
    level: 5,
    kanjiImage: 動,
    kanjiImageFrames: 動Frames,
    words: [],
    textbooks: [],
  },
  {
    kanji: '後',
    level: 5,
    kanjiImage: ato,
    kanjiImageFrames: atoFrames,
    words: [],
    textbooks: [],
  },


  {
    kanji: '春',
    level: 4,
    kanjiImage: haru,
    kanjiImageFrames: haruFrames,
    words: [],
  },
  {
    kanji: '事',
    level: 4,
    kanjiImage: koto,
    kanjiImageFrames: kotoFrames,
    words: [],
  },
  {
    kanji: '飛',
    level: 3,
    kanjiImage: 飛,
    kanjiImageFrames: 飛Frames,
    words: [],
  },
  {
    kanji: '機',
    level: 3,
    kanjiImage: 機,
    kanjiImageFrames: 機Frames,
    words: [],
  },
  {
    kanji: '空',
    level: 3,
    kanjiImage: 空,
    kanjiImageFrames: 空Frames,
    words: [],
  },
  {
    kanji: '港',
    level: 3,
    kanjiImage: 港,
    kanjiImageFrames: 港Frames,
    words: [],
  },
  {
    kanji: '預',
    level: 3,
    kanjiImage: 預,
    kanjiImageFrames: 預Frames,
    words: [],
  },
  {
    kanji: '込',
    level: 3,
    kanjiImage: 込,
    kanjiImageFrames: 込Frames,
    words: [],
  },
  {
    kanji: '荷',
    level: 3,
    kanjiImage: 荷,
    kanjiImageFrames: 荷Frames,
    words: [],
  },
  {
    kanji: '査',
    level: 3,
    kanjiImage: 査,
    kanjiImageFrames: 査Frames,
    words: [
      {
        word: '調査　ちょうさ',
        level: null,
        translation: 'расследование',
      },
      {
        word: '検査　けんさ',
        level: null,
        translation: 'проверка, досмотр; инспекция',
      },
      {
        word: '巡査　じゅんさ',
        level: null,
        translation: 'полицейский; инспектор',
      },
    ],
  },
  {
    kanji: '検',
    level: 3,
    kanjiImage:　検,
    kanjiImageFrames: 検Frames,
    words: [
      {
        word: '倹しい　つましい',
        level: null,
        translation: 'бережливый, экономный',
      },
      {
        word: '倹約　けんやく',
        level: null,
        translation: 'экономия, бережливость',
      }
    ],
    images: [
      検keyToKanji,
    ],
  },
  {
    kanji: '満',
    level: 3,
    kanjiImage:　満,
    kanjiImageFrames: null,
    words: [],
  },
  {
    kanji: '席',
    level: 3,
    kanjiImage:　席,
    kanjiImageFrames: 席Frames,
    words: [],
  },
  {
    kanji: '番',
    level: 3,
    kanjiImage:　番,
    kanjiImageFrames: 番Frames,
    words: [],
  },
  {
    kanji: '号',
    level: 3,
    kanjiImage:　号,
    kanjiImageFrames: 号Frames,
    words: [],
  },
  {
    kanji: '路',
    level: 3,
    kanjiImage:　路,
    kanjiImageFrames: 路Frames,
    words: [],
  },
  {
    kanji: '側',
    level: 3,
    kanjiImage:　側,
    kanjiImageFrames: 側Frames,
    words: [],
  },
  {
    kanji: '座',
    level: 3,
    kanjiImage:　座,
    kanjiImageFrames: 座Frames,
    words: [],
  },
  {
    kanji: '窓',
    level: 3,
    kanjiImage:　窓,
    kanjiImageFrames: 窓Frames,
    words: [],
  },
  {
    kanji: '読',
    level: 5,
    kanjiImage: yomu,
    kanjiImageFrames: yomuFrames,
    words: [
      {
        word: '読む　よむ',
        level: 5,
        translation: 'читать, to read',
      },
      {
        word: '読書　どくしょ',
        level: 3,
        translation: 'чтение, reading books',
      },
      {
        word: '読み　よみ',
        level: 3,
        translation: 'чтение, reading',
      },
      {
        word: '読者　どくしゃ',
        level: 1,
        translation: 'читатель, a reader',
      }
    ],
  }
];