import React, { useContext } from 'react';
import { UserType } from "./types";

export interface UsersContextInterface {
  users: UserType[];
  fetchUsers: (controller: AbortController) => Promise<UserType[]>;
  setUsers: React.Dispatch<UserType[]>;
  getUsername: (userId: string) => string;
}

const UsersContext = React.createContext<UsersContextInterface | null>(null);

const useUsersContext = (): UsersContextInterface => {
  const context = useContext(UsersContext);
  if (!context) {
    throw new Error(
      'useUsersContext must be placed within UsersContextProvider'
    );
  }
  return context;
};

export { UsersContext, useUsersContext };