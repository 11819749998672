import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const waMochiron: OneGrammarType = {
  name: '〜はもちろん',
  level: 'N3', // n2n3
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <JP>
          N＋はもちろん
          <br /><br />
        </JP>
      ),
    },
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <>
          ⑴　彼は、英語<Accent>はもちろん</Accent>、ドイツ語も中国もできる。
        </>
      ),
    },
  ]
};