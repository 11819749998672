import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const tsutsu: OneGrammarType = {
  name: '〜つつ',
  level: 'N2',
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <RUS>
          Одновременность (simultaneity)<br />
          2ая основа глагола + <JP>つつ</JP><br />
          Похожая грамматика: <JP>ながら</JP><br />
          <br />
          Присоединяется ко 2ой основе глагола (is used with a verb stem).
          Подлежащее в левой и правой части должно совпадать
          (быть одним и тем же).
          Подлежащее (человек) <b>совершает два действия одновременно</b>,
          пока действие слева продолжается, продолжается и действие справа.
          Похоже на грамматику <JP>〜ながら</JP>, однако,
          в отличие от <JP>〜ながら</JP>, используется на письме или
          при официальном разговоре (formal conversation).
          <br /><br />
        </RUS>
      ),
    },
    {
      meaningNumber: 2,
      content: (
        <RUS>
          Противоположность (adversative connective)<br />
          2ая основа глагола + <JP>つつ</JP><br />
          Похожая грамматика: <JP>のに／ながら</JP><br />
          <br />
          Присоединяется ко 2ой основе глагола (is used with a verb stem).
          Подлежащее в левой и правой части должно совпадать
          (быть одним и тем же).
          Подлежащее (человек) <b>совершает два действия одновременно</b>,
          однако <b>второе действие совершается вопреки первому</b>.
          По этой причине похоже
          на грамматику <JP>〜のに</JP>, как и <JP>〜のに</JP> соединяет
          противоречащие друг другу простые предложения.
          <br />
          В этом значении часто используется как idiomatic expression (как в примере 3).
          <br /><br />
        </RUS>
      ),
    }
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <>
          <JP>①　彼は、「春ももう終わりですね」
            と言い<Accent>つつ</Accent>、庭へ目をやった。</JP><br />
          <RUS>Он, говоря [Весна тоже уже почти закончилась],
          перевёл взгляд на сад.</RUS><br />
          <JP>庭　にわ</JP><br />
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <RUS>
          <JP>②　静かな青い海を眺め<Accent>つつ</Accent>、
          良子は物思いにふけっていた。</JP><br />
          <JP>良子　よしこ</JP> имя<br />
          <JP>歩む　あゆむ</JP><br />
        </RUS>
      ),
    },
    {
      meaningNumber: 2,
      exampleNumber: 2,
      content: (
        <RUS>
          <JP>①　夏休みの間、勉強しなければならないと思い<Accent>つつ</Accent>、
            毎日遊んで過ごしてしまった。</JP><br />
          <JP>間　あいだ</JP><br />
        </RUS>
      ),
    },
  ],
};