import { komu } from "../n3/komu";
import { sae } from "../n3/sae";
import { waMochiron } from "../n3/waMochiron";
import { tsuideni } from "../n3/tsuideni";
import { toshite } from "../n3/toshite";
import { nioite } from "../n3/において";
import { にかわって} from '../n3/にかわって';
import { はずだ} from '../n3/はずだ';

export const grammarN3 = [
  komu,
  sae,
  waMochiron,
  tsuideni,
  toshite,
  nioite,
  にかわって,
  はずだ,
];