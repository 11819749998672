import React, { useEffect, useState } from 'react';
// import { FlashcardType } from '../data/style';
import { Typography } from '@mui/material';
//import '../App.css';
import { AddIconStyled, AddIconStyled_2, FlashcardStyle, EditIconStyled } from "../flashcard/style";
//import { FlashcardDialogueContent } from "./FlashcardDialogueContent";
//import { FlashcardDialogueEdit } from "./FlashcardDialogueEdit";
import { FlashcardType } from "./context/FlashcardContext";
import { RUS_3, JP_2 } from "../../style";

interface FlashcardInterface {
    flashcard: FlashcardType;
    size?: number;
    listIndex?: number;
    updateData?: (id: number, isOpen: boolean, listIndex: number) => void;
}

export const Flashcard: React.FC<FlashcardInterface> = ({ listIndex, updateData, flashcard, size }) => {
  const [shouldShowAnswer, setShouldShowAnswer] = useState(false);
  const [shouldOpenFlashcardEdit, setShouldOpenFlashcardEdit] = useState(false);

  const handleAddIconClick = (e: any) => {
    //e.stopPropagation();
    console.log('clicked on icon');
    setShouldOpenFlashcardEdit(true);
  }

  useEffect(() => {
    if (shouldShowAnswer) {
      setShouldShowAnswer(false);
    }
  }, [flashcard]);

  const handleClick = (id: number, isOpen: boolean) => {
    updateData && updateData(id, isOpen, listIndex || 0);
  };

  const getLevel = (level: number | undefined) => {
    return level === 0 ? '' : `N${level}`;
  };

    // console.log('TARGET WITH', flashcard);
  // () => handleClick(flashcard.id, !flashcard.isOpen)

  /*
  <FlashcardDialogueContent
          open={shouldOpenFlashcardEdit}
          onClose={() => setShouldOpenFlashcardEdit(false)}
          content={
          <FlashcardDialogueEdit
            open={shouldOpenFlashcardEdit}
            handleClose={() => setShouldOpenFlashcardEdit(false)}
            flashcard={flashcard}
          />}
        />
   */

    return (
      <FlashcardStyle
        size={size}
        className={` ${flashcard.isOpen ? 'answer' : ''}`}
        onClick={() => handleClick(flashcard.id, !flashcard.isOpen)}
      >
        <div className={`front ${flashcard.id}`}>
          <Typography variant={'h3'}
            style={{
              //fontFamily: `"KosugiMaru-Regular", sans-serif`,
            }}
          >
            {flashcard.front}
          </Typography>
        </div>
        <AddIconStyled_2 className='icon'>{getLevel(flashcard.jlptLevel)}</AddIconStyled_2>
        {
          // <AddIconStyled className='icon' fontSize={'large'} onClick={handleAddIconClick} />
        }


        <div className={`back ${flashcard.id}`}>
          {
            flashcard.backJp?.map((answer, i) => {
              return (
                <div key={i}>
                  <Typography variant={'h5'}
                              style={{
                                fontFamily: `"KosugiMaru-Regular", sans-serif`,
                              }}
                  >
                    {flashcard.isOpen ? <>{answer}</> : ''}
                  </Typography>
                </div>
              );
            })
          }
          {
            flashcard.backRus?.map((answer, i) => {
              return (
                <div key={i}>
                  <Typography variant={'h6'}
                    style={{
                      fontFamily: `"Roboto", sans-serif`,
                      fontWeight: 'normal',
                    }}
                  >
                    {flashcard.isOpen ? <>{answer}</> : ''}
                  </Typography>
                </div>
              );
            })
          }
        </div>
      </FlashcardStyle>
    );
};