import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const もしない: OneGrammarType = {
  name: '〜もしない',
  level: 'N2',
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <RUS>

        </RUS>
      ),
    },
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <>
          <JP>
            食事<Accent>もしない</Accent>でゲームばかりしていると、
            病気になるよ。
          </JP><br />
          <RUS>
            You're going to fall sick if you keep playing those computer games
            without stopping for meals.
          </RUS>
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <>
          <JP>
            A:失敗したなあ。このシャツ、ちょっと派手すぎた。<br />
            B:よく見<Accent>もしない</Accent>で買うからよ。
          </JP><br />
          <RUS>
            A: I made a bad decision. This shirt is a bit too loud.<br />
            B: That's because you always buy stuff without looking at it closely.
          </RUS>
        </>
      ),
    },
  ],
};