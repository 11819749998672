import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Flashcard } from "./Flashcard";
import { FlashcardType } from "./context/FlashcardContext";
import {AddIconStyled, FlashcardWrapperStyle, FlashcardWrapperItem} from "./style";

interface FlashcardWrapperInterface {
    flashcard: FlashcardType;
    listIndex?: number;
    updateData?: (id: number, isOpen: boolean, listIndex: number) => void;
}

export const FlashcardWrapper: React.FC<FlashcardWrapperInterface> = (props) => {
    const { flashcard, listIndex, updateData } = props;
    return (
        <FlashcardWrapperStyle>
            <FlashcardWrapperItem>
                <Flashcard
                  flashcard={flashcard}
                  size={flashcard.size}
                  listIndex={listIndex}
                  updateData={updateData}
                />
            </FlashcardWrapperItem>
        </FlashcardWrapperStyle>
    )
};