import React from 'react';
import { Box } from "@mui/material";

type Props = {
  children: React.ReactNode;
  backgroundColor: string;
  padding: string;
}

export const SharedBox: React.FC<Props> = (props) => {
  const { backgroundColor, padding, children } = props;
  return (
    <Box
      sx={{
        borderRadius: '4px',
        padding: padding,
        backgroundColor: backgroundColor, // 'rgba(97,111,125,0.2)',
        height: 'inherit'
      }}
    >
      {children}
    </Box>
  );
};