import { sappariNai } from "../n2/sappariNai";
import { niKagiri } from "../n2/niKagiri";
import { totomoni } from "../n2/totomoni";
import { tsutsu } from "../n2/tsutsu";
import { にもかかわらず　} from "../n2/にもかかわらず";
import { をめぐって　} from "../n2/をめぐって";
import { にしては　} from "../n2/にしては";
import { もしない　} from "../n2/もしない";

export const grammarN2 = [
  sappariNai,
  niKagiri,
  totomoni,
  tsutsu,
  にもかかわらず,
  をめぐって,
  にしては,
  もしない,
];