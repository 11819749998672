import React, {useCallback, useRef, useState} from 'react';
import { UsersContext } from "./UsersContext";
import {UserType} from "./types";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import _find from "lodash/find";

type Props = {
  children: React.ReactNode;
};

export const UsersContextProvider: React.FC<Props> = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const [users, setUsers] = useState<UserType[]>([]);

  const fetchUsers = useCallback(async (
    controller: AbortController,
  ): Promise<UserType[]> => {
    try {
      console.log('Attempting to fetch');
      const response = await axiosPrivate.get('/users', {
        signal: controller.signal,
      });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }, []);

  const getUsername = useCallback((userId: string): string => {
    const found = _find(users, user => user.id === userId);
    return found ? found.username : 'Not found';
  }, [users]);

  return (
    <UsersContext.Provider
      value={{
        users,
        setUsers,
        fetchUsers,
        getUsername,
      }}
    >
      {props.children}
    </UsersContext.Provider>
  );
};