import React from 'react';
import { Grid } from "@mui/material";
import { JP, RUS, Level } from "../../style";
import { colors } from "../../utils/palette";
import { WordType } from "./types";

type Props = {

} & WordType;

export const Line: React.FC<Props> = (props) => {
  const { level, word, translation } = props;

  const getBackgroundColor = (level: number) => {
    switch (level) {
      case 5:
        return colors.matcha.green_light;
      case 3:
        return colors.gold;
      case 2:
        return '#c5dcfa'; // colors.purple;
      default:
        return colors.blue;
    }
  };

  return (
    <Grid
      container
      alignItems='center'
      columnSpacing={1}
      rowSpacing={0}
      style={{ padding: '.2vh', borderRadius: '4px'}}
    >
      <Grid item>
        <div>
          <JP>{word}</JP>
        </div>
      </Grid>
      {
        level &&
        <Grid item>
          <Level $bgColor={getBackgroundColor(level)}>
            N{level}
          </Level>
        </Grid>
      }
      <Grid item>
        <div style={{  padding: '.2vh', textAlign: 'center', marginTop: '3px'}}>
          <RUS style={{ fontSize: '12px', fontFamily: '"FiraSansCondensed-Regular", sans-serif', textAlign: 'center', verticalAlign: 'center'}}>{translation}</RUS>
        </div>
      </Grid>
    </Grid>
  );
};