import React from 'react';
import {Avatar, Box, Grid} from "@mui/material";
import {CheckOutlined} from "@mui/icons-material";

type Props = {
  heading: string;
};

export const AdvantageLine1: React.FC<Props> = (props) => {
  const {
    heading,
  } = props;
  return (
    <Grid
      container
      wrap='nowrap'
      justifyContent='flex-start'
      alignItems='center'
      columnSpacing={2}
      style={{
        border: '0px solid red',
      }}
    >
      <Grid item justifyContent='center' style={{
        border: '0px solid red',
        paddingRight: 0
      }}>
        <Avatar
          sx={{
            bgcolor: 'rgba(191,210,140, 0.3)',
          }}
        >
          <CheckOutlined
            fontSize='small'
            style={{
              color: 'rgb(116,164,62,1)',
            }}
          />
        </Avatar>
      </Grid>
      <Grid
        item
        style={{
          color: 'rgb(116,164,62,1)',
          paddingLeft: '5px',
        }}>
        <Box
          sx={{
            padding: '0vh',
            borderRadius: '4px'
          }}
        >
          <span style={{
            fontWeight: 600,
            textDecoration: 'none',
          }}>
            {heading}
          </span>
        </Box>
      </Grid>
    </Grid>
  );
};