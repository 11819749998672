import React, {createContext, useEffect, useState, useMemo} from "react";

export type AuthType = {
  roles?: number[];
  user?: string;
  pwd: string;
  accessToken: string;
}

interface AuthContextInterface {
  auth: AuthType | null;
  setAuth: any;
  persist: boolean;
  setPersist: any;
  isAdmin: boolean;
}

export const AuthContext = createContext<AuthContextInterface | null>(null);

export const useAuthContext = (): AuthContextInterface => {
  const context = React.useContext(AuthContext);
  if (!context) {
    console.error('AuthContext must be used within AuthContextProvider');
    throw 'AuthContext must be used within AuthContextProvider';
  }
  return context;
};

type Props = {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [auth, setAuth] = useState<AuthType | null>(null);
  const [persist, setPersist] = useState<boolean>(false);

  useEffect(() => {
    const tmpPersist: string | null = localStorage.getItem('persist');
    if (tmpPersist) {
      setPersist(JSON.parse(tmpPersist) === true);
    } else {
      setPersist(false);
    }
  }, []);

  const isAdmin = useMemo(() => {
    return auth?.roles?.includes(5150) ?? false;
  }, [auth]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        persist,
        setPersist,
        isAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};