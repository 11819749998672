import React, { useCallback } from 'react';
import {
  Container,
  Box,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import {
  BackgroundImageStyle,
  StyledLogo,
} from "./style";
import { MenuListComposition } from "./components/menu/Menu";

const LINE_HEIGHT = 1.4;

type Props = {
  //children: React.ReactNode;
}

export const Main: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const handleLogoClick = useCallback(() => {
    navigate(`/`);
  }, [navigate]);

  return (

    <Box
      sx={{
        padding: '0',
        backgroundColor: '#f0f2f5', // '#f0f2f5', // '#FAFAFA',
        //border: '1px solid green',
        width: '100%'
      }}
    >

      <BackgroundImageStyle>
      <Box
        sx={{
          //paddingTop: '3vh',
          //paddingBottom: '3vh',
           // '#F4F5F1',
          minHeight: '100vh',
          borderRadius: '55px',
          paddingBottom: '2vh',
          //border: '1px solid red'
        }}
      >
        <Box
          sx={{
            zIndex: 2,
            backgroundColor: '#61dafb', // '#F8F5DE', // '#83A0BC',
            color: 'white', // '#3F351A', // '#F4F5F1', // '#405C7D',
            // color: '#61dafb',
            // height: '5vh',
            // padding: '1vh',
            padding: '2vh',
            fontSize: '24px',
            position: 'fixed',
            width: '100%',
            top: 0,
            left: 0,
          }}
        >
          <Container
            maxWidth='xl'
            fixed
            style={{ //backgroundColor: 'rgba(255,255,255,1)',
              //paddingBottom: '2vh',
              //borderRadius: '0 0 55px 55px',

            }}
          >
            <Grid container justifyContent='space-between'>
              <Grid item>
                <StyledLogo onClick={handleLogoClick}>
                  大空　おおぞら
                </StyledLogo>{'   '}
              </Grid>
              <Grid item style={{ display: 'flex', alignItems: 'center'}}>
                <MenuListComposition />
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Container
          maxWidth='xl'
          fixed
          style={{
            backgroundColor: 'white',
            //paddingBottom: '2vh',
            borderRadius: '0 0 25px 25px',
            paddingTop: '8vh',
            paddingBottom: '10vh',
            width: '100%'
          }}
        >

          {
            // This box gives extra space at the beginning of the page
          }
          <Box
            sx={{
              minHeight: '2vh',
            }}
          >
          </Box>
          <Outlet />
        </Container>
      </Box>
    </BackgroundImageStyle>
      <Box
        sx={{
          backgroundColor: '#282c34', // '#282c34',
          // color: '#f4f4f4', // '#566268', // '#405C7D',
          color: '#F5F5F5',
          // height: '20vh',
          // padding: '1vh',
          padding: '3vh 1vh 3vh 1vh',
          //position: 'fixed',
          left: 0,
          bottom: 0,
          //width: '100%',
        }}
      >
        <Container maxWidth='xl' fixed>
          <Box
            sx={{
              padding: '1vh 1vh 1vh 1vh',
            }}
          >
            <div style={{  }}>
              <span style={{
                fontFamily: 'KosugiMaru-Regular',
                fontSize: '18px',
                lineHeight: LINE_HEIGHT,
              }}>
                大空　おおぞら
            </span><br />

              <div style={{
                fontFamily: 'Arial',
                fontSize: '16px',
                lineHeight: LINE_HEIGHT,
              }}>
                Школа японского языка "Оодзора". <br />
                Сайт в разработке.
              </div>
            </div>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

// export default Main;