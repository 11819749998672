import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const toItteIru: OneGrammarType = {
  name: '〜といっている',
  level: 'N4',
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <JP>
          ある人の発言が現在でも有効であることを表す。{''}
          三人称の発言を引用する場合が多いが、{''}
          自分の発言の場合はそれが聞き入れてもらえないという状況があるのが普通である。{''}
        </JP>
      ),
      translation: (
        <RUS>
          <JP>発言（はつげん）</JP> statement<br />
          <JP>有効（ゆうこう）</JP> valid<br />
          <JP>三人称（さんにんしょう）</JP> third person<br />
          <JP>引用（いんよう）する</JP> цитировать, quotation, <JP>他動詞</JP><br />
          <JP>普通（ふつう）</JP> normal<br />
          <JP>聞き入る（ききいる）</JP> в потенциальной форме <JP>聞き入れる（ききいれる）</JP>,
          по этой же причине (что потенциальная форма) там стоит частица <JP>が</JP><br />
          <JP>状況（じょうきょう）</JP> state of affairs, контекст<br />
        </RUS>
      ),
    },
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <JP>
          ⑴　山下さんはまだ決められないと言っている。
        </JP>
      ),
    }
  ],
};