
// TODO Add getRGBA function
export const colors = {
  blue: '#61dafb', // rgb(97,218,251)
  blue2: '#89dcf5',
  gold: '#d6b55f', // rgba(214,181,95,255)
  red: '#db5c56', // rgb(219,92,86)
  purple: '#bf4fa2', // rgba(191,79,162,255)
  gem: '#f1c7b4',
  green: '#d4dcc4',
  apple: {
    pink: {
      dark: '#b42f4c', // rgb(180,47,76)
      light: '#debcc6', // rgb(222,188,198)
    },
    blue: '#d9e9eb', // rgb(217,233,235)
  },
  pale: {
    blue: 'rgba(97,218,251,0.08)',
    green: 'rgba(191,210,140, 0.3)',
    icon: 'rgba(97,218,251,0.5)',
  },
  link: 'rgb(101,160,225)', // #65a0e1
  matcha: {
    green_dark: 'rgb(185,211,115)',
    green_light: 'rgba(191,210,140, 1)',
    green_lightest: 'rgba(220,226,184, 1)',
  },
};