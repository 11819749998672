import styled, { css } from "styled-components";
import { colors } from "../../utils/palette";

export const StyledWrapper = styled.div(() => {
  return css`
    /*
      width:  220px;
      height:  auto;
      min-height:  100%;
      position:  relative;
      background-image: url("https://images.pexels.com/photos/114979/pexels-photo-114979.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
      background-size:  cover;
      border-top-left-radius:  4px;
      border-bottom-left-radius:  4px;
      svg {
        height:  40px;
        width:  auto;
        margin:  20px;
      }*/

    // background: linear-gradient(#FFFFFF, #9198e5); 199 197 244; 119 107 204
    background: linear-gradient(120deg, rgba(199,197,244,0.2), rgba(119,107,204, 0.5));
    
    // box-shadow: 0px 0px 24px #5C5696
    height: 200px;

    border-radius: 10px;
    backdrop-filter: blur(10px);
    `;
});

export const WordButton = styled.button(() => {
  return css`
    &:hover {
      cursor: pointer;
      background-color: dodgerblue;
      transition: background-color ease-in 0.3s;
    }

    transition: background-color ease-in 0.3s;
    
    border-radius: 15px;
    outline: none;
    border: 1px solid rgba(199,197,244,0.2);
    background-color: ${colors.blue2};
  `;
});