import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthContext } from "../../context/AuthProvider";
import useRefreshToken from "../../hooks/useRefreshToken";
import { Loader } from "../loader/Loader";

export const PersistLogin: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth, persist } = useAuthContext();

  useEffect(() => {
    let isMounted = true;
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error(err);
      } finally {
        // Prevents from getting an endless loading loop
        isMounted && setIsLoading(false);
      }
    };
    if (persist && !auth?.accessToken) {
      verifyRefreshToken().catch(console.error);
    } else {
      setIsLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, [persist]);

  useEffect(() => {
    // console.log(`isLoading: ${isLoading}`);
    // console.log(`accessToken: ${JSON.stringify(auth?.accessToken)}`);
  }, [isLoading]);

  return (
    <>
      {!persist
        ? <Outlet />
        : isLoading
          ? <Loader type='ThreeDots' />
          : <Outlet />
      }
    </>
  );
};