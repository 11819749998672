import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const niKagiri: OneGrammarType = {
  name: '〜に限り（かぎり）',
  level: 'N2',
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <JP>
          公に説明するときの言い方。例外的に扱われるものを表す言葉につく。
          後には、その例外だけに適用されることを言う文が来る。
          ふつう、否定の文や働きかけの文は来ない。
        </JP>
      ),
      translation: (
        <JP>
          公（おおやけ）にする <RUS>to make public</RUS><br />
          例外（れいがい）<RUS>exception</RUS><br />
          例外的（れいがいてき）に <RUS>exceptional</RUS>
        </JP>
      ),
    }
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <>
          ①　このちらしをご持参のお客様<Accent>に限り</Accent>、
          すべての商品を１割引でお買い求めいただきます。
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <JP>
          ②　欠席理由が正当な場合<Accent>に限り</Accent>
          出席扱いにしますが、それ以外の欠席は認めません。
        </JP>
      ),
      translation: (
        <>
          <JP>
            欠席（けっせき） <RUS>и</RUS>{' '}
            出席（しゅっせき） <RUS>это антонимы</RUS>,
            欠席 <RUS>означает отсутствовать, не придти, а</RUS>{' '}
            出席 <RUS>означает наоборот присутствовать, придти</RUS>,{' '}
            <RUS>то есть здесь речь идёт про посещаемость (отсутствие/присутствие на уроках)</RUS>.
            <br />
            それ以外（いがい） <RUS>кроме этого, кроме таких ситуаций</RUS>,
            以外 <RUS>означает кроме</RUS>, それ <RUS>означает это</RUS>,{' '}
            <RUS>поэтому получается кроме этого, кроме таких ситуаций</RUS>.
            <br />
            <RUS>Причина, почему здесь подразумевается ситуация, это слово</RUS>{' '}
            場合（ばあい）, <RUS>оно означает случай, ситуация</RUS>, {' '}
            <RUS>то есть речь здесь идёт про такие случаи, ситуации.</RUS>
            <br />
            <RUS>Имеется в виду, кроме тех случаев, когда можно не присутствовать</RUS>{' '}
            （欠席理由が適当な場合に限り）, <RUS>дословно – ТОЛЬКО (ИСКЛЮЧИТЕЛЬНО) в тех случаях</RUS>,{' '}
            <RUS>когда причина отсутствия оправдана/приемлима</RUS>.{' '}
            <RUS>Только/исключительно это и есть перевод грамматики</RUS> 〜に限り.
            <br />
            <RUS>Глагол</RUS> 認める（みとめる） <RUS>это переходный глагол</RUS> （他動詞）,{' '}
            <RUS>означает признавать / понимать что-то как что-то ещё</RUS>,{' '}
            <RUS>то есть любой другое отсутствие не разрешено/не допускается</RUS>{' '}
            （それ以外の欠席は認めません）<RUS>.</RUS>
            <br />
            扱い（あつかい）にする <RUS>означает рассматривать что-то в качестве чего-то</RUS>,{' '}
            <RUS>в данном случае означает, рассматривать (засчитывать) отсутствие как присутствие</RUS>{' '}
            （出席扱い）<RUS>.</RUS><br />
            <RUS>Перевод предложения: Только те пропуски, которые считаются допустимыми
              (по-русски мы говорим только пропуски по уважительной причине),
              не повлияют на посещаемость (будут засчитаны
              как присутствие), любые другие пропуски мы не примем.</RUS>
          </JP>
        </>
      ),
    }
  ]
};