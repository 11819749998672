import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const zutsu: OneGrammarType = {
  name: '〜ずつ',
  level: 'N5',
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <>
          По сколько-то штук.<br />
          <JP>

          </JP>
        </>
      ),
    },
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <>
          ①　一人に３つ<Accent>ずつ</Accent>キャンディーをあげましょう。
        </>
      ),
      translation: (
        <>
          一人（ひとり）один человек, японское количественное числительное,
          люди считаются на суффикс 人<br />
          一人（ひとり）に частица に означает кому → одному человеку<br />
          ずつ по сколько штук<br />
          ３つ（みっつ）три, японское количественное числительное<br />
          ３つずつ по три штуки<br />
          あげる давать<br />
          キャンディー конфеты
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <>
          ②　５人<Accent>ずつ</Accent>でグループを作った。
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 3,
      content: (
        <>
          ③　雪が溶けて、少し<Accent>ずつ</Accent>春が近いてくる。
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 4,
      content: (
        <>
          ④　いくらか<Accent>ずつ</Accent>でもお金を出し合って、
          焼けた寺の再建に協力しよう。
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 5,
      content: (
        <>
          ⑤　病人はわずか<Accent>ずつ</Accent>だが食べられるようになってきた。
        </>
      ),
    }
  ]
};