import React, { useState } from 'react';
import { Grid } from "@mui/material";
import { Info, Launch, Close, HelpOutline } from "@mui/icons-material";
import { JP, ReadMore, ReadMoreText, RUS } from "../../style";
import { Card } from "../card/Card";
import { colors } from "../../utils/palette";

type Props = {
  exampleSentence: React.ReactNode; // Because it can have Accent style
  exampleSentenceNumber: number;
  translation?: React.ReactNode;
};

export const RenderExample: React.FC<Props> = (props) => {
  const { exampleSentence, exampleSentenceNumber, translation } = props;
  const [isReadMore, setIsReadMore] = useState(false);

  const handleReadMore = (): void => {
    setIsReadMore(!isReadMore);
  };

  return (
    <>
    <Grid container justifyContent={'flex-start'} alignContent={'flex-start'} alignItems='center' style={{ paddingTop: '2vh'}}>
      <Grid item style={{ verticalAlign: 'top' }}>
        <Grid item>
          <JP>
            {exampleSentence}
          </JP>
        </Grid>
        {
          translation &&
          <Grid item>
            <ReadMore onClick={handleReadMore} style={{ display: 'inline-block' }}>
              {
                isReadMore
                  ?  (
                    <Close
                      fontSize='medium'
                      style={{
                        color: colors.blue,
                        marginBottom: '-5px',
                      }}
                    />
                  )
                  : (
                    <HelpOutline
                      fontSize='medium'
                      style={{
                        color: colors.blue,
                        marginBottom: '-5px',
                      }}
                    />
                  )
              }
            </ReadMore>
          </Grid>
        }
      </Grid>
    </Grid>

      <ReadMoreText $isVisible={isReadMore}>
        <Card
          lineHeight={1.4}
          lessonDescription={
            translation &&
            <>
              {translation}
            </>
          }
          backgroundColor={colors.pale.green}
          padding={'3vh'}
        />
      </ReadMoreText>
    </>
  );
};