import styled from "styled-components";
import { Grid } from "@mui/material";
import { colors } from "../../utils/palette";

export const LessonButton = styled.button`
  background-color: forestgreen;
  color: #dbf3da;
  border: 0;
  outline: 0;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledRow = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  
  > * {
    padding: 5px;
  }
  
  // border: 1px solid black;
  background-color: #dbf3da;
  border-radius: 4px;
  padding: 5px !important;
  margin-bottom: 5px !important;

  border: 1px solid transparent;
  transition-duration: 1s;
  transition-property: border;
  &:hover {
    transition-duration: .2s;
    transition-property: border;
    border: 1px solid forestgreen;
    cursor: pointer;
  }
  
  > div {
    //border: 1px solid blue;
    display: flex;
    flex-wrap: wrap;
    
    overflow: hidden;
    word-wrap: break-word;
    word-break: break-all;
  }
  
  &:nth-child(odd) {
    //background-color: ${colors.matcha.green_light};
  }
  &:nth-child(even) {
    //background-color: ${colors.matcha.green_lightest};
  }
`;