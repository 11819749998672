import React from "react";
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { StyledDialogTitle } from "../../style";
import { colors } from "../../utils/palette";

type Props = {
  open: boolean;
  onClose: () => void;
  content: React.ReactNode;
};

export const GrammarTableModal: React.FC<Props> = (props) => {
  const { onClose, open, content } = props;
  return (
    <>
      <Dialog onClose={onClose} open={open}>

        <div style={{ padding: '2vh 2vh 2vh 2vh', }}>
          {content}
        </div>

      </Dialog>
    </>
  );
};