import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const tsuideni: OneGrammarType = {
  name: '〜ついでに',
  level: 'N3', // n2n3
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <RUS>
          Означает <b>используя возможность, заодно, по пути</b>.
          Человек планирует что-то сделать и заодно к этому решает сделать что-то ещё.
        </RUS>
      ),
    },
    {
      meaningNumber: 2,
      content: (
        <>
          Присоединяется к существильному через <JP>の</JP>,
          существительное должно выражать активное действие, и
          к глаголу в словарной (<JP>辞書形</JP>) или прошедшей (<JP>た形</JP>) форме.
        </>
      ),
    }
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <>
          ⑴　図書館へ本を借りにいった。
          <Accent>ついでに</Accent>、近くに住んでいる友達のところへ行ってみた。
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <JP>
          ⑵　でかけるのなら、<Accent>ついでに</Accent>、この手紙を出して来てくれませんか。
        </JP>
      ),
    },
    {
      meaningNumber: 2,
      exampleNumber: 1,
      content: (
        <JP>
          ⑴　京都へ行く<Accent>ついでに</Accent>、奈良を回ってみたい。
        </JP>
      ),
    },
    {
      meaningNumber: 2,
      exampleNumber: 2,
      content: (
        <JP>
          ⑵　洗濯機を直す<Accent>ついでに</Accent>、ドアの取っ手も直してもらった。
        </JP>
      ),
    },
    {
      meaningNumber: 2,
      exampleNumber: 2,
      content: (
        <JP>
          ⑵　洗濯機を直す<Accent>ついでに</Accent>、ドアの取っ手も直してもらった。
        </JP>
      ),
    },
  ]
};