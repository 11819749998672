import React, {useCallback} from 'react';
import dayjs, { Dayjs } from 'dayjs';
import DialogTitle from "@mui/material/DialogTitle";
import {JP, KanjiImageContainer} from "../../style";
import {DialogContent} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


type Props = {
  open: boolean;
  onClose: (value: string) => void;
  create: (selectedDate: Dayjs) => void;
}

export const LessonDialogue = (props: Props) => {
  const { onClose, open } = props;
  // dayjs('2022-04-17')
  const [value, setValue] = React.useState<Dayjs | null>(null); // dayjs()

  const handleCreateLessonClick = useCallback(() => {
    console.log('Create new lesson', value);
    if (value) {
      props.create(value);
      setValue(null);
    }
  }, [value, props.create]);

  const handleDateClick = (e: string) => {

  };

  return (
    <Dialog onClose={onClose} open={open}
      style={{
        height: '500px'
      }}
    >
      <DialogTitle><JP>TESTING</JP></DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
            <DateTimePicker
              label="Basic date picker"
              value={value}
              onChange={(newValue) => setValue(newValue)}
            />
          </DemoContainer>
        </LocalizationProvider>
        <button onClick={handleCreateLessonClick}>
          Create Lesson
        </button>
      </DialogContent>
    </Dialog>
  );
};