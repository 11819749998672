import React, { useRef, useState, useEffect, useContext } from 'react';
import { Grid } from "@mui/material";
import axios from "../../api/axios";
import { useAuthContext } from "../../context/AuthProvider";
import { DIALOGUE_MODES, DialogueModeType } from "../menu/Menu";
import { StyledButton, StyledSection, JP } from "../../style";

const LOGIN_URL = '/auth'; // TODO on the server

type Props = {
  onModeChange: (mode: DialogueModeType) => void;
  onSuccess: () => void;
}

export const Login: React.FC<Props> = (props) => {
  const { setAuth, persist, setPersist } = useAuthContext();
  const userRef: React.MutableRefObject<any> = useRef();
  const errRef: React.MutableRefObject<any> = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // To not to reload the page
    // console.log(user, pwd);

    try {
      // LOGIN_URL will be attached to the base url
      const response = await axios.post(LOGIN_URL,
        JSON.stringify({user, pwd}),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      );
      //console.log('response', response);
      //console.log(JSON.stringify(response?.data));
      // console.log(JSON.stringify(response));
      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles; // Just the numbers // TODO add to server
      setAuth({user, pwd, roles, accessToken});
      setUser('');
      setPwd('');
      setSuccess(true);
      props.onSuccess();
    } catch (err: any) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if(err.response?.status === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
      errRef.current.focus();
    }
  };

  const togglePersist = (): void => {
    setPersist((prev: boolean) => !prev);
  };

  useEffect(() => {
    // Update localStorage
    localStorage.setItem('persist', JSON.stringify(persist));
  }, [persist]);

  if (success) {
    return null;
  } else {
    return (
      <StyledSection style={{ padding: 0 }}>
        <StyledSection>
          <Grid container justifyContent='row' rowSpacing={2}>
            <Grid item xs={12}>
              <p
                ref={errRef}
                className={errMsg ? 'errmsg' : 'offscreen'}
                aria-live='assertive'
              >
                {errMsg}
              </p>
            </Grid>
            <Grid item xs={12}>
              <h1>Войти</h1>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit}>
                <Grid container justifyContent='row' rowSpacing={2}>
                  <Grid item xs={12}>
                    <label htmlFor='username'>Имя пользователя (логин):</label>
                  </Grid>
                  <Grid item xs={12}>
                    {
                      // Controlled input (in order to clean it up later
                    }
                    <input
                      type='text'
                      id='username'
                      ref={userRef}
                      autoComplete='off'
                      onChange={(e) => setUser(e.target.value)}
                      value={user}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label htmlFor='password'>Пароль:</label>
                  </Grid>
                  <Grid item xs={12}>
                    <input
                      type='password'
                      id='password'
                      onChange={(e) => setPwd(e.target.value)}
                      value={pwd}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledButton>Войти</StyledButton>
                  </Grid>
                  <Grid item xs={12}>
                    <div className='persistCheck'>
                      <input
                        type='checkbox'
                        id='persist'
                        onChange={togglePersist}
                        checked={persist}
                      />
                      <label htmlFor='persist'>
                        Доверять устройству (если это Ваш личный компьютер или телефон)
                      </label>
                    </div>
                  </Grid>
                </Grid>
                <br />
                <p>
                  Новый пользователь?<br />
                  <span className='line'>
                    <a onClick={() => props.onModeChange(DIALOGUE_MODES.REGISTER)}>Зарегистрироваться</a>
                  </span>
                </p>
              </form>
            </Grid>
          </Grid>
        </StyledSection>
      </StyledSection>
    );
  }
}