import lesson_26_kaiwa from '../../../assets/mnn/26-kaiwa.mp3';
import lesson_26_mondai_1 from '../../../assets/mnn/26-mondai-1.mp3';
import lesson_26_mondai_2 from '../../../assets/mnn/26-mondai-2.mp3';
import lesson_26_choukai_1 from '../../../assets/mnn/choukai/26-choukai-1.mp3';
import lesson_26_choukai_2 from '../../../assets/mnn/choukai/26-choukai-2.mp3';
import lesson_26_choukai_3 from '../../../assets/mnn/choukai/26-choukai-3.mp3';
import lesson_26_choukai_4 from '../../../assets/mnn/choukai/26-choukai-4.mp3';

import { MnnAudioFileType } from "../mnnAudioFiles";

export const mnnLesson26: MnnAudioFileType[] = [
  {
    fileName: '会話',
    file: lesson_26_kaiwa,
    lessonNumber: 26,
    type: '教科書',
  },
  {
    fileName: '問題　１番',
    file: lesson_26_mondai_1,
    lessonNumber: 26,
    type: '教科書',
  },
  {
    fileName: '問題　２番',
    file: lesson_26_mondai_2,
    lessonNumber: 26,
    type: '教科書',
  },
  {
    fileName: '１番',
    file: lesson_26_choukai_1,
    lessonNumber: 26,
    type: '聴解',
  },
  {
    fileName: '２番',
    file: lesson_26_choukai_2,
    lessonNumber: 26,
    type: '聴解',
  },
  {
    fileName: '３番',
    file: lesson_26_choukai_3,
    lessonNumber: 26,
    type: '聴解',
  },
  {
    fileName: '４番',
    file: lesson_26_choukai_4,
    lessonNumber: 26,
    type: '聴解',
  },
];