import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const youna: OneGrammarType = {
  name: '〜ようなN',
  level: 'N4',
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <JP>
          例をあげる場合に使う。くだけた言い方では「みたいな」に言い換えられる。
        </JP>
      ),
      translation: (
        <>
          <RUS>
            Такой, как. После <JP>ような</JP> стоит существительное <JP>名詞</JP>,
            для которого приводим пример.
            <br />
          </RUS>
        </>
      ),
    }
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <>
          ①　風邪をひいたときは、みかんの<Accent>ような</Accent>ビタミンC{''}
          を多く含む果物を食べるといい。
        </>
      ),
    }
  ],
};