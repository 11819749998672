import lesson_29_kaiwa from "../../../assets/mnn/29-kaiwa.mp3";
import lesson_29_mondai_1 from "../../../assets/mnn/29-mondai-1.mp3";
import lesson_29_mondai_2 from "../../../assets/mnn/29-mondai-2.mp3";

import { MnnAudioFileType } from "../mnnAudioFiles";

export const mnnLesson29: MnnAudioFileType[] = [
  {
    fileName: '会話',
    file: lesson_29_kaiwa,
    lessonNumber: 29,
    type: '教科書',
  },
  {
    fileName: '問題　１番',
    file: lesson_29_mondai_1,
    lessonNumber: 29,
    type: '教科書',
  },
  {
    fileName: '問題　２番',
    file: lesson_29_mondai_2,
    lessonNumber: 29,
    type: '教科書',
  },
];