import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const sappariNai: OneGrammarType = {
  name: 'さっぱり〜ない',
  level: 'N2',
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <RUS>
          Used to emphasize negative expressions (mostly).
          Often implies that things do not go as expected.
          <br /><br />
          Присоединяется к глаголам в ない форме,
          подчёркивает отрицание.
          <br /><br />
          Нюанс: <b>часто подразумевает, что что-то идёт
          не так, как запланировано, вразрез с ожиданиями</b>.
          Человек ожидал противоположное.
        </RUS>
      ),
    }
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <JP>
          ①　あの人の話はいつもむずかしいことばがたくさんでてきて
          <Accent>さっぱり</Accent>わから<Accent>ない</Accent>。
        </JP>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <JP>
          ②　最近山田さんから<Accent>さっぱり</Accent>
          連絡が<Accent>ない</Accent>ね。
        </JP>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 3,
      content: (
        <JP>
          ③　辞書をいくら使ってもこの本は<Accent>さっぱり</Accent>
          理解でき<Accent>ない</Accent>。
        </JP>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 4,
      content: (
        <JP>
          ④　これだけ努力しているのに<Accent>さっぱり</Accent>
          上達し<Accent>ない</Accent>のは、これは私のせいではなく、
          日本語そのもののせいなのではないでしょうか。
        </JP>
      ),
    }
  ],
};