import lesson_31_kaiwa from "../../../assets/mnn/31-kaiwa.mp3";
import lesson_31_mondai_1 from "../../../assets/mnn/31-mondai-1.mp3";
import lesson_31_mondai_2 from "../../../assets/mnn/31-mondai-2.mp3";

import { MnnAudioFileType } from "../mnnAudioFiles";

export const mnnLesson31: MnnAudioFileType[] = [
  {
    fileName: '会話',
    file: lesson_31_kaiwa,
    lessonNumber: 31,
    type: '教科書',
  },
  {
    fileName: '問題　１番',
    file: lesson_31_mondai_1,
    lessonNumber: 31,
    type: '教科書',
  },
  {
    fileName: '問題　２番',
    file: lesson_31_mondai_2,
    lessonNumber: 31,
    type: '教科書',
  },
];