import * as React from 'react';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import {DialogContent} from "@mui/material";
import { JP } from "../../style";
import 一Genki from '../../assets/kanji/n5/一genki.png';
import { KanjiImageContainer } from "../../style";
import ZoomImage from "../zoom-image";

const emails = ['username@gmail.com', 'user02@gmail.com'];

export interface Props {
  open: boolean;
  onClose: (value: string) => void;
  kanji: string;
  images: string[];
}

export const KanjiDialogue = (props: Props) => {
  const { onClose, open } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      {
        // <DialogTitle><JP>{props.kanji}</JP></DialogTitle>
      }
      <DialogContent>
        {
          (props.images.length > 0) &&
          <>
            {
              props.images.map((image, i) => {
                return (
                  <KanjiImageContainer key={i}>
                    <ZoomImage image={image} />
                  </KanjiImageContainer>
                );
              })
            }
          </>
        }
      </DialogContent>
    </Dialog>
  );
}