import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import { FlashcardContext, DataType, PaginationType } from "./FlashcardContext";
// import {FlashcardType, MnnListType} from "../../data/style";
import { FlashcardType, MnnListType } from "./FlashcardContext";
import axios from "axios";
import _isNil from 'lodash/isNil';
import _find from "lodash/find";
import _forEach from 'lodash/forEach';
import update from "immutability-helper";
import {useParams} from "react-router-dom";
// import { FormAddFlashcard } from "../../form-add-flashcard/FormAddFlashcard";
import {toNumber} from "lodash";
import { KANJI_BOOK } from "./kanji_book";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

interface FlashcardContextProviderInterface {
  children: React.ReactNode;
  lesson: number;
  level: number;
  deckName?: string;
  grammarName?: string;
  // content: React.ReactNode;
}

const pageSize = 7; // How many cards per page to show, default number

const ADD_FLASHCARD_URL = 'http://localhost:3001/api/kanjiBook/addFlashcard';
const GET_FLASHCARDS_URL = 'http://localhost:3001/api/kanjiBook/getFlashcards';

const FlashcardContextProvider: React.FC<FlashcardContextProviderInterface> = (props) => {
  // const { level, lesson, name, label, id } = useParams();
  const axiosPrivate = useAxiosPrivate();

  const flashcardContext = useContext(FlashcardContext);
  const [pagination, setPagination] = useState<PaginationType>({
    count: 0,
    from: 0,
    to: pageSize,
  });
  const [flashcards, setFlashcards] = useState<FlashcardType[]>([]);
  const [data, setData] = useState<DataType | null>(null);


  const updateData = (id: number, isOpen: boolean): void => {
    if (_isNil(data)) {
      console.error('No data, cannot update');
      return;
    }

    const dataUpdated = update(data, {
      list: {
        $apply: (flashcards: FlashcardType[]) => {
          return flashcards.map((flashcard) => {
            if (flashcard.id === id){
              return update(flashcard, {
                isOpen: {
                  $set: isOpen,
                },
              });
            } else {
              return flashcard;
            }
          });
        },
      }
    });
    setData(dataUpdated);
  };

  const getTargetLesson = (level: number, lesson: number, name: string, flashcards: MnnListType[]): MnnListType | null => {
    // searches KANJI_BOOK
    const found = _find(flashcards, (flashcard) => {
      return (flashcard.lesson === lesson && (flashcard.name === name));
    });

    return (found) ? found : null;
  };

  const getCards = async (
    textbookName: string,
    lessonNumber: number,
    controller: AbortController,
  ) => {
    try {
      const response = await axiosPrivate.get('/flashcards', {
        signal: controller.signal,
        params: {
          lessonNumber: lessonNumber, // TODO Make dynamic
          textbookName: textbookName, // TODO Make dynamic
        },
      });
      return response.data;
    } catch (err) {
      console.log('in error');
      console.error(err);
      throw err;
    }
  };

  const getFlashcardsFromApi = async (
    controller: AbortController,
  ): Promise<FlashcardType[]> => {
    try {
      const config = {
        signal: controller.signal,
      }
      const controller2 = new AbortController();
      // It will receive 401 until it sends accessToken
      const response = await axiosPrivate.get('/flashcards', controller2);
      /*
      const response = await axios.get<FlashcardType[]>(GET_FLASHCARDS_URL, {
        params: { lesson: props.lesson, deckName: props.deckName }
      });
       */
      // console.log('response', response.data);
      return response.data;

      // return response.data.data;
      // return [testFlashcard_1, testFlashcard_2];
    } catch (err) {
      console.log(err);
      throw new Error('error');
    }
  };

  useEffect(() => {
    if (!flashcards) {
      const newData = {
        count: 0,
        list: [],
      };
      setData(newData);
    } else {
      // Update data
      const sliced = flashcards.slice(pagination.from, pagination.to); // list.flashcards.slice(from, to);
      // console.log('sliced', sliced, level, lesson, name, label, id);
      if (props.level && props.lesson && props.deckName) {
        // console.log(props.level, props.lesson, props.deckName);
        const name = '介';
        const targetLesson = getTargetLesson(props.level, props.lesson, name, KANJI_BOOK);
        if (targetLesson) {
          const targetLessonUpdated = update({}, {
            flashcards: {
              $set: sliced,
            }
          });
          const newData: DataType = {
            count: flashcards.length, //list.flashcards.length,
            list: sliced,
          };
          setData(newData);
          setPagination({
            ...pagination,
            count: newData ? newData.count : 0,
          });
        }

      }


    }
  }, [flashcards, props]);

  const fetchFlashcards = useCallback(async (
    textbookName: string | null,
    lessonNumber: number | null,
    controller: AbortController,
  ): Promise<FlashcardType[]> => {
    try {
      if (!textbookName || !lessonNumber) {
        // TODO Reset newData
        setFlashcards([]);
        return [];
      } else {
        const flashcards = await getCards(textbookName, lessonNumber, controller);
        setFlashcards(flashcards);
        return flashcards;
      }
    } catch (error) {
      console.log('Received an error');
      console.error(error);
      throw error;
    }
  }, [
    props.lesson, props.deckName, pagination.from, pagination.to]);

  useEffect(  () => {

  }, []);

  const handlePageChange = (e: any, page: number) => {
    // console.log('changing pagination', page);
    const from = (page - 1) * pageSize;
    const to = (page - 1) * pageSize + pageSize;
    setPagination({ ...pagination, from: from, to: to });
    // console.log('crr flashcards', flashcards);
    const sliced = flashcards.slice(from, to); // list.flashcards.slice(from, to);
    // console.log('sliced', sliced);
    // console.log('sliced', sliced, level, lesson, name, label, id);
    const newData: DataType = {
      count: flashcards.length, //list.flashcards.length,
      list: sliced,
    };
    setData(newData);

    setPagination({
      ...pagination,
      count: newData ? newData.count : 0,
    });
  };

  return (
    <FlashcardContext.Provider
      value={{
        ...flashcardContext,
        lesson: props.lesson,
        level: props.level,
        deckName: props.deckName,
        grammarName: props.grammarName,
        content: <>No content</>,
        data,
        updateData,
        addFlashcardUrl: ADD_FLASHCARD_URL,
        pagination,
        setPagination,
        handlePageChange,
        fetchFlashcards,
        pageSize,
        setFlashcards,
      }}
    >
      {props.children}
    </FlashcardContext.Provider>
  )
};

export { FlashcardContextProvider };