import React from 'react';

export const LessonType1: React.FC = () => {
  return (
    <>
      Индивидуальные занятия построены под нужды каждого ученика.
      В зависимости от уровня и желаемых целей, каждый ученик учит
      наиболее подходящий материал. Кто-то готовится поехать в Японию,
      кто-то хочет сдать экзамен нихонго норёку сикэн,
      кому-то нужна помощь в освоении университетской программы, а кто-то хочет
      смотреть аниме в оригинале или играть в игры на японском языке.
    </>
  );
};