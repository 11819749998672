import React, { useState } from 'react';
import { Launch, Close, HelpOutline } from "@mui/icons-material";
import { JP, ReadMore, ReadMoreText, RUS } from "../../style";
import { Card } from "../card/Card";
import { colors } from "../../utils/palette";

type Props = {
  meaning: React.ReactNode;
  meaningNumber: number;
  translation?: React.ReactNode;
};

export const RenderMeaning: React.FC<Props> = (props) => {
  const { meaning, meaningNumber, translation } = props;
  const [isReadMore, setIsReadMore] = useState(false);

  const handleReadMore = (): void => {
    setIsReadMore(!isReadMore);
  };

  return (
    <>
      <Card
        lineHeight={1.4}
        lessonTitleRus={`Значение ${meaningNumber}`}
        lessonDescription={meaning}
        backgroundColor={colors.pale.blue}
        padding={'3vh'}

      />
      {
        translation &&
        <ReadMore onClick={handleReadMore} style={{ display: 'inline-block' }}>
          {
            isReadMore
              ?  (
                <Close
                  fontSize='medium'
                  style={{
                    color: colors.blue,
                    marginBottom: '-5px',
                  }}
                />
              )
              : (
                <HelpOutline
                  fontSize='medium'
                  style={{
                    color: colors.blue,
                    marginBottom: '-5px',
                  }}
                />
              )
          }
        </ReadMore>
      }
      <ReadMoreText $isVisible={isReadMore}>
        <Card
          lineHeight={1.4}
          lessonDescription={
            <>
              {
                translation &&
                <>
                  {translation}
                </>
              }
            </>
          }
          backgroundColor={'rgba(191,210,140, 0.3)'}
          padding={'3vh'}
        />
      </ReadMoreText>
    </>
  );
};