import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const komu: OneGrammarType = {
  name: '〜込む（こむ）',
  level: 'N3',
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <RUS>
          Помещать (класть) что-то внутрь.
          Присоединяется ко 2ой основе глагола,
          глагол становится переходным <JP>他動詞</JP>
          {' '}(нужна частица <JP>を</JP>).
        </RUS>
      ),
    },
    {
      meaningNumber: 2,
      content: (
        <RUS>
          Непереходный глагол <JP>自動詞</JP>
          {' '}(нужна частица <JP>は／が</JP>).
          Означает заходить внутрь помещения (буквально)
          или вступать внутрь чего-то (образно, внутрь какой-то группы).
        </RUS>
      ),
    },
    {
      meaningNumber: 3,
      content: (
        <RUS>
          Быть увлечённым каким-либо действием,
          быть глубоко погружённым в процесс.
        </RUS>
      ),
    },
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <JP>
          ①　ここに名前<Accent>を</Accent>書き
          <Accent>こんで</Accent>ください。
        </JP>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <JP>
          ②　かばんに本<Accent>を</Accent>つめ<Accent>こんで</Accent>
          旅にでかけた。
        </JP>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 3,
      content: (
        <JP>
          ③　トラックに荷物<Accent>を</Accent>積み<Accent>こむ</Accent>
          のを手伝った。
        </JP>
      ),
    },
    {
      meaningNumber: 2,
      exampleNumber: 4,
      content: (
        <JP>
          ④　その客<Accent>は</Accent>家にあがり<Accent>こんで</Accent>、
          もう５時間も帰らない。
        </JP>
      ),
      translation: (
        <RUS>
          Глагол <JP>上がり込む　あがりこむ</JP>,
          дословно подняться на порог <JP>上がる</JP>{' '}
          и зайти внутрь <JP>込む</JP>.
          Этот гость, после того, как зашёл внутрь дома, уже целых пять часов оттуда не выходит.
        </RUS>
      ),
    },
    {
      meaningNumber: 3,
      exampleNumber: 7,
      content: (
        <JP>
          ⑦　友達と話し<Accent>込んで</Accent>いたら
          いつのまにか朝になっていた。
        </JP>
      ),
      translation: (
        <RUS>
          Я так увлечённо разговаривал с другом, что когда мы закончили,
          оказалось, что уже рассвело
          (как-то незаметно для меня самого наступило утро).
        </RUS>
      ),
    }
  ],
};