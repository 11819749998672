import React from 'react';
import axios from "../api/axios";
import { useAuthContext, AuthType } from "../context/AuthProvider";

// () => () => Promise<any>
const useRefreshToken: any  = () => {
  const { setAuth } = useAuthContext();

  const refresh = async () => {
    const response = await axios.get('/refresh', {
      withCredentials: true
    });
    setAuth((prev: any) => {
      //console.log(JSON.stringify(prev));
      //console.log(response.data.accessToken);
      return {
        ...prev,
        roles: response.data.roles,
        accessToken: response.data.accessToken,
      };
    });
    return response.data.accessToken;
  }
  return refresh;
};

export default useRefreshToken;