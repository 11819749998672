import React, { useCallback } from 'react';
import { Divider } from "@mui/material";
import { RenderExample } from "./RenderExample";
import { RenderMeaning } from "./RenderMeaning";
import { ExampleType, OneGrammarType } from "./types";
import { colors } from "../../utils/palette";
import { RUS, JP } from "../../style";

export const RenderGrammar: React.FC<OneGrammarType> = (props) => {
  //console.log('props', props);
  const { name, level, meanings, examples } = props;

  const getExamples = useCallback((meaningNumber: number): ExampleType[] => {
    const list: ExampleType[] = [];
    examples.map((example) => {
      if (example.meaningNumber === meaningNumber) {
        list.push(example);
      }
    });
    return list;
  }, [examples]);

  return (
    <>
      {
        meanings.map((meaning, i) => {
          const relevantExamples = getExamples(meaning.meaningNumber);
          return (
            <div key={`grammar-${name}-${i}`}>
              <RenderMeaning
                meaning={meaning.content}
                meaningNumber={meaning.meaningNumber}
                translation={meaning.translation}
              />
              {
                relevantExamples.map((example, i) => {
                  return (
                    <div key={i}>
                      <div
                        style={{
                          // backgroundColor:colors.pale.blue,
                          // background:'linear-gradient(to right,rgba(97,218,250,0.3), rgba(97,218,250,0))',
                          borderColor:colors.blue,
                          color:colors.blue,
                          borderWidth: '1px',
                          borderStyle: 'solid',
                          padding:'0.3rem 1rem 0.3rem 1rem',
                          margin:'1rem 0 0 0',
                          width:'120px',
                          borderRadius:'20px',
                          display: 'flex',
                          justifyContent:'center',
                        }}
                      >
                        <RUS><b>Пример {i+1}</b></RUS>
                      </div>
                      <RenderExample
                        key={`example-${i}`}
                        exampleSentence={example.content}
                        exampleSentenceNumber={example.exampleNumber}
                        translation={example.translation}
                      />
                    </div>
                  );
                })
              }
            </div>
          );
        })
      }
    </>
  );
};