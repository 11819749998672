import React, {useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import {RUS, JP_2} from "../../style";
import {StyledRow} from "../users/style";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { LessonType } from "./types";
import { useUsersContext } from "../users/UsersContext";

const CREATE_LESSON_URL = '/lessons/lesson';

export const Lessons: React.FC = () => {
  const { getUsername } = useUsersContext();
  const axiosPrivate = useAxiosPrivate();
  const [lessons, setLessons] = useState<LessonType[]>([]);

  useEffect(() => {
    let isMounted = true;
    // Previously cancellationToken,
    // in order to cancel a request if the component unmounts
    const controller = new AbortController();

    const getLessons = async () => {
      try {
        const response = await axiosPrivate.get(CREATE_LESSON_URL, {
          signal: controller.signal,
        });
        console.log('lessons', response.data);
        isMounted && setLessons(response.data);
      } catch (err) {
        console.error(err);
      }
    }
    getLessons().catch(console.error);
    // It will receive 401 until it sends accessToken

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, []);

  return (
    <>
      <h4>Lessons</h4>
      {lessons?.length
        ? (
          <Grid container flexDirection='row' flexWrap='wrap' rowSpacing={2}>
            <Grid item xs={12}>
              <Grid item xs={2}>
                <RUS><b>Имя</b></RUS>
              </Grid>
            </Grid>
            {
              lessons.map((lesson, i) => {
                const lessonDate: Date = new Date(lesson?.lessonDate);
                return (
                  <StyledRow key={i} item xs={12}>
                    <Grid item xs={12} sm={2}>
                      {getUsername(lesson?.userId)}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <JP_2>
                        {lessonDate.toLocaleString('ja-Jpan-JP', { weekday: 'short' })}{'　'}
                        {lessonDate.getFullYear()}年
                        {lessonDate.getMonth()+1}月
                        {lessonDate.getDate()}日{'　'}
                        {lessonDate.toLocaleString('ja-Jpan-JP', { hour: '2-digit', minute: '2-digit' })}
                      </JP_2>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      {lesson?.lessonName.split(';').map((part, i) => {
                        return (
                          <Grid item xs={12} key={i}>{part}</Grid>
                        );
                      })}
                    </Grid>
                  </StyledRow>
                );
              })
            }
          </Grid>
        )
        : (
          <p>No lessons found.</p>
        )
      }
    </>
  );
};