import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const sura: OneGrammarType = {
  name: '〜すら',
  level: 'N1',
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <JP>
          N(＋助詞)すら
          <br /><br />
          「さえ」の意味。一つの例をとりあげて、それでさえこうだということを並べ、
          その他はもちろんのことだという含みを持たせる。
          ⑴は「ふつうのひとはもちろんのこと、子供でも知っている」の意味。
          <br /><br />
          接続：<br />
          名詞や「名詞＋助詞」に付く。主格に付くときは、「ですら」になることが多い。
          改まった書き言葉的な表現。
        </JP>
      ),
      translation: (
        <>
          <JP>主格　しゅかく</JP> именительный падеж, nominative case,
          имеется в виду, когда слово является подлежащим (вместо частицы <JP>は</JP>)<br />
          <JP>改まった　あらたまった</JP> официальная (речь), ceremonious, formal<br />
        </>
      ),
    }
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <>
          ⑴　そんなことは子供<Accent>ですら</Accent>知っている。
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <JP>
          ⑵　むかし世話になった人の名前<Accent>すら</Accent>忘れてしまった。
        </JP>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 3,
      content: (
        <JP>
          ⑶　この寒さで、あの元気な加藤さん<Accent>ですら</Accent>
          風邪を引いている。
        </JP>
      ),
      translation: (
        <>
          <JP>加藤（かとう）さん</JP><br />
          <JP>風邪を引く　かぜをひく</JP> заболеть<br />
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 4,
      content: (
        <JP>
          ⑷　大企業はもちろんのこと、この辺の町工場<Accent>ですら</Accent>
          週休２日だという。
        </JP>
      ),
      translation: (
        <>
          <JP>企業　きぎょう</JP> company / enterprise / corporation<br />
          <JP>大企業　だいきぎょう</JP> large company / enterprise / corporation<br />
          <JP>この辺　このへん</JP> this area, around here<br />
          <JP>町工場　まちこうば</JP> small factory in town<br />
          <JP>週休　しゅうきゅう</JP> weekly holiday<br />
          <JP>２日　ふつか</JP><br />
        </>
      ),
    }
  ]
};