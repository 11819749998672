import React from 'react';
import axios from "../api/axios";
import { useAuthContext } from "../context/AuthProvider";

const useLogout = () => {
  const { setAuth } = useAuthContext();

  const logout = async () => {
    setAuth(null);
    try {
      const response = await axios('/logout', {
        withCredentials: true,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return logout;
};

export default useLogout;