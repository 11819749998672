
import lesson_28_kaiwa from '../../assets/mnn/28-kaiwa.mp3';
import lesson_28_mondai_1 from '../../assets/mnn/28-mondai-1.mp3';
import lesson_28_mondai_2 from '../../assets/mnn/28-mondai-2.mp3';

import lesson_29_kaiwa from '../../assets/mnn/29-kaiwa.mp3';
import lesson_29_mondai_1 from '../../assets/mnn/29-mondai-1.mp3';
import lesson_29_mondai_2 from '../../assets/mnn/29-mondai-2.mp3';

import lesson_30_kaiwa from '../../assets/mnn/30-kaiwa.mp3';
import lesson_30_mondai_1 from '../../assets/mnn/30-mondai-1.mp3';
import lesson_30_mondai_2 from '../../assets/mnn/30-mondai-2.mp3';

import lesson_31_kaiwa from '../../assets/mnn/31-kaiwa.mp3';
import lesson_31_mondai_1 from '../../assets/mnn/31-mondai-1.mp3';
import lesson_31_mondai_2 from '../../assets/mnn/31-mondai-2.mp3';

import lesson_32_kaiwa from '../../assets/mnn/32-kaiwa.mp3';
import lesson_32_mondai_1 from '../../assets/mnn/32-mondai-1.mp3';
import lesson_32_mondai_2 from '../../assets/mnn/32-mondai-2.mp3';

import lesson_33_kaiwa from '../../assets/mnn/33-kaiwa.mp3';
import lesson_33_mondai_1 from '../../assets/mnn/33-mondai-1.mp3';
import lesson_33_mondai_2 from '../../assets/mnn/33-mondai-2.mp3';

import lesson_34_kaiwa from '../../assets/mnn/34-kaiwa.mp3';
import lesson_34_mondai_1 from '../../assets/mnn/34-mondai-1.mp3';
import lesson_34_mondai_2 from '../../assets/mnn/34-mondai-2.mp3';

import lesson_35_kaiwa from '../../assets/mnn/35-kaiwa.mp3';
import lesson_35_mondai_1 from '../../assets/mnn/35-mondai-1.mp3';
import lesson_35_mondai_2 from '../../assets/mnn/35-mondai-2.mp3';

import lesson_36_kaiwa from '../../assets/mnn/36-kaiwa.mp3';
import lesson_36_mondai_1 from '../../assets/mnn/36-mondai-1.mp3';
import lesson_36_mondai_2 from '../../assets/mnn/36-mondai-2.mp3';

import lesson_37_kaiwa from '../../assets/mnn/37-kaiwa.mp3';
import lesson_37_mondai_1 from '../../assets/mnn/37-mondai-1.mp3';
import lesson_37_mondai_2 from '../../assets/mnn/37-mondai-2.mp3';

import lesson_38_kaiwa from '../../assets/mnn/38-kaiwa.mp3';
import lesson_38_mondai_1 from '../../assets/mnn/38-mondai-1.mp3';
import lesson_38_mondai_2 from '../../assets/mnn/38-mondai-2.mp3';

import lesson_39_kaiwa from '../../assets/mnn/39-kaiwa.mp3';
import lesson_39_mondai_1 from '../../assets/mnn/39-mondai-1.mp3';
import lesson_39_mondai_2 from '../../assets/mnn/39-mondai-2.mp3';

import { mnnLesson26 } from "./mnn/lesson-26";
import { mnnLesson27 } from "./mnn/lesson-27";
import { mnnLesson28 } from "./mnn/lesson-28";
import { mnnLesson29 } from "./mnn/lesson-29";
import { mnnLesson30 } from "./mnn/lesson-30";
import { mnnLesson31 } from "./mnn/lesson-31";
import { mnnLesson32 } from "./mnn/lesson-32";
import { mnnLesson33 } from "./mnn/lesson-33";
import { mnnLesson34 } from "./mnn/lesson-34";
import { mnnLesson35 } from "./mnn/lesson-35";
import { mnnLesson36 } from "./mnn/lesson-36";
import { mnnLesson37 } from "./mnn/lesson-37";
import { mnnLesson38 } from "./mnn/lesson-38";
import { mnnLesson39 } from "./mnn/lesson-39";

export type MnnAudioFileType = {
  fileName: string;
  file: string;
  type: string;
  lessonNumber: number;
}

export const mnnAudioFiles: MnnAudioFileType[] = [
  ...mnnLesson26,
  ...mnnLesson27,
  ...mnnLesson28,
  ...mnnLesson29,
  ...mnnLesson30,
  ...mnnLesson31,
  ...mnnLesson32,
  ...mnnLesson33,
  ...mnnLesson34,
  ...mnnLesson35,
  ...mnnLesson36,
  ...mnnLesson37,
  ...mnnLesson38,
  ...mnnLesson39,
];