import React, { useState, useEffect } from "react";
import { CustomButton } from "./style";

type UseAudioType = [boolean, (() => void)];

export const useAudio = (url: string): UseAudioType => {
  const [audio, setAudio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    setAudio(new Audio(url));
  }, [url]);

  useEffect(() => {
    if (playing) {
      audio.play().catch(console.error);
    } else {
      audio.pause();
    }
  }, [playing]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

type PlayerType = {
  url: string;
}
export const Player: React.FC<PlayerType> = ({ url }) => {
  const [playing, toggle] = useAudio(url);

  return (
    <div>
      <CustomButton onClick={toggle}>{playing ? "Пауза" : "Слушать"}</CustomButton>
    </div>
  );
};