import React from 'react';
import {Grid} from "@mui/material";

type Props = {
  lineHeight: number;
  heading: string;
};

export const Header: React.FC<Props> = (props) => {
  const { lineHeight, heading } = props;
  return (
    <Grid
      container
      justifyContent='center'
    >
      <Grid item>
        <div style={{
          display: 'flex',
          width: 'auto',
          fontFamily: 'Arial',
          lineHeight: lineHeight,
          fontSize: '16px',
          fontWeight: 600,
        }}>
          {heading}
        </div>
      </Grid>
    </Grid>
  );
};