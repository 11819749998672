import React from 'react';
import { ThreeDots } from "react-loader-spinner";

const DEFAULT_HEIGHT = '80px';
const DEFAULT_WIDTH = '80px';
const DEFAULT_COLOUR = '#61dafb';

type Props = {
  type:
    | 'Audio'
    | 'BallTriangle'
    | 'Bars'
    | 'Circles'
    | 'Grid'
    | 'Hearts'
    | 'Oval'
    | 'Puff'
    | 'Rings'
    | 'TailSpin'
    | 'ThreeDots'
    | 'Watch'
    | 'RevolvingDot'
    | 'Triangle'
    | 'Plane'
    | 'MutatingDots'
    | 'CradleLoader';
  height?: number | string;
  width?: number | string;
  visible?: boolean;
  color?: string;
  radius?: number; // Set radius if the loader has a circle element in it
};

export const Loader: React.FC<Props> = ({
  height = DEFAULT_HEIGHT,
  width = DEFAULT_WIDTH,
  visible = true,
  color = DEFAULT_COLOUR,
  radius,
}) => {
  return (
    <ThreeDots
      height={height}
      width={width}
      visible={visible}
      color={color}
      radius={radius}
    />
  );
};