import React from 'react';
import { SharedBox } from "../SharedBox";
import {ReadMore, ReadMoreText} from "../../style";

type Props = {
  backgroundColor: string;
  padding: string;
  handleReadMore: () => void;
  isReadMore: boolean;
};

// '0vh 5vh 0vh 5vh'
export const AdvantageCard: React.FC<Props> = (props) => {
  const {
    backgroundColor,
    padding,
    handleReadMore,
    isReadMore,
  } = props;
  return (
    <SharedBox
      padding={padding}
      backgroundColor={backgroundColor}
    >
      <br />
      На занятиях ученики учат{' '}
      <span style={{ fontWeight: 600, textDecoration: 'none' }}>
                        токийский диалект
                      </span>, самый популярный,
      он называется{' '}
      <span style={{ fontFamily: 'KosugiMaru-Regular', fontWeight: 600, }}>
                        東京（とうきょう）弁（べん）
                      </span>{' '}
      <span style={{ fontWeight: 600, textDecoration: 'none' }}>
                        то:кё: бэн
                      </span> (двоеточие в транскрипции передаёт удлинение звука).
      Иероглиф{' '}
      <span style={{ fontFamily: 'KosugiMaru-Regular', fontWeight: 600, }}>
                        弁
                      </span>{' '}
      означает диалект.
      <ReadMore onClick={handleReadMore}>
        {isReadMore ?  'Спрятать текст' : 'Читать далее'}
      </ReadMore>

      <ReadMoreText $isVisible={isReadMore}>
        Есть ещё кансайский диалект{' '}
        <span style={{
          fontFamily: 'KosugiMaru-Regular',
          fontWeight: 600,
        }}>
                          関西（かんさい）弁（べん）
                        </span>{' '}
        <span style={{ fontWeight: 600, textDecoration: 'none' }}>
                          кансай бэн
                        </span>
        , на нём говорят в регионе Кансай{' '}
        <span style={{
          fontFamily: 'KosugiMaru-Regular',
          fontWeight: 600,
        }}>
                          関西（かんさい）地方（ちほう）
                        </span>{' '}
        <span style={{ fontWeight: 600, textDecoration: 'none' }}>
                          кансай тихо:
                        </span>.{' '}<br /><br />
        Регион Кансай также называют регионом Кинки{' '}
        <span style={{
          fontFamily: 'KosugiMaru-Regular',
          fontWeight: 600,
        }}>
                          近畿（きんき）地方（ちほう）
                        </span>{' '}
        <span style={{ fontWeight: 600, textDecoration: 'none' }}>
                          кинки тихо:
                        </span>.{' '}

        Он расположен в южно-центральной части Японии
        на самом большом острове Хонсю,
        в него входят 7 префектур:<br />
        ①　Вакаяма{' '}
        <span style={{
          fontFamily: 'KosugiMaru-Regular',
          fontWeight: 600,
        }}>
                          和歌山（わかやま）県（けん）
                        </span>{' '}
        <br />
        ②　Киото{' '}
        <span style={{
          fontFamily: 'KosugiMaru-Regular',
          fontWeight: 600,
        }}>
                          京都（きょうと）府（ふ）
                        </span>{' '}
        <br />
        ③　Миэ{' '}
        <span style={{
          fontFamily: 'KosugiMaru-Regular',
          fontWeight: 600,
        }}>
                          三重（みえ）県（けん）
                        </span>{' '}
        <br />
        ④　Нара{' '}
        <span style={{
          fontFamily: 'KosugiMaru-Regular',
          fontWeight: 600,
        }}>
                          奈良（なら）県（けん）
                        </span>
        <br />
        ⑤　Осака{' '}
        <span style={{
          fontFamily: 'KosugiMaru-Regular',
          fontWeight: 600,
        }}>
                          大阪（おおさか）府（ふ）
                        </span>
        <br />
        ⑥　Сига{' '}
        <span style={{
          fontFamily: 'KosugiMaru-Regular',
          fontWeight: 600,
        }}>
                          滋賀（しが）県（けん）
                        </span>
        <br />
        ⑦　Хиого{' '}
        <span style={{
          fontFamily: 'KosugiMaru-Regular',
          fontWeight: 600,
        }}>
                          兵庫（ひょうご）県（けん）
                        </span>
        <br /><br />
        Во всех этих префектурах могут говорить на кансайском диалекте.
        Однако, основным диалектом страны всё равно
        остаётся токийский – на нём же транслируется центральное телевидение.
        <br /><br />
        Есть и другие диалекты, но они менее популярны среди изучающих японский язык.
        <br /><br />
        Все ученики получают необходимую минимальную подготовку по фонетике,
        чтобы их речь была понятна японцам.
        <br /><br /><br />

      </ReadMoreText>
    </SharedBox>
  );
};