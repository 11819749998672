import React from "react";
import { Accent, Accent_2, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const はずだ: OneGrammarType = {
  name: '〜はずだ',
  level: 'N3', // n2n3
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <>
          Перевод: должен, должны<br />
          {
            // <JP><Accent>接続</Accent>［名］＋にかわって</JP><br />
          }
          <JP><Accent>意味</Accent>　確信をもって〜と推測する</JP><br />
          То, что стоит перед <JP>〜はず</JP>,
          это то, в чём я уверен (моё сильное убеждение <JP>確信</JP>).
          Основываясь на нём, я предполагаю, что ...
        </>
      ),
      translation: (
        <>
          <JP>確信（かくしん）</JP> conviction, убеждение<br />
          <JP>推測（すいそく）する</JP> to guess, предполагать<br />
        </>
      ),
    },
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <>
          <JP>⑴　
            会議の場所はメールで知らせておいたんだから、
            みんな知っている<Accent>はずだ</Accent>。
          </JP>
        </>
      ),
      translation: (
        <>
          <RUS>
            Я уверен, все знают о месте проведения кайги.
            Они должны знать, потому что я заранее проинформировал всех
            (казуатив: против их воли, насильно, хотели они того или нет)
            по имейлу.
          </RUS><br />
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <JP>
          ⑵　携帯電話をかばんに入れた<Accent>はずなのに</Accent>、
          見当たらない。
        </JP>
      ),
      translation: (
        <>
          Я уверен (сильное убеждение), что я положил мобильник в сумку...
          Я должен был его туда положить! Но вопреки этому (<JP>のに</JP>)
          я никак не вижу его
          (дословно: он никак не попадается мне на глаза).<br />
          <JP>見当たる（みあたる）</JP> попадать в поле зрения<br />
          <JP>入れた<Accent>はず</Accent></JP> я уверен, что положил (внутрь)<br />
        </>
      ),
    },
  ]
};