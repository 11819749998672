import React from 'react';
import { Grid } from "@mui/material";

type Props = {
  levels: number[];
  onWordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFuriganaChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onLevelChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onExampleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onCategoryChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onExampleClick: () => void;
  onCategoryClick: () => void;
  removeExamples: () => void;
  removeCategories: () => void;
  onTranslationChange: (value: string, language: string) => void;
}

export const Form: React.FC<Props> = (props) => {
  const handleRussianTranslationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onTranslationChange(e.target.value, 'RUS');
  };
  const handleEnglishTranslationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onTranslationChange(e.target.value, 'ENG');
  };
  return (
    <Grid container wrap='wrap' columnSpacing={1} rowSpacing={0}>
      <Grid item xs={12}>
        <label htmlFor='word'>Word</label>
      </Grid>
      <Grid item>
        <input id='word' onChange={props.onWordChange} />
      </Grid>
      <Grid item xs={12}>
        <label htmlFor='furigana'>Furigana</label>
      </Grid>
      <Grid item>
        <input id='furigana' onChange={props.onFuriganaChange} />
      </Grid>
      <Grid item xs={12}>
        <label htmlFor='translation_rus'>Russian Translation</label>
      </Grid>
      <Grid item>
        <input id='translation_rus' onChange={handleRussianTranslationChange} />
      </Grid>
      <Grid item xs={12}>
        <label htmlFor='translation_eng'>English Translation</label>
      </Grid>
      <Grid item>
        <input id='translation_eng' onChange={handleEnglishTranslationChange} />
      </Grid>
      <Grid item xs={12}>
        <label htmlFor='category'>Category</label>
      </Grid>
      <Grid item>
        <textarea id='category' onChange={props.onCategoryChange}/>
        <button onClick={props.onCategoryClick}>Add Category</button>
        <button onClick={props.removeCategories}>Remove All Categories</button>
      </Grid>
      <Grid item xs={12}>
        <label htmlFor='example'>Example</label>
      </Grid>
      <Grid item>
        <textarea id='example' onChange={props.onExampleChange}/>
        <button onClick={props.onExampleClick}>Add Example</button>
        <button onClick={props.removeExamples}>Remove All Examples</button>
      </Grid>
      <Grid item xs={12}>
        <label htmlFor='level'>Уровень (через запятую)</label>
      </Grid>
      <Grid item>
        <input id='level' onChange={props.onLevelChange} />
      </Grid>
    </Grid>
  );
};