import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { Divider, ListItemIcon, ListItemText } from '@mui/material';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import {Headphones, MusicNote, Menu, Login, Abc, EmojiFoodBeverage, LocalLibrary, Style} from "@mui/icons-material";
import {useCallback, useState} from "react";
import { JP, RUS, RUS_2 } from "../../style";
import { MenuDialogue } from "./MenuDialogue";
import { Login as LoginScreen } from '../login/Login';
import { Register as RegisterScreen } from "../login/Register";
import { colors } from "../../utils/palette";
import { useAuthContext } from "../../context/AuthProvider";
import useLogout from "../../hooks/useLogout";
import { MenuActions } from "./MenuActions";

type SubMenuType = {
  icon?: React.ReactNode;
  jpText: string;
  translation: string;
  redirectTo: string;
}

const subMenu: SubMenuType[] = [
  {
    icon: <LocalLibrary fontSize='small' style={{ color: colors.blue }} />,
    jpText: '漢字',
    translation: 'Кандзи',
    redirectTo: '/kanji',
  },
  {
    icon: <EmojiFoodBeverage fontSize='small' style={{ color: colors.blue }} />,
    jpText: '文法',
    translation: 'Грамматика',
    redirectTo: '/grammar',
  },
  {
    icon: <Abc fontSize='small' style={{ color: colors.blue }} />,
    jpText: '辞書',
    translation: 'Словарь',
    redirectTo: '/words',
  },
  {
    icon: <Headphones fontSize='small' style={{ color: colors.blue }} />,
    jpText: 'オーディオ',
    translation: 'Аудио',
    redirectTo: '/audio',
  },
  {
    icon: <Style fontSize='small' style={{ color: colors.blue }} />,
    jpText: 'フラッシュカード',
    translation: 'Карточки',
    redirectTo: '/flashcards',
  },
];

export enum DIALOGUE_MODES {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
}

export type DialogueModeType =
  | DIALOGUE_MODES.LOGIN
  | DIALOGUE_MODES.REGISTER;

export const MenuListComposition = () => {
  const [dialogueMode, setDialogueMode] = useState<DialogueModeType>(DIALOGUE_MODES.LOGIN);
  const [dialogueOpen, setDialogueOpen] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const logout = useLogout();
  const { auth, setAuth } = useAuthContext();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleRedirect = useCallback((path: string) => {
    navigate(path);
    setOpen(false);
  }, [navigate]);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleDialogueModeChange = (mode: DialogueModeType): void => {
    setDialogueMode(mode);
  }

  const handleLoginClick = (): void => {
    setOpen(false);
    setDialogueOpen(true);
  };

  const handleLogoutClick = async (): Promise<void> => {
    // console.log('logging out');
    // If used in more components, this should be in context
    setOpen(false);
    setDialogueOpen(false);
    await logout().then(
      () => { // Success
        setAuth(null);
      },
      failure => {
        console.error(failure);
      },
    );
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" spacing={2}>
      {
        /*
      <Paper>
        <MenuList>
          <MenuItem>Profile</MenuItem>
          <MenuItem>My account</MenuItem>
          <MenuItem>Logout</MenuItem>
        </MenuList>
      </Paper>
         */
      }
      <div>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Menu
            fontSize='medium'
            style={{
              color: 'white',
            }}
          />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          style={{
            zIndex: 100
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper style={{
                zIndex: 100,
                //backgroundColor: "yellow"
              }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {
                      subMenu.map((item, i) => {
                        return (
                          <MenuItem key={i} onClick={() => handleRedirect(item.redirectTo)}>
                            {
                              item.icon &&
                              <ListItemIcon>
                                {item.icon}
                              </ListItemIcon>
                            }
                            <ListItemText>
                              <JP>{item.jpText}</JP> <RUS>({item.translation})</RUS>
                            </ListItemText>
                          </MenuItem>
                        );
                      })
                    }
                    <Divider />
                    <MenuItem>
                      <ListItemIcon>
                        <Login fontSize='small' style={{ color: colors.blue }} />
                      </ListItemIcon>
                      <MenuActions onLogout={handleLogoutClick} onLogin={handleLoginClick} />
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <MenuDialogue
        open={dialogueOpen}
        onClose={() => setDialogueOpen(false)}
      >
        {
          dialogueMode === DIALOGUE_MODES.LOGIN
            ? <LoginScreen onModeChange={handleDialogueModeChange} onSuccess={() => setDialogueOpen(false)} />
            : <RegisterScreen onModeChange={handleDialogueModeChange} />
        }
      </MenuDialogue>
    </Stack>
  );
}