import lesson_32_kaiwa from "../../../assets/mnn/32-kaiwa.mp3";
import lesson_32_mondai_1 from "../../../assets/mnn/32-mondai-1.mp3";
import lesson_32_mondai_2 from "../../../assets/mnn/32-mondai-2.mp3";

import { MnnAudioFileType } from "../mnnAudioFiles";

export const mnnLesson32: MnnAudioFileType[] = [
  {
    fileName: '会話',
    file: lesson_32_kaiwa,
    lessonNumber: 32,
    type: '教科書',
  },
  {
    fileName: '問題　１番',
    file: lesson_32_mondai_1,
    lessonNumber: 32,
    type: '教科書',
  },
  {
    fileName: '問題　２番',
    file: lesson_32_mondai_2,
    lessonNumber: 32,
    type: '教科書',
  },
];