import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const にもかかわらず: OneGrammarType = {
  name: '〜にもかかわらず',
  level: 'N2',
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <RUS>
          Переводится как <b>несмотря на</b>, англ. <b>in spite of, despite, even though</b><br />
          <JP>
            予想とは違った結果に対する以外な気持ちを表す表現。
          </JP>
          <br />
          Used to express the surprised feeling caused by unexpected result.
          <br />
          Глагол, <JP>い</JP> прилагательное, <JP>な</JP> прилагательное
          в простой форме в любом времени<JP>＋にもかかわらず</JP><br />
          Существительное<JP>＋にもかかわらず</JP>
          <br />
        </RUS>
      ),
    },
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <>
          <JP>
            大学生<Accent>にもかかわらず</Accent>、
            基本的な漢字が書けない者もいる。
          </JP><br />
          <RUS>существительное</RUS>
          <JP>
            ＋にもかかわらず
          </JP><br />
          <RUS>
            Несмотря на то, что они дайгакусэи (я ожидал, они-то точно умеют писать кандзи),
            среди них тоже есть те, кто не может написать базовые иероглифы.<br />
            There are some people who can't write basic kanji <b>despite</b> being in university.
          </RUS>
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <>
          <JP>
            友だちは私より成績が良かった<Accent>にもかかわらず</Accent>、
            試験で実力が出せずに、不合格になった。
          </JP><br />
          <RUS>
            <b>Even though</b> my friend had better grades than me, she wasn't able to perform well
            in the actual exam and ended up failing it.
          </RUS>
          <br />
          <RUS>прилагательное в простой форме в прошедшем времени</RUS>
          <JP>
            ＋にもかかわらず
          </JP><br />
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 3,
      content: (
        <>
          <JP>
            ＜式などで＞本日はお忙しい中<Accent>にもかかわらず</Accent>、
            ご出席いただき、誠にありがとうございます。
          </JP><br />
          <RUS>
            (at a ceremony, etc) Thank you very much for coming <b>despite</b> your busy schedule.
          </RUS>
          <br />
          <RUS>прилагательное в простой форме в прошедшем времени</RUS>
          <JP>
            ＋にもかかわらず
          </JP><br />
        </>
      ),
      translation: (
        <>
          <JP>
            誠（まこと）に
          </JP>
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 4,
      content: (
        <>
          <JP>
            雨<Accent>にもかかわらず</Accent>、
            大勢の人が出てくれました。
          </JP><br />
          <RUS>существительное</RUS>
          <JP>
            ＋にもかかわらず
          </JP><br />
          <RUS>
            Несмотря на дождь (я ожидал, все будут сидеть дома),
            пришли много людей.<br />
            Lots of people came <b>in spite of</b> the rain.
          </RUS>
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 5,
      content: (
        <>
          <JP>
            週末<Accent>にもかかわらず</Accent>、
            上司から仕事のメールが来た。
          </JP><br />
          <RUS>существительное</RUS>
          <JP>
            ＋にもかかわらず
          </JP><br />
          <RUS>
            <b>Even though</b> it's the weekend, I got an e-mail from my boss.
          </RUS>
        </>
      ),
    },
  ],
};