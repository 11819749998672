import React, {useState, useMemo, useEffect, useCallback} from 'react';
import update from "immutability-helper";
import { Grid } from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import { AutocompleteInput } from "../autocomplete-input/AutocompleteInput";
import { WordCard } from "./WordCard";
import { colors } from "../../utils/palette";
import { Form } from "./Form";
import _find from "lodash/find";
import _uniq from "lodash/uniq";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useAuthContext } from "../../context/AuthProvider";

type TranslationType = {
  language: string;
  translation: string;
}

export type NewWordType = {
  word: string;
  furigana: string;
  kanji: string[];
  levels: number[];
  examples: string[];
  categories: string[];
  translations: TranslationType[];
}

export const Kotoba: React.FC = () => {
  const axiosPrivate = useAxiosPrivate();
  const { isAdmin } = useAuthContext();
  const [open, setOpen] = useState(false);
  const [words, setWords] = useState<NewWordType[]>([]);
  const [word, setWord] = useState('');
  const [example, setExample] = useState<string>('');
  const [furigana, setFurigana] = useState('');
  const [translations, setTranslations] = useState<TranslationType[]>([]);
  const [levels, setLevels] = useState<number[]>([]);
  const [examples, setExamples] = useState<string[]>([]);
  const [category, setCategory] = useState<string>('');
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = React.useState<NewWordType | null>(null);
  const [items, setItems] = React.useState<NewWordType[]>([]);

  const handleChange = (newValue: NewWordType | null) => {
    setSelectedOption(newValue);
    if (newValue) {
      const found = _find(items, (item) => item.word === newValue.word);
      if (!found) {
        setItems(items => update(items, { $push: [newValue]} ));
      }
    }
  }

  const onWordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setWord(e.target.value);
  };

  const onFuriganaChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFurigana(e.target.value);
  };

  const onLevelChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const levelsStr: string[] = e.target.value.split(',');
    const levelsNum: number[] = [];
    levelsStr.forEach((level) => {
      const v = parseInt(level.trim());
      if (typeof v === 'number' && v >= 0) {
        levelsNum.push(v);
      }
    });
    setLevels(_uniq(levelsNum));
  };

  const onExampleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setExample(e.target.value);
  };

  const onCategoryChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setCategory(e.target.value);
  };

  const removeExamples = (): void => {
    setExamples([]);
  };

  const removeCategories = (): void => {
    setCategories([]);
  };

  const onCategoryClick = useCallback(() => {
    const found = _find(categories, (item) => item === category);
    if (!found) {
      setCategories(categories => {
        return update(categories, {
          $push: [category],
        });
      });
    } else {
      console.error('Cannot insert duplicates.');
    }
  }, [categories, category]);

  const onExampleClick = useCallback(() => {
    const found = _find(examples, (item) => item === example);
    if (!found) {
      setExamples(examples => {
        return update(examples, {
          $push: [example],
        });
      });
    } else {
      console.error('Cannot insert duplicates.');
    }
  }, [examples, example]);

  const onTranslationChange = (value: string, language: string): void => {
    const newTranslation: TranslationType = {
      language: language,
      translation: value,
    };
    setTranslations(translations => {
      const found = _find(translations, translation => translation.language === language);
      if (found) {
        return update(translations, {
          $apply: (items: TranslationType[]) => items.map((item) => {
            if (item.language === language) {
              return newTranslation;
            } else return item;
          })
        });
      } else {
        return update(translations, {
          $push: [newTranslation]
        });
      }

    })
  }

  const newWord = useMemo(() => {
    const newWord: NewWordType = {
      word: word,
      furigana: furigana,
      levels: levels,
      examples: examples,
      categories: categories,
      kanji: [],
      translations: translations,
    };
    return newWord;
  }, [
    word,
    furigana,
    levels,
    categories,
    examples,
    translations,
  ]);

  const getWords = useCallback(async (): Promise<NewWordType[]> => {
    try {
      const controller = new AbortController();
      const response = await axiosPrivate.get('/words', {
        signal: controller.signal,
      });
      return response.data;
    } catch (err: any) {
      console.log('Could not fetch words.');
      console.log(err);
      return Promise.reject(err);
    }
  }, [axiosPrivate]);

  useEffect(() => {
    getWords().then(
      words => {
        setWords(words);
      },
      error => {
        console.error(error);
      },
    );
  }, [getWords]);

  const addWord = useCallback(async (): Promise<void> => {
    try {
      const controller = new AbortController();
      await axiosPrivate.post('/words/add', newWord, {
        signal: controller.signal,
      });
      // Re-fetch words
      getWords().then(
        words => {
          setWords(words);
        },
        error => {
          console.error(error);
        },
      );
      setWord('');
      setFurigana('');
      setLevels([]);
      setCategories([]);
      setExamples([]);
      setTranslations([]);
      setSelectedOption(null);
    } catch (err: any) {
      console.log('Could not create a new lesson');
      console.log(err);
    }
  }, [newWord, axiosPrivate, getWords]);

  const removeWord = useCallback(async (word: string): Promise<void> => {
    try {
      const controller = new AbortController();
      await axiosPrivate.delete('/words/delete', {
        signal: controller.signal,
        data: {
          word,
        },
      });
      // Re-fetch words
      getWords().then(
        words => {
          setWords(words);
          setSelectedOption(null);
          const found = _find(items, item => item.word === word);
          if (found) {
            setItems(items => {
              const tmpItems: NewWordType[] = [];
              items.forEach((item) => {
                if (item.word !== word) {
                  tmpItems.push(item);
                }
              });
              return tmpItems;
            });
          }
        },
        error => {
          console.error(error);
        },
      );
    } catch (err: any) {
      console.log('Could not remove this word: ', word);
      console.log(err);
    }
  }, [items, axiosPrivate, getWords]);

  const removeItem = useCallback((word: string) => {
    setItems(items => {
      const tmpItems: NewWordType[] = [];
      items.forEach((item) => {
        if (item.word !== word) {
          tmpItems.push(item);
        }
      });
      return tmpItems;
    });
  }, []);

  return (
    <>
      <Grid
        container
        columnSpacing={1}
        rowSpacing={2}
      >



        <Grid item xs={12}>
          Добавлено {words.length} слов(а).


        </Grid>
        <Grid item xs={12}>
          <Grid container columnSpacing={1} alignItems='center'>
            <Grid item>
              <AutocompleteInput
                value={selectedOption}
                handleChange={handleChange}
                words={words}
              />
            </Grid>
            {
              isAdmin &&
              <Grid item>
                {!open && <Add fontSize='large' style={{ color: colors.pale.icon }} onClick={() => setOpen(true)} />}
                {open && <Close fontSize='large' style={{ color: colors.pale.icon }} onClick={() => setOpen(false)} />}
              </Grid>
            }
          </Grid>
        </Grid>

        {
          open &&
          <Grid item xs={12}>
            <button onClick={addWord}>Add Word</button>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Form
                  levels={levels}
                  onWordChange={onWordChange}
                  onFuriganaChange={onFuriganaChange}
                  onLevelChange={onLevelChange}
                  onExampleChange={onExampleChange}
                  onCategoryChange={onCategoryChange}
                  onExampleClick={onExampleClick}
                  onCategoryClick={onCategoryClick}
                  removeExamples={removeExamples}
                  removeCategories={removeCategories}
                  onTranslationChange={onTranslationChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <WordCard word={newWord} isPreview={true} isAdmin={isAdmin} />
              </Grid>
            </Grid>


          </Grid>
        }
        {
          items.length > 0 &&
          <Grid item xs={12}>
            <Grid
              container
              flexDirection='row'
              justifyContent='flex-start'
              alignItems='flex-start'
              columnSpacing={1}
              rowSpacing={1}
              style={{
                //backgroundColor: 'dodgerblue',
              }}
            >
              {
                items.slice(0).reverse().map((item, i) => {
                  return (
                    <Grid key={i} item xs={12} sm={12} md={6}>
                      <WordCard word={item} isPreview={false} removeWord={removeWord} removeItem={removeItem} isAdmin={isAdmin} />
                    </Grid>
                  );
                })
              }
            </Grid>
          </Grid>
        }
      </Grid>
    </>
  );
};