import styled, { css } from 'styled-components';
import { colors } from "../../utils/palette";

export const CustomButton = styled.button`
  background-color: ${colors.blue};
  padding: 5px 15px 5px 15px;
  // border-radius: 14px;
  border: none;
  color: white;
  fontFamily: 'Arial';
  font-size: 16px;
  line-height: 1.4;
  
  &:hover {
    cursor: pointer;
  }
`;