import lesson_35_kaiwa from "../../../assets/mnn/35-kaiwa.mp3";
import lesson_35_mondai_1 from "../../../assets/mnn/35-mondai-1.mp3";
import lesson_35_mondai_2 from "../../../assets/mnn/35-mondai-2.mp3";

import { MnnAudioFileType } from "../mnnAudioFiles";

export const mnnLesson35: MnnAudioFileType[] = [
  {
    fileName: '会話',
    file: lesson_35_kaiwa,
    lessonNumber: 35,
    type: '教科書',
  },
  {
    fileName: '問題　１番',
    file: lesson_35_mondai_1,
    lessonNumber: 35,
    type: '教科書',
  },
  {
    fileName: '問題　２番',
    file: lesson_35_mondai_2,
    lessonNumber: 35,
    type: '教科書',
  },
];