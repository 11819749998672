import React from "react";
import { Accent, Accent_2, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const nioite: OneGrammarType = {
  name: '〜において（は）',
  level: 'N3', // n2n3
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <>
          Три формы:<br />
          <JP>〜において（は）</JP><br />
          <JP>〜においても</JP><br />
          <JP>〜における</JP><br />
          <JP><Accent>接続</Accent>［助］＋において</JP><br />
          <JP><Accent>意味</Accent>　〜で〈場所・時間を示す。〉</JP><br />
        </>
      ),
    },
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <>
          <JP>⑴　会議は第一会議室<Accent>において</Accent>行われる。</JP>
        </>
      ),
      translation: (
        <>
          <JP>会議　かいぎ</JP><br />
          <JP>会議室　かいぎしつ</JP><br />
          <JP>第一　だいいち</JP><br />
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <JP>
          ⑵　現代<Accent>においては</Accent>、コンピューターは不可欠なものである。
        </JP>
      ),
      translation: (
        <>
          <JP>現代　げんだい</JP> современность, нынешнее время<br />
          <JP>不可欠（ふかけつ）な</JP> indispensable, жизненно необходимый<br />
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 3,
      content: (
        <JP>
          ⑶　我が国<Accent>においても</Accent>、
          青少年の犯罪が増えている。
        </JP>
      ),
      translation: (
        <>
          <JP>我が　わが</JP> мой, наш<br />
          <JP>青少年　せいしょうねん</JP> молодёжь, молодое поколение<br />
          <JP>犯罪　はんざい</JP><br />
          <JP>増える　ふえる</JP><br />
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 4,
      content: (
        <JP>
          ⑷　それはわたしの人生<Accent>における</Accent>
          最良の日であった。
        </JP>
      ),
      translation: (
        <>
          <JP>人生　じんせい</JP> (человеческая) жизнь<br />
          <JP>最良　さいりょう</JP> самый лучший, наилучший<br />
        </>
      ),
    },
  ]
};