import React, {useCallback, useMemo} from 'react';
import _find from "lodash/find";
import { Card, CardContent, Grid } from "@mui/material";
import { JP, RUS } from "../../style";
import { colors } from "../../utils/palette";
import { NewWordType } from "./Kotoba";
import { WordButton } from "./style";

type Props = {
  word: NewWordType;
  isPreview: boolean;
  removeWord?: (word: string) => Promise<void>;
  removeItem?: (word: string) => void;
  isAdmin: boolean;
}

type StringType = {
  jp: string;
  rus: string;
}

export const WordCard: React.FC<Props> = (props) => {
  const { word, isPreview } = props;

  const getWord = (word: NewWordType): string => {
    if (word.furigana) {
      return `${word.word} (${word.furigana})`;
    } else {
      return `${word.word}`;
    }
  }

  const targetWord = useMemo(() => {
    return getWord(word);
  }, [word]);

  const translationRus = useMemo(() => {
    const found = _find(word.translations, (translation) => translation.language === 'RUS');
    return found ? found.translation : null;
  }, [word]);

  const translationEng = useMemo(() => {
    const found = _find(word.translations, (translation) => translation.language === 'ENG');
    return found ? found.translation : null;
  }, [word]);

  const getStrings = (value: string): StringType => {
    // const match = value.match(/[\u0B80-\u0BFF]+/g);
    const kanji = value.match(/[\u4e00-\u9faf]+/g)?.join('').split('');
    const hiragana = value.match(/[\u3040-\u309f]+/g)?.join('').split('');
    const katakana = value.match(/[\u30a0-\u30ff]+/g)?.join('').split('');
    const punctuation = value.match(/[\u3000-\u303f]+/g)?.join('').split('');
    const braces = value.match(/[\uFF08-\uFF09]+/g)?.join('').split('');
    let rus = '';
    let jp = '';
    value.split('').forEach((char) => {
      if (
        kanji?.includes(char) ||
        hiragana?.includes(char) ||
        katakana?.includes(char) ||
        punctuation?.includes(char) ||
        braces?.includes(char)
      ) {
        jp = jp.concat(char);
      } else {
        rus = rus.concat(char);
      }
    });
    return {
      rus: rus.trim(),
      jp: jp.trim(),
    };
  };

  const handleRemove = useCallback(() => {
    if (props.removeWord) {
      props.removeWord(word.word).catch(console.error);
    }
  }, [word, props]);

  const handleRemoveItem = useCallback(() => {
    if (props.removeItem) {
      props.removeItem(word.word);
    }
  }, [word, props]);

  return (
    <>
      <Card sx={{ minWidth: 275, height: 'auto' }}>
        <CardContent style={{
          padding: 0
        }}>
          <Grid
            container
            columnSpacing={1}
            rowSpacing={1}
          >
            <Grid item xs={12} style={{
              backgroundColor: colors.pale.blue,
              padding: '2vh 4vh 1vh 4vh',
              marginBottom: '0 0 0vh 0',
            }}>
              <Grid container flexDirection='row' justifyContent='space-between' alignItems='center'>
                <Grid item>
                  <JP style={{ fontWeight: "bold", fontFamily: '"NotoSerifJP-Bold", sans-serif' }}>
                    {targetWord} {isPreview && <span style={{color: 'red'}}>New</span>}
                  </JP>
                </Grid>
                {
                  word.levels && word.levels.length > 0 &&
                  <JP >N{word.levels.join(', ').trim()}</JP>
                }
              </Grid>
            </Grid>
            <Grid item xs={12} style={{
              padding: '2vh 4vh 2vh 4vh',
            }}>
              {
                translationRus &&
                <>
                  <RUS>
                    {translationRus}
                  </RUS>
                  <br />
                </>
              }
              {
                translationEng &&
                <>
                  <RUS>
                    {translationEng}
                  </RUS>
                  <br />
                </>

              }
              {
                word.examples?.length > 0 &&
                word.examples.map((example, i) => {
                  const { jp, rus } = getStrings(example);
                  return (
                    <div key={i}>
                      <JP>{jp}</JP><br /><RUS>{rus}</RUS>
                    </div>
                  );
                })
              }
              {
                word.categories?.length > 0 &&
                <Grid container columnSpacing={1}>
                  {
                    word.categories.map((category, i) => {
                      return (
                        <Grid item key={i} style={{ marginTop: '10px', }}>
                          <span style={{ backgroundColor: colors.blue2, color: 'black', padding:' 1vh 2vh 1vh 2vh', borderRadius: '15px' }}>
                        <JP>{category}</JP>
                      </span>
                        </Grid>
                      );
                    })
                  }
                </Grid>
              }
              {
                word.kanji?.length > 0 &&
                <Grid container columnSpacing={1}>
                  {
                    word.kanji.map((item, i) => {
                      return (
                        <Grid item key={i} style={{ marginTop: '10px', }}>
                          <span style={{ backgroundColor: colors.blue2, color: 'black', padding:' 1vh 2vh 1vh 2vh', borderRadius: '15px' }}>
                        <JP>{item}</JP>
                      </span>
                        </Grid>
                      );
                    })
                  }
                </Grid>
              }
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent='flex-end'
                alignItems='center'
                columnSpacing={1}
                rowSpacing={0}
                style={{
                  padding: '1vh 2vh 1vh 2vh'
                }}
              >
                {
                  props.isAdmin && props.removeWord &&
                  <Grid item>
                    <WordButton onClick={handleRemove}>Стереть</WordButton>
                  </Grid>
                }
                {
                  props.removeItem &&
                  <Grid item>
                    <WordButton onClick={handleRemoveItem}>Закрыть</WordButton>
                  </Grid>
                }
              </Grid>
            </Grid>


          </Grid>

        </CardContent>

      </Card>
    </>
  );
};