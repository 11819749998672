import styled, { css } from 'styled-components';
import { DialogTitle, Avatar } from "@mui/material";
import jp from './assets/jp.jpg';
import jpBlue from './assets/jp-blue.jpg';
import jpMountain from './assets/jp-mountain.jpg';
import jpFudji from './assets/jp-blue.jpg';
import tenki from './assets/tenki.jpeg';
import { colors } from "./utils/palette";

export const KanjiButton = styled.button`
  padding: 1vh;
  text-transform: uppercase;
  background-color: blanchedalmond;
  color: #ccbca4;
  margin-bottom: 1vh;
  outline: none;
  border-color: yellowgreen;
  border: none;
  border-radius: 4px;

  background-color: #dbf3da; // forestgreen;
  color: #84ac84;

  //font-family: "FiraSansCondensed-Regular", sans-serif;
  font-family: "KosugiMaru-Regular", sans-serif;
  font-size: 22px;
  transition-property: background-color, color, border;
  transition-duration: 1s;
  border: 1px solid transparent;
  &:hover {
    cursor: pointer;
    background-color: #dbf3da; // forestgreen;
    color: forestgreen; // #84ac84; // #487d48;
    border: 1px solid forestgreen;
    transition-property: background-color, color, border;
    transition-duration: .5s;
  }
`;

export const KanjiImageContainer = styled.div`
  overflow: hidden;
  overflow-x: scroll;

  border: 0px solid lightgrey; // #61dafb;
  
  img {
    width: 200px !important; // 550px;
    max-width: 550px;
    transition: transform 0.3s;
  }

  img:hover {
    // transform: scale(1.5);
  }
`;

type LevelProps = {
  $bgColor: string;
}

export const Level = styled.div<LevelProps>((props) => {
  return css`
  background-color: ${props.$bgColor};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
  width: 30px;
    //border-radius: 8px;
    padding: .2vh;
  font-family: "KosugiMaru-Regular", sans-serif;
  // font-size: 20px;
  line-height: 1.4;
    margin-top: 3px;
`;
})

export const StyledWordsTable = styled.table`
  border: 1px solid black;
  border-collapse: collapse;
  tr > th,td {
    border: 1px solid black;
    padding: 1vh;
    min-width: 200px;
  }
`;

export const StyledDialogTitle = styled.button`
  background-color: ${colors.pale.blue};
  border-color: #282c34;
  border-width: 1px;
  border: 0;
  color: #282c34;
  line-height: 1.4;
  padding: 1vh 3vh 1vh 3vh;
  // text-transform: capitalize;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  font-weight: bold;
  // border-radius: 8px;
  box-shadow: 0;
  outline: none;
  
  &:hover {
    cursor: pointer;
  }
`;

export const GrammarTable = styled.table`
  border-collapse: collapse;
}
  tr > th, td {
    padding: 1vh 3vh 1vh 3vh;
    color: black;
    
    > span {
      font-size: 16px;
    }
    
    // border: 1px solid rgba(97,218,25,0.5);
  }
  
  thead > tr:nth-of-type(1) {
    background: linear-gradient(to right, rgba(97,218,25,0.8), rgba(97,218,25,0.4));
  }
  
  tr > th {
    font-weight: normal;
    // background-color: ${colors.blue2};
    
  }

  tr:nth-of-type(even) > td {
    color: black;
  }
  
  tbody > tr:nth-of-type(odd) {
    //background-color: rgba(97,218,25,0.2);
    background: linear-gradient(to right, rgba(97,218,25,0.4), rgba(97,218,25,0.2));
`;

export const JPHeader = styled.span`
  font-family: "NotoSerifJP-Bold", sans-serif;
  font-size: 22px;
  line-height: 1.4;
  
`;

export const StyledLogo = styled.span`
  font-family: "KosugiMaru-Regular", sans-serif;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledSection = styled.section(() => {
  return css`
    width: 100%;
    max-width: 330px;
    /*min-height: 400px;*/
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1.5rem;
    background-color: ${colors.pale.blue}; // dodgerblue
    color: black;
    
    h1 {
      color: black;
    }
    
    button {
      background-color: dodgerblue;
    }

    a, a:visited {
      color: dodgerblue;
      text-decoration: underline;
      transition-property: color;
      transition-duration: 1s;
    }
    a:hover {
      cursor: pointer;
      color: ${colors.blue};
      transition-property: color;
      transition-duration: .2s;
    }
  `;
});

export const StyledButton = styled.button((props) => {
  const { disabled } = props;
  return css`
    color: red;
    //background-color:blue;
    margin-top: 5px;

    padding: 10px 40px 10px 40px;
    text-transform: uppercase;
    //background-color: ;
    color: white;
    margin-bottom: 1vh;
    outline: none;
    // border: 1px solid blue;
    border: none;
    border-radius: 24px;

    //font-family: "FiraSansCondensed-Regular", sans-serif;
    font-size: 16px;
    transition-property: background-color;
    transition-duration: 1s;
    &:hover {
      cursor: pointer;
      background-color: #61dafb;
      transition-property: background-color;
      transition-duration: .5s;
    }
    
    ${disabled && css`
      color: rgba(30, 144, 255, 0.5);
      background-color: transparent !important;
      border: 1px solid rgba(30, 144, 255, 0.5);

      &:hover {
        cursor: not-allowed;
        background-color: #f3f3f3;
      }
    `}
  `;
});

// JapaneseText
export const JP = styled.span`
  font-family: "NotoSerifJP-Regular", sans-serif;
  font-size: 20px;
  line-height: 1.4;
  
  > h4 {
    font-family: "NotoSerifJP-Bold", sans-serif;
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
`;

export const JPM = styled.span`
  font-family: "NotoSerifJP-Regular", sans-serif;
  font-size: 16px;
  line-height: 1.4;
`;

export const JP_2 = styled.span`
  font-family: "KosugiMaru-Regular", sans-serif;
  font-size: 14px;
  line-height: 1.4;
  
`;

// RussianText
export const RUS = styled.span`
  font-family: "Arial", sans-serif;
  font-size: 16px;
  line-height: 1.4;
`;

export const RUS_2 = styled.span`
  font-family: "FiraSansCondensed-Regular", sans-serif;
  font-size: 16px;
  line-height: 1.4;
`;

export const RUS_3 = styled.span`
  font-family: "CormorantGaramond-Regular", sans-serif;
  font-size: 16px;
  line-height: 1.4;
`;

export const Accent = styled.span`
  // color: black;
  //color: ${colors.apple.pink.dark}; // cadetblue;
  //background-color: lightpink; // #bbfff3;
  background-color: ${colors.pale.blue};
  color: ${colors.blue};
    //color: ${colors.apple.pink.dark};
  //color: #61dafb;
  font-family: "NotoSerifJP-Bold", sans-serif;
`;

export const Accent_2 = styled.span`
  color: black;
  background-color: #dbf3da;
  //color: #61dafb;
  font-family: "NotoSerifJP-Bold", sans-serif;
`;

export const BackgroundImageStyle = styled.div`
  //background-image: url(${jpFudji});
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center;
  border-radius: 0 0 5px 5px;
`;

export const BookCover = styled.div`
  background-image: url(${tenki});
  // background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  background-position: 0px 0px ;
  border-radius: 0 0 5px 5px;
  min-height: 500px;
  min-width: 350px;
  border: 1px solid red;
`;

export const ReadMore = styled.div`
  text-decoration: underline;
  color: ${colors.link};
  transition-property: background-color;
  transition-duration: 0.5s;
  padding:1vh;
  margin-left:-1vh;
  
  margin-top: 1vh;
  margin-bottom: 1vh;
  
  &:hover {
    transition-property: background-color;
    transition-duration: 0.5s;
    cursor: pointer;
    // background-color: rgba(101,160,225,0.2);
  }
`;

type ReadMoreTextType = {
  $isVisible: boolean;
}

export const ReadMoreText = styled.span<ReadMoreTextType>((props) => {
  const { $isVisible } = props;
  return css`
    display: ${$isVisible ? 'inline' : 'none'};
    // transition: opacity 1s ease-out;
    opacity: ${$isVisible ? 1 : 0};
    transition-property: display, opacity;
    transition-duration: 1s;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  `;
})