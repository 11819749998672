import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const sae: OneGrammarType = {
  name: '〜さえ',
  level: 'N3', // n2n3
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <JP>
          N(＋助詞)さえ（も）
          <br /><br />
          「XさえY」の形で、普通Yだとは考えられない極端な例Xをあげて、
          それがYなのだから、他のことはなおさらYだということを表す。
          「…も」で言いかえられる。主格の場合は「でさえ」になることも多い。
        </JP>
      ),
      translation: (
        <>
          <JP>極端（きょくたん）な</JP> extreme, extremity<br />
          <JP>言いかえる　いいかえる</JP> to say in other words<br />
          <JP>→言いかえられる（可能形）</JP> можно сказать другими словами,
          можно заменить другими словами<br />
          <JP>主格　しゅかく</JP> именительный падеж, nominative case,
          имеется в виду, когда слово является подлежащим (вместо частицы <JP>は</JP>)<br />
        </>
      ),
    },
    {
      meaningNumber: 2,
      content: (
        <JP>
          連用形＋さえ＋する<br />
          連用形＋さえ＋しない
          <br /><br />
          「連用形＋さえ＋する」の形で「普通ならしないような極端なことをする」という意味、
          「連用形＋さえ＋しない」の形で「普通なら当然することをしない」という意味を表す。
          <br />
          <RUS>При обычных обстоятельствах я бы такого ни за что не сделала.</RUS>
        </JP>
      ),
    }
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <>
          ⑴　失業して、今は家賃<Accent>さえ</Accent>払えない。
        </>
      ),
      translation: (
        <>
          <JP>失業（しつぎょう）する</JP> потерять работу, to lose one's job, to become unemployed<br />
          <JP>家賃　やちん</JP> арендная плата (плата за аренду квартиры), рента, rent<br />
          <JP>払う　はらう</JP> платить<br />
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <JP>
          ⑵　そんなことは小学生<Accent>でさえ</Accent>知っている。
        </JP>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 3,
      content: (
        <JP>
          ⑶　本人に<Accent>さえ</Accent>わからないものを、
          どうしてあなたが知っているというのですか。
        </JP>
      ),
      translation: (
        <>
          <JP>本人　ほんにん</JP> я сам (лично); лицо, о котором идёт речь,
          the person in question, the person themselves, said person<br />
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 4,
      content: (
        <JP>
          ⑷　早く研究を完成しなければならない。
          今は食事の時間<Accent>さえ</Accent>
          もったいないとおもう。
        </JP>
      ),
      translation: (
        <>
          <JP>研究　けんきゅう</JP><br />
          <JP>〜を完成（かんせい）する（他動詞）</JP> закончить что-то, to complete<br />
          <JP>もったいない</JP> не имеет смысла, не нужно, a waste<br />
        </>
      ),
    },
    {
      meaningNumber: 2,
      exampleNumber: 1,
      content: (
        <JP>
          ⑴　その夫婦は、病気の娘の看病のために仕事をやめ<Accent>さえ</Accent>した。
        </JP>
      ),
      translation: (
        <>
          <JP>夫婦　ふうふ</JP><br />
          <JP>看病　かんびょう</JP> nursing (a patient)<br />
        </>
      ),
    },
    {
      meaningNumber: 2,
      exampleNumber: 2,
      content: (
        <JP>
          ⑵　貧しさから逃れようと彼は何でもした。
          人を殺し<Accent>さえ</Accent>した。
        </JP>
      ),
      translation: (
        <>
          <JP>貧しさ　まずしさ</JP><br />
          <JP>貧しい　まずしい</JP><br />
          <JP>逃れる　のがれる（自動詞）</JP> to escape<br />
        </>
      ),
    },
    {
      meaningNumber: 2,
      exampleNumber: 3,
      content: (
        <JP>
          ⑶　そのテキストは、先輩が貸してくれたが、川田さんは開き<Accent>さえ</Accent>しなかった。
        </JP>
      ),
      translation: (
        <>
          <JP>川田（かわた）さん</JP><br />
          <JP>開く　ひらく</JP> открыть (файл, документ), to open (a file)<br />
        </>
      ),
    }
  ]
};