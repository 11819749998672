import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import Main from "./Main";
import { RoutesWrapper } from "./routes/RoutesWrapper";

function App() {
  return (
    <div className="App">
      <Router>
        <RoutesWrapper />
      </Router>
      {
        /*
        <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
         */
      }
    </div>
  );
}

export default App;
