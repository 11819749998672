const environment = process.env.NODE_ENV || 'development';
//console.log('environment', environment);
//console.log('domain', process.env.REACT_APP_DOMAIN);
//console.log('url12', process.env.REACT_APP_PUBLIC_URL);

const config = {
  development: {
    serverLocation: process.env.REACT_APP_PUBLIC_URL || '',
    suffix: '_DEV',
  },
  test: {
    serverLocation: process.env.REACT_APP_PUBLIC_URL || '',
    suffix: '_TEST',
  },
  production: {
    serverLocation: process.env.REACT_APP_PUBLIC_URL || '',
    suffix: '',
  },
};

export default config[environment];