import React from 'react';
import { RenderGrammar } from "../RenderGrammar";
import { grammarN1 } from "./grammar-n1";
import { grammarN2 } from "./grammar-n2";
import { grammarN3 } from "./grammar-n3";
import { grammarN4 } from "./grammar-n4";
import { grammarN5 } from "./grammar-n5";
import {
  OneGrammarType,
  GrammarType,
} from "../types";


const rawGrammar: OneGrammarType[] = [
  ...grammarN1,
  ...grammarN2,
  ...grammarN3,
  ...grammarN4,
  ...grammarN5,
];

const mapRawGrammar = (rawGrammar: OneGrammarType[]): GrammarType[] => {
  return rawGrammar.map((item) => {
    return {
      name: item.name,
      level: item.level,
      content: <RenderGrammar {...item} />,
    };
  });
};

export const grammarList: GrammarType[] = mapRawGrammar(rawGrammar);