import React from 'react';

export const LessonType2: React.FC = () => {
  return (
    <>
      Языковая практика проходит в группах по 2 ученика. Ученики объединяются
      в пары в зависимости от наиболее подходящего уровня и вместе
      выполняют упражнения, направленные на развитие разговорной речи,
      совместные задания, игры.
    </>
  );
};