import React, { useEffect, useState } from 'react';
import { ListItemText } from "@mui/material";
import { RUS} from "../../style";
import { useAuthContext } from "../../context/AuthProvider";
import useRefreshToken from "../../hooks/useRefreshToken";
import { Loader } from "../loader/Loader";

type Props = {
  onLogout: () => void;
  onLogin: () => void;
}

export const MenuActions: React.FC<Props> = (props) => {
  const { auth, persist } = useAuthContext();
  const refresh = useRefreshToken();
  const [isRefreshing, setIsRefreshing] = useState<boolean>(true);

  useEffect(() => {
    let isMounted = true;

    // const persist: string | null = localStorage.getItem('persist');
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error(err);
      } finally {
        // Prevents from getting an endless loading loop
        isMounted && setIsRefreshing(false);
      }
    };

    if (persist && !auth?.accessToken) {
      verifyRefreshToken().catch(console.error);
    } else {
      // If accessToken exists, refresh is not required
      setIsRefreshing(false);
    }

    return () => {
      isMounted = false;
    };
  }, [persist]);

  if (isRefreshing) {
    return <Loader type='ThreeDots' />;
  } else {
    return (
      <>
        {
          auth?.accessToken
            ? (
              <ListItemText onClick={props.onLogout}>
                <RUS>Выйти из учётной записи</RUS>
              </ListItemText>
            ) : (
              <ListItemText onClick={props.onLogin}>
                <RUS>Войти в личный кабинет</RUS>
              </ListItemText>
            )
        }
      </>
    );
  }
};