import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const をめぐって: OneGrammarType = {
  name: '〜をめぐって',
  level: 'N2',
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <RUS>
          Переводится как <b>по поводу</b>, англ. <b>over the issue of ...</b><br />
          <JP>
            ある物事について、多くの議論や対立があることを表す表現。
          </JP>
          <br />
          Used to express the thing that is very controversial or
          has a confliction.
          <br />
          Существительное (например, <JP>代表</JP>)<JP>＋をめぐって</JP><br />
          Глагол (например, <JP>行くかどうか</JP>)<JP>＋をめぐって</JP>
          <br />
        </RUS>
      ),
      translation: (
        <RUS>
          <JP>物事（ものごと）</JP> things, everything<br/>
          <JP>議論（ぎろん）</JP> argument, споры<br/>
          <JP>対立（たいりつ）</JP> confrontation, противостояние<br/>
          <JP>代表（だいひょう）</JP> representative, delegate<br/>
        </RUS>
      ),
    },
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <>
          <JP>
            ①　国境<Accent>をめぐって</Accent>、
            二国間で長年争いが続いた。
          </JP><br />
          <RUS>
            The two countries continued to wage a long conflict <b>over the issue of</b> national borders.
          </RUS>
          <br />
          <RUS>существительное</RUS>
          <JP>
            ＋をめぐって
          </JP><br />
        </>
      ),
      translation: (
        <RUS>
          <JP>国境（こっきょう）</JP> national border<br/>
          <JP>二国間（にこくかん）</JP> bilateral<br/>
          <JP>長年（ながねん）</JP> <br/>
          <JP>争い（あらそい）</JP> <br/>
        </RUS>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <>
          <JP>
            ②　＜ニュース＞国会では、予算<Accent>をめぐって</Accent>、
            A党とB党が激しく対立しています。
          </JP><br />
          <RUS>
            (news) Party A and Party B are waging an intense battle in parliament over the national budget.
          </RUS>
          <br />
          <RUS>существительное</RUS>
          <JP>
            ＋をめぐって
          </JP><br />
        </>
      ),
      translation: (
        <RUS>
          <JP>国会（こっかい）</JP> парламент, национальное собрание<br/>
          <JP>予算（よさん）</JP> estimate (of costs), budget<br/>
          <JP>党（とう）</JP> party (political), политическая партия<br/>
          <JP>激しい（はげしい）</JP> сильно (в плохом смысле)<br/>
        </RUS>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 3,
      content: (
        <>
          <JP>
            ③　オリンピックの代表<Accent>をめぐって</Accent>、
            最後のレースが行われます。
          </JP><br />
          <RUS>
            The final selection race will be held over the Olympic representatives.
          </RUS>
          <br />
          <RUS>существительное</RUS>
          <JP>
            ＋をめぐって
          </JP><br />
        </>
      ),
      translation: (
        <RUS>
          <JP>代表（だいひょう）</JP> representative<br/>
          <JP>最後（さいご）</JP> <br/>
          <JP>行う（おこなう）</JP> <br/>
        </RUS>
      ),
    },
  ],
};