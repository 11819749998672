import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const youToOmou: OneGrammarType = {
  name: '〜ようとおもう',
  level: 'N4',
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <JP>
          意志的な行為を表す動詞を受けて、話しての予定や意志を表すのに用いる。
          <RUS>
            Эту грамматику используют с глаголами, которые выражают желаемое действие,
            то есть то действие, которое хочет или намерен совершить говорящий.
            Таким образом, говорящий выражает своё желание/намерение
            что-либо сделать, озвучивает свои планы.
          </RUS>
          疑問文は聞き手の意志を問う表現となる。
          <RUS>
            В вопросительных предложениях через эту грамматику
            можно спросить собеседника об её/его желаниях/планах/намерениях.
          </RUS>
          また、⑷の「かと思う」は、話し手に迷いやためらいがあることを表す。
          ⑸の「V-ようと（は）思わない」はそのような意志が話し手にないことを表す。
        </JP>
      ),
      translation: (
        <JP>
          意志（いし）<RUS>желание, намерение</RUS><br />
          意志的（いしてき）な<br />
          行為（こうい）<br />
          表す（あらわす）<br />
          用いる（もちいる）<RUS>to use, использовать,</RUS> 他動詞<br />
          動詞（どうし）<br />
          受ける（うける）<br />
          疑問（ぎもん）
        </JP>
      ),
    },
    {
      meaningNumber: 2,
      content: (
        <RUS>
          <JP>また、「かと思う」は、話し手に迷いやためらいがあることを表す。</JP>
          <br />
          Если перед <JP>と思う</JP> стоит частица <JP>か</JP>,
          это значит, что говорящий планирует что-то сделать,
          но ещё не твёрд в своём решении,
          не уверен, ещё сомневается/думает.
          Это как бы вопрос самому себе
          "Делать мне что-нибудь или не делать? Стоит ли делать что-то?".
        </RUS>
      ),
    },
  ],
  examples: [
    {
      meaningNumber: 2,
      exampleNumber: 4,
      content: (
        <JP>
          ⑷　今の仕事を辞めようかと思っている。
        </JP>
      ),
      translation: (
        <RUS>
          Я думаю о том, чтобы уйти с работы, но пока не уверен/ещё думаю.
          <br />
          <JP>仕事を辞める（やめる）</JP> бросить, уйти с работы
        </RUS>
      ),
    }
  ],
};