import React from 'react';
import { SharedBox } from "../SharedBox";

type Props = {
  lineHeight: number;
  lessonTitleJp?: string;
  lessonTitleRus?: string;
  lessonDescription: React.ReactNode;
  lessonTime?: string;
  backgroundColor: string;
  padding: string;
};

export const Card: React.FC<Props> = (props) => {
  const {
    lineHeight,
    lessonTitleJp,
    lessonTitleRus,
    lessonDescription,
    lessonTime,
    backgroundColor,
    padding,
  } = props;
  return (
    <SharedBox
      padding={padding}
      backgroundColor={backgroundColor}
    >
      {
        lessonTitleJp &&
        <div style={{
          fontFamily: 'KosugiMaru-Regular',
          textTransform: 'uppercase',
          lineHeight: lineHeight,
          fontSize: '18px',
        }}>
          {lessonTitleJp}
        </div>
      }
      {
        lessonTitleRus &&
        <div style={{
          paddingTop: '1vh',
          fontFamily: 'Arial',
          fontWeight: 600,
          //textTransform: 'uppercase',
          //fontStyle: 'oblique',
          lineHeight: lineHeight,
          fontSize: '16px',
        }}>
          {lessonTitleRus}
        </div>
      }
      <div style={{
        paddingTop: '1vh',
        fontFamily: 'Arial',
        fontSize: '16px',
        lineHeight: lineHeight,
      }}>
        {lessonDescription}
      </div>
      {
        lessonTime &&
        <div style={{
          fontFamily: 'Arial',
          //textTransform: 'uppercase',
          lineHeight: lineHeight,
          fontSize: '16px',
          fontWeight: 600,
          paddingTop: '1vh',
        }}>
          {lessonTime}
        </div>
      }
    </SharedBox>
  );
};