import React, { useRef, useMemo, useEffect, useState } from "react";

const SCROLL_SENSITIVITY = 0.0005;
const MAX_ZOOM = 5;
const MIN_ZOOM = 0.1;

type Props = {
  image: string;
}

// There are two types of ref : mutable or inmutable
// React.RefObject
// React.MutableRefObject
type CanvasRefType = React.RefObject<HTMLCanvasElement>; // HTMLImageElement
type ContainerRefType = React.RefObject<HTMLDivElement>;
type ObserverRefType = React.MutableRefObject<ResizeObserver> | null;

const ZoomImage: React.FC<Props> = ({ image }) => {
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [draggind, setDragging] = useState(false);

  const touch = useRef({ x: 0, y: 0 });
  const canvasRef: CanvasRefType = React.createRef(); // useRef(null);
  const containerRef: ContainerRefType = React.createRef(); // useRef(null);
  const observer = useRef<ResizeObserver | null>(null);
  const background = useMemo(() => new Image(), [image]);

  const clamp = (num: number, min: number, max: number): number => Math.min(Math.max(num, min), max);

  const handleWheel = (event: React.WheelEvent<HTMLCanvasElement>): void => {
    const { deltaY } = event;
    if (!draggind) {
      setZoom((zoom) =>
        clamp(zoom + deltaY * SCROLL_SENSITIVITY * -1, MIN_ZOOM, MAX_ZOOM)
      );
    }
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (draggind) {
      const { x, y } = touch.current;
      const { clientX, clientY } = event;
      setOffset({
        x: offset.x + (x - clientX),
        y: offset.y + (y - clientY),
      });
      touch.current = { x: clientX, y: clientY };
    }
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const { clientX, clientY } = event;
    touch.current = { x: clientX, y: clientY };
    setDragging(true);
  };

  const handleMouseUp = () => setDragging(false);

  const draw = () => {
    if (canvasRef.current) {
      const { width, height } = canvasRef.current;
      const context = canvasRef.current.getContext("2d");

      // Set canvas dimensions
      canvasRef.current.width = width;
      canvasRef.current.height = height;

      if (context) {
        // Clear canvas and scale it
        context.translate(-offset.x, -offset.y);
        context.scale(zoom, zoom);
        context.clearRect(0, 0, width, height);

        // Make sure we're zooming to the center
        const x = (context.canvas.width / zoom - background.width) / 2;
        const y = (context.canvas.height / zoom - background.height) / 2;

        // Draw image
        context.drawImage(background, x, y);
      }
    }
  };

  useEffect(() => {
    observer.current = new ResizeObserver((entries) => {
      entries.forEach(({ target }) => {
        const { width, height } = background;

        /*
        // If width of the container is smaller than image, scale image down
        if (target.clientWidth < width) {
          // Calculate scale
          const scale = target.clientWidth / width;

          // Redraw image
          if (canvasRef && canvasRef.current) {
            canvasRef.current.width = width * scale;
            canvasRef.current.height = height * scale;
            const context = canvasRef.current.getContext("2d");
            if (context) {
              context.drawImage(background, 0, 0, width * scale, height * scale);
            }
          }
        }

         */
      });
    });
    if (containerRef && containerRef.current) {
      observer.current.observe(containerRef.current);
    }
    return () => {
      if (containerRef && containerRef.current) {
        observer.current?.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    background.src = image;

    if (canvasRef.current) {
      background.onload = () => {
        // Get the image dimensions
        const { width, height } = background;

        if (canvasRef && canvasRef.current) {
          canvasRef.current.width = width;
          canvasRef.current.height = height;

          // Set image as background
          const context = canvasRef.current.getContext("2d");
          if (context) {
            context.drawImage(background, 0, 0);
          }
        }
      };
    }
  }, [background]);

  useEffect(() => {
    draw();
  }, [zoom, offset]);

  return (
    <div ref={containerRef}>
      <canvas
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onWheel={handleWheel}
        onMouseMove={handleMouseMove}
        ref={canvasRef}
        style={{ maxWidth: '550px', width: '100%' }}
      />
    </div>
  );
};

export default ZoomImage;