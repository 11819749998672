import React from "react";
import { Accent, Accent_2, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const toshite: OneGrammarType = {
  name: '〜として（は／も／の）',
  level: 'N3', // n2n3
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <>
          <JP><Accent>接続</Accent>［助］＋として</JP><br />
          После <JP>として</JP> могут быть частицы <JP>は、も、の</JP>
          <br />
          <JP><Accent>意味</Accent>　立場・資格・種類をはっきり言う。</JP><br />
        </>
      ),
      translation: (
        <>
          <JP>立場　たちば</JP><br />
          <JP>資格　しかく</JP> <RUS>квалификация</RUS><br />
          <JP>種類　しゅるい</JP><br />
        </>
      ),
    },
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <>
          <JP>⑴　彼は国費留学生<Accent>として</Accent>日本へ来た。</JP>
        </>
      ),
      translation: (
        <>
          <JP>国費留学生　こくひりゅうがくせい</JP> government-financed or sponsored foreign student<br />
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <JP>
          ⑵　この病気は難病<Accent>として</Accent>認定された。
        </JP>
      ),
      translation: (
        <>
          <JP>認定（にんてい）する</JP><br />
          <JP>難病　なんびょう</JP><br />
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 3,
      content: (
        <JP>
          ⑶　あの人は学手<Accent>としては</Accent>立派だが、
          人間<Accent>としては</Accent>尊敬できない。
        </JP>
      ),
      translation: (
        <>
          <JP>立派（りっぱ）な</JP><br />
          <JP>尊敬（そんけい）する</JP><br />
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 4,
      content: (
        <JP>
          ⑷　彼は医者であるが、小説家<Accent>としても</Accent>
          有名である。
        </JP>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <JP>
          ⑸　日本はアジアの一員<Accent>としての</Accent>
          役割を果たさなければならない。
        </JP>
      ),
      translation: (
        <>
          <JP>役割（やくわり）を果たす（はたす）</JP>
          to play a role, to serve a function, to play a part
          <br />
        </>
      ),
    },
  ]
};