import React, { useState, useMemo } from 'react';
// import { FlashcardType } from "../data/style";
import { FlashcardType } from "./context/FlashcardContext";
import { FlashcardWrapper } from "./FlashcardWrapper";
import { Box, Grid } from "@mui/material";

interface FlashcardsInterface {
    flashcards: FlashcardType[];
    listIndex?: number;
    updateData?: (id: number, isOpen: boolean, listIndex: number) => void;
}

export const Flashcards: React.FC<FlashcardsInterface> = ({ listIndex, updateData, flashcards }) => {
    // const [number, setNumber] = useState(flascards.length);

    return (
        <Box >
            <Grid container columnSpacing={1} rowSpacing={1} flexDirection='row' justifyContent={'stretch'} flexWrap={'wrap'}
                  style={{border: '0px solid blue'}}
            >
                {flashcards.map((flashcard, i) => {
                    return (
                      <Grid item key={i} style={{border:'0px solid red'}} flexGrow={1}>

                        {
                          //
                        }
                        <FlashcardWrapper key={i} flashcard={flashcard} listIndex={listIndex} updateData={updateData} />
                      </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};