import axios from "axios";
// console.log('process.env.ENV', process.env.ENV);
const BASE_URL_LOCAL = `http://localhost:3001`; // Port 3001
const BASE_URL_PRODUCTION = `https://oozora-app-laflt.ondigitalocean.app`;
const BASE_URL = BASE_URL_PRODUCTION; // TODO

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});