import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const oSuru: OneGrammarType = {
  name: 'お〜する',
  level: 'N4',
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <JP>
          動詞の運用形や行為を表す漢語名詞を用いる。{''}
          謙譲表現で「自分が相手のためにある行為をする」という意味。{''}
          ⑷⑸のように漢語名詞とともに使う場合は「ごNする」の形が多いが、{''}
          「電話する」の場合は「お電話する」を用いる。{''}
          <br /><br />
        </JP>
      ),
      translation: (
        <>
          <JP>運用形（うんようけい）</JP><br />
          <JP>漢語（かんご）</JP> Japanese word of Chinese origin<br />
        </>
      ),
    }
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <JP>
          ⑴　先生、<Accent>お</Accent>荷物を<Accent>お</Accent>持ち<Accent>します</Accent>。
        </JP>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <JP>
          ⑵　<Accent>お</Accent>宅まで車で<Accent>お</Accent>送り<Accent>します</Accent>。
        </JP>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 3,
      content: (
        <JP>
          ⑶　<Accent>ご</Accent>注文の品を<Accent>お</Accent>届け<Accent>しました</Accent>。
        </JP>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 4,
      content: (
        <JP>
          ⑷　<Accent>お</Accent>部屋へ<Accent>ご</Accent>案内<Accent>しましょう</Accent>。
        </JP>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 5,
      content: (
        <JP>
          ⑸　あとでこちらから<Accent>ご</Accent>連絡<Accent>します</Accent>。
        </JP>
      ),
    }
  ],
};