import React from "react";
import { Accent, Accent_2, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const にかわって: OneGrammarType = {
  name: '〜にかわって',
  level: 'N3', // n2n3
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <>
          Две формы:<br />
          <JP>〜にかわって</JP><br />
          <JP>〜にかわり</JP><br />
          <JP><Accent>接続</Accent>［名］＋にかわって</JP><br />
          <JP><Accent>意味</Accent>　今までの〜ではなく</JP><br />
          <JP>〈今までに使われていたものが別のものにかわることを表す。〉</JP><br />
        </>
      ),
    },
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <>
          <JP>⑴　ここでは、人間<Accent>にかわって</Accent>
            ロボットが作業をしている。</JP>
        </>
      ),
      translation: (
        <>
          <JP>作業　さぎょう</JP><br />
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <JP>
          ⑵　今はタイプライター<Accent>にかわり</Accent>、
          ワープロが使われている。
        </JP>
      ),
    },
  ]
};