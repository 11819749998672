import React, { useRef, useState, useEffect } from 'react';
import { Grid } from "@mui/material";
import { Close, Check, Info } from "@mui/icons-material";
import { colors } from "../../utils/palette";
import axios from "../../api/axios";
import { DIALOGUE_MODES, DialogueModeType } from "../menu/Menu";
import { StyledButton, RUS, StyledSection } from "../../style";

type Props = {
  onModeChange: (mode: DialogueModeType) => void;
}

// Validate username
const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
// Validate password
// Requires at least one lower case letter,
// one upper case latter, one digit and one special character
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = '/register';

export const Register: React.FC<Props> = (props) => {
  const userRef: React.MutableRefObject<any> = useRef();
  const errRef: React.MutableRefObject<any> = useRef();

  const [user, setUser] = useState('');
  // Whether username validates or not
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    // Set the focus on username input
    userRef.current.focus();
  }, []);

  // Username
  useEffect(() => {
    const result = USER_REGEX.test(user);
    //console.log(result);
    //console.log(user);
    setValidName(result);
  }, [user]);

  // Password
  useEffect(() => {
    const result = PWD_REGEX.test(pwd);
    setValidPwd(result);
    const match = pwd === matchPwd;
    setValidMatch(match);
  }, [pwd, matchPwd]);

  // Error msg
  useEffect(() => {
    // Reset err msg because the user has already read it
    setErrMsg('');
  }, [user, pwd, matchPwd]);

  const handleSubmit = async (e: any): Promise<void> => {
    e.preventDefault();
    // If button enabled with JS hack
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);
    if (!v1 || !v2) {
      setErrMsg('Invalid Entry');
      return;
    }
    // TODO axios
    try {
      const response: any = await axios.post(
        REGISTER_URL,
        JSON.stringify({ user, pwd }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      //console.log(response.data);
      //console.log(response.accessToken);
      setSuccess(true);
      // Clear input fields
    } catch (err: any) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 409) {
        setErrMsg('Это имя пользователя занято.');
      } else {
        setErrMsg('Registration Failed');
      }
      errRef.current.focus();
    }
  }

  return (
    <StyledSection>
      {
        success ? (
          <StyledSection>
            <h1>Success!</h1>
            <p>
              <a href='a'>Sign In</a>
            </p>
          </StyledSection>
        ) : (
          <StyledSection>
            <Grid container justifyContent='row' rowSpacing={2}>
              <Grid item xs={12}>
                <p
                  ref={errRef}
                  className={errMsg ? 'errmsg' : 'offscreen'}
                  aria-live='assertive'
                >
                  {errMsg}
                </p>
              </Grid>
              <Grid item xs={12}>
                <h1>Создать аккаунт</h1>
              </Grid>
              <Grid item xs={12}>
                <form onSubmit={handleSubmit}>
                  <Grid container justifyContent='row' rowSpacing={1}>
                    <Grid item xs={12}>
                      <label htmlFor='username'>
                        Имя пользователя (логин):
                        <span className={validName ? 'valid' : 'hide'}>
                      <Check
                        fontSize='medium'
                        style={{
                          color: colors.blue,
                          marginBottom: '-5px',
                        }}
                      />
                    </span>
                        <span className={validName || !user ? 'hide' : 'invalid'}>
                      <Close
                        fontSize='medium'
                        style={{
                          color: colors.blue,
                          marginBottom: '-5px',
                        }}
                      />
                    </span>
                      </label>
                    </Grid>
                    <Grid item xs={12}>
                      <input
                        type='text'
                        id='username'
                        ref={userRef}
                        autoComplete='off'
                        onChange={(e) => setUser(e.target.value)}
                        required
                        aria-invalid={validName ? 'false' : 'true'}
                        aria-describedby='uidnote'
                        onFocus={() => setUserFocus(true)}
                        onBlur={() => setUserFocus(false)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <p
                        id='uidnote'
                        className={
                          userFocus &&
                          user &&
                          !validName ? 'instructions' : 'offscreen'}
                      >
                        <Info
                          fontSize='medium'
                          style={{
                            color: colors.blue,
                            marginBottom: '-5px',
                          }}
                        />
                        От 4 до 24 символов.<br />
                        Должно начинаться с буквы.<br />
                        Буквы, цифры, нижнее подчёркивание, дефис.
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <label htmlFor='password'>
                        Пароль:
                        <span className={validPwd ? 'valid' : 'hide'}>
                      <Check
                        fontSize='medium'
                        style={{
                          color: colors.blue,
                          marginBottom: '-5px',
                        }}
                      />
                    </span>
                        <span className={validPwd || !pwd ? 'hide' : 'invalid'}>
                      <Close
                        fontSize='medium'
                        style={{
                          color: colors.blue,
                          marginBottom: '-5px',
                        }}
                      />
                    </span>
                      </label>
                    </Grid>
                    <Grid item xs={12}>
                      <input
                        type='password'
                        id='password'
                        onChange={(e) => setPwd(e.target.value)}
                        required
                        aria-invalid={validPwd ? 'false' : 'true'}
                        aria-describedby='pwdnote'
                        onFocus={() => setPwdFocus(true)}
                        onBlur={() => setPwdFocus(false)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <p
                        id='pwdnote'
                        className={pwdFocus && pwd && !validPwd ? 'instructions' : 'offscreen'}
                      >
                        <Info
                          fontSize='medium'
                          style={{
                            color: colors.blue,
                            marginBottom: '-5px',
                          }}
                        />
                        От 8 до 24 символов.<br />
                        Должна быть хотя бы одна заглавная и строчная буква, цифра и специальный символ:{' '}
                        <span aria-label='exclamation mark'>!</span>
                        <span aria-label='at symbol'>@</span>
                        <span aria-label='hashtag'>#</span>
                        <span aria-label='dollar sign'>$</span>
                        <span aria-label='percent'>%</span>
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <label htmlFor='confirm_pwd'>
                        Подтвердить пароль:
                        <span className={validMatch && matchPwd ? 'valid' : 'hide'}>
                      <Check
                        fontSize='medium'
                        style={{
                          color: colors.blue,
                          marginBottom: '-5px',
                        }}
                      />
                    </span>
                        <span className={validMatch || !matchPwd ? 'hide' : 'invalid'}>
                      <Close
                        fontSize='medium'
                        style={{
                          color: colors.blue,
                          marginBottom: '-5px',
                        }}
                      />
                    </span>
                      </label>
                    </Grid>
                    <Grid item xs={12}>
                      <input
                        type='password'
                        id='confirm_pwd'
                        onChange={(e) => setMatchPwd(e.target.value)}
                        required
                        aria-invalid={validMatch ? 'false' : 'true'}
                        aria-describedby='confirmnote'
                        onFocus={() => setMatchFocus(true)}
                        onBlur={() => setMatchFocus(false)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <p
                        id='confirmnote'
                        className={matchFocus && !validMatch ? 'instructions' : 'offscreen'}
                      >
                        <Info
                          fontSize='medium'
                          style={{
                            color: colors.blue,
                            marginBottom: '-5px',
                          }}
                        />
                        Пароли должны совпадать.
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledButton disabled={!validName || !validPwd || !validMatch ? true : false}>
                        <RUS>Создать аккаунт</RUS>
                      </StyledButton>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 0 }}>
                <p>
                  Вы уже зарегистрированы?<br />
                  <span>
                    {
                      // Put react-router link here
                    }
                    <a onClick={() => props.onModeChange(DIALOGUE_MODES.LOGIN)}>Войти в аккаунт</a>
                  </span>
                </p>
              </Grid>
            </Grid>
          </StyledSection>
        )
      }
    </StyledSection>
  );
};