import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const igai: OneGrammarType = {
  name: '以外（いがい）',
  level: 'N4',
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <JP>
          「…をのぞいて・そのほかに」という意味を表す。{''}
          <br /><br />
          接続：
          <br />「N＋以外」
          <br />「Vる／Vた＋以外」
        </JP>
      ),
      translation: (
        <RUS>
          <JP>除いて （のぞいて）</JP>except, за исключением, кроме<br />
          <JP>そのほかに</JP> помимо этого<br />
        </RUS>
      ),
    },
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <JP>
          ⑴　来週のパーティーには、山田さん<Accent>以外</Accent>みんな行くそうです。
        </JP>
      ),
      translation: (
        <RUS>
          На вечеринку на следующей неделе вроде бы придут все, кроме Ямада-сана.
          (Ямада-сан – исключение).
        </RUS>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <JP>
          ⑵　これ<Accent>以外</Accent>に、もっといい辞書はありませんか。
        </JP>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 3,
      content: (
        <JP>
          ⑶　温泉に行ってのんびりする<Accent>以外</Accent>にも、{''}
          何かいい案があったら出してください。
        </JP>
      ),
    }
  ],
};