// import {MnnListType} from "./style";
import {FlashcardType, MnnListType} from "./FlashcardContext";

const testFlashcard_1: FlashcardType = {
  id: 1,
  lesson: 12,
  name: 'Test Name A',
  front: 'すいか',
  backJp: ['すいか'],
  backRus: ['answer1'],
  size: 1,
  isOpen: false,
  // sentenceNumber?: number;
  jlptLevel: 4,
};

const testFlashcard_2: FlashcardType = {
  id: 2,
  lesson: 12,
  name: 'Test Name B',
  front: 'りんご',
  backJp: ['りんご'],
  backRus: ['answer1'],
  size: 1,
  isOpen: false,
  // sentenceNumber?: number;
  jlptLevel: 4,
};

export const KANJI_BOOK: MnnListType[] = [
    {
        id: 0,
        level: 1,
        lesson: 1,
        name: '介',
        label: '第１課　介',
        flashcards: [testFlashcard_1,testFlashcard_2],
    },
    {
        id: 1,
        level: 1,
        lesson: 2,
        name: '介',
        label: 'Social security and community services, Dialogue 1',
        flashcards: [],
    },
    {
        id: 2,
        level: 1,
        lesson: 5,
        name: '航',
        label: '第５課　航',
        flashcards: [],
    },
    {
        id: 3,
        level: 1,
        lesson: 5,
        name: '携',
        label: '第５課　携',
        flashcards: [],
    },
    {
        id: 4,
        level: 1,
        lesson: 5,
        name: '狂',
        label: '第５課　狂',
        flashcards: [],
    },
    {
        id: 5,
        level: 1,
        lesson: 5,
        name: '陸',
        label: '第５課　陸',
        flashcards: [],
    },
    {
        id: 6,
        level: 1,
        lesson: 5,
        name: '御',
        label: '第５課　御',
        flashcards: [],
    },
    {
        id: 7,
        level: 1,
        lesson: 5,
        name: '磁',
        label: '第５課　磁',
        flashcards: [],
    },
    {
        id: 8,
        level: 1,
        lesson: 5,
        name: '波',
        label: '第５課　波',
        flashcards: [],
    },
    {
        id: 9,
        level: 1,
        lesson: 5,
        name: '況',
        label: '第５課　況',
        flashcards: [],
    },
    {
        id: 10,
        level: 1,
        lesson: 5,
        name: '疑',
        label: '第５課　疑',
        flashcards: [],
    },
    {
        id: 11,
        level: 1,
        lesson: 5,
        name: '維',
        label: '第５課　維',
        flashcards: [],
    },
    {
        id: 12,
        level: 1,
        lesson: 5,
        name: '搭',
        label: '第５課　搭',
        info: 'трава, соединённая вместе = лучистая фасоль + рука',
        flashcards: [],
    },
    {
        id: 13,
        level: 1,
        lesson: 5,
        name: '卓',
        label: '第５課　卓',
        info: 'ранний 早い + гадание, стол для гадания',
        flashcards: [],
    },
    {
        id: 14,
        level: 1,
        lesson: 5,
        name: '蔵',
        label: '第５課　蔵',
        info: 'вассал + густо расти',
        flashcards: [],
    },
    {
        id: 15,
        level: 1,
        lesson: 5,
        name: '姿',
        label: '第５課　姿',
        info: 'следующий + женщина',
        flashcards: [],
    },
    {
        id: 16,
        level: 1,
        lesson: 5,
        name: '勢',
        label: '第５課　勢',
        info: 'сила + круглый + земля',
        flashcards: [],
    },
    {
        id: 17,
        level: 1,
        lesson: 5,
        name: '補',
        label: '第５課　補',
        info: 'одежда + начало',
        flashcards: [],
    },
    {
        id: 18,
        level: 1,
        lesson: 5,
        name: '興',
        label: '第５課　興',
        info: 'развлечение',
        flashcards: [],
    },
    {
        id: 19,
        level: 1,
        lesson: 1,
        name: 'case-study-1',
        label: 'Case Study 1',
        flashcards: [],
    },
  {
    id: 20,
    level: 1,
    lesson: 1,
    name: '栄',
    label: '第１２課　栄',
    flashcards: [],
    readings: [
      { name: '訓読み', list: ['さか、は、ば、え'] },
      { name: '音読み', list: ['エイ、ヨウ'] },
      { name: '名乗り', list: ['さかえ、しげ、てる、なが、ひで、よし'] },
    ],
  },
  {
    id: 21,
    level: 1,
    lesson: 12,
    name: '秒',
    label: '第１２課　秒',
    flashcards: [],
    readings: [
      { name: '音読み', list: ['ビョウ'] },
    ],
  },
  {
    id: 22,
    level: 1,
    lesson: 12,
    name: '糖',
    label: '第１２課　糖',
    flashcards: [],
    readings: [
      { name: '音読み', list: ['トウ'] },
    ],
  },
  {
    id: 23,
    level: 1,
    lesson: 12,
    name: '炭',
    label: '第１２課　炭',
    info: 'charcoal, coal, carbon',
    flashcards: [],
    readings: [
      { name: '訓読み', list: ['すみ'] },
      { name: '音読み', list: ['タン'] },
    ],
  },
  {
    id: 24,
    level: 1,
    lesson: 12,
    name: '脂',
    label: '第１２課　脂',
    info: 'fat, lard, resin',
    flashcards: [],
    readings: [
      { name: '訓読み', list: ['あぶら'] },
      { name: '音読み', list: ['シ'] },
    ],
  },
  {
    id: 25,
    level: 1,
    lesson: 12,
    name: '肪',
    label: '第１２課　肪',
    info: 'fat',
    flashcards: [],
    readings: [
      { name: '音読み', list: ['ボウ'] },
    ],
  },
  {
    id: 26,
    level: 1,
    lesson: 12,
    name: '穀',
    label: '第１２課　穀',
    info: 'grain, cereals',
    flashcards: [],
    readings: [
      { name: '音読み', list: ['コク'] },
      { name: '名乗り', list: ['たけ'] },
    ],
  },
  {
    id: 27,
    level: 1,
    lesson: 12,
    name: '植',
    label: '第１２課　植',
    info: 'plants, plant',
    flashcards: [],
    readings: [
      { name: '訓読み', list: ['う'] },
      { name: '音読み', list: ['ショク'] },
      { name: '名乗り', list: ['うえ、え、げ'] },
    ],
  },
  {
    id: 28,
    level: 1,
    lesson: 12,
    name: '含',
    label: '第１２課　含',
    info: 'include, understand',
    flashcards: [],
    readings: [
      { name: '訓読み', list: ['ふく'] },
      { name: '音読み', list: ['ガン'] },
    ],
  },
  {
    id: 29,
    level: 1,
    lesson: 12,
    name: '吸',
    label: '第１２課　吸',
    info: 'suck, sip, inhale',
    flashcards: [],
    readings: [
      { name: '訓読み', list: ['す'] },
      { name: '音読み', list: ['キュウ'] },
    ],
  },
  {
    id: 30,
    level: 1,
    lesson: 12,
    name: '酸',
    label: '第１２課　酸',
    info: 'acid, sour',
    flashcards: [],
    readings: [
      { name: '訓読み', list: ['す'] },
      { name: '音読み', list: ['サン'] },
    ],
  },
  {
    id: 31,
    level: 1,
    lesson: 12,
    name: '菌',
    label: '第１２課　菌',
    info: 'germs, bacteria',
    flashcards: [],
    readings: [
      { name: '音読み', list: ['キン'] },
    ],
  },
  {
    id: 32,
    level: 1,
    lesson: 12,
    name: '胞',
    label: '第１２課　胞',
    info: 'sac, sheath',
    flashcards: [],
    readings: [
      { name: '音読み', list: ['ホウ'] },
    ],
  },
  {
    id: 33,
    level: 1,
    lesson: 12,
    name: '塩',
    label: '第１２課　塩',
    info: 'salt, chloride',
    flashcards: [],
    readings: [
      { name: '訓読み', list: ['しお'] },
      { name: '音読み', list: ['エン'] },
    ],
  },
  {
    id: 34,
    level: 1,
    lesson: 12,
    name: '窒',
    label: '第１２課　窒',
    info: 'plug up, nitrogen',
    flashcards: [],
    readings: [
      { name: '音読み', list: ['チツ'] },
    ],
  },
  {
    id: 35,
    level: 1,
    lesson: 12,
    name: '硫',
    label: '第１２課　硫',
    info: 'sulfur',
    flashcards: [],
    readings: [
      { name: '音読み', list: ['リュウ'] },
    ],
  },
  {
    id: 36,
    level: 1,
    lesson: 12,
    name: '剤',
    label: '第１２課　剤',
    info: 'medicine, drug',
    flashcards: [],
    readings: [
      { name: '訓読み', list: ['かる、けず'] },
      { name: '音読み', list: ['ザイ、スイ、セイ'] },
    ],
  },
  {
    id: 37,
    level: 1,
    lesson: 12,
    name: '砂',
    label: '第１２課　砂',
    info: 'sand',
    flashcards: [],
    readings: [
      { name: '訓読み', list: ['すな'] },
      { name: '音読み', list: ['サ、シャ'] },
      { name: '名乗り', list: ['いち、ご'] },
    ],
  },
  {
    id: 38,
    level: 1,
    lesson: 12,
    name: '繊',
    label: '第１２課　繊',
    info: 'fine, slender',
    flashcards: [],
    readings: [
      { name: '音読み', list: ['セン'] },
    ],
  },
  {
    id: 39,
    level: 1,
    lesson: 12,
    name: '殺',
    label: '第１２課　殺',
    info: 'kill, lessen, butcher',
    flashcards: [],
    readings: [
      { name: '訓読み', list: ['ころ、ごろ、そ'] },
      { name: '音読み', list: ['サツ、サイ、セツ'] },
    ],
  },
  {
    id: 40,
    level: 1,
    lesson: 12,
    name: '滅',
    label: '第１２課　滅',
    info: 'fall into, ruin, perish, destroy',
    flashcards: [],
    readings: [
      { name: '訓読み', list: ['ほろ'] },
      { name: '音読み', list: ['メツ'] },
    ],
  },
  {
    id: 41,
    level: 1,
    lesson: 12,
    name: '抗',
    label: '第１２課　抗',
    info: 'resist, anti-, oppose',
    flashcards: [],
    readings: [
      { name: '訓読み', list: ['あらが'] },
      { name: '音読み', list: ['コウ'] },
    ],
  },
];