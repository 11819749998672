import { youna } from "../n4/youna";
import { youToOmou } from "../n4/youToOmou";
import { toItteIru } from "../n4/toItteIru";
import { toIu } from "../n4/toIu";
import { igai } from "../n4/igai";
import { oSuru } from "../n4/oSuru";
import { そうだ} from '../n4/そうだ';

export const grammarN4 = [
  youna,
  youToOmou,
  toIu,
  toItteIru,
  igai,
  oSuru,
  そうだ,
];