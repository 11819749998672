import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const toIu: OneGrammarType = {
  name: '〜という',
  level: 'N4',
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <JP>
          人の言ったことを引用して述べるのに用いる。{''}
          引用の仕方には⑵のように言ったとおりの言葉を直接引用する場合と{''}
          ⑴や⑶のように間接的に引用する場合がある。{''}
          間接的に引用するときは文体を普通体にする。{''}
          発言内容を問うは「なんといいましたか」または「どういいましたか」となる。{''}
          依頼や命令の文を間接的に引用する場合は「…ようにいう」となる。{''}
          <br /><br />
          尊敬語は「おっしゃる」、謙譲語は「申す」となる。
        </JP>
      ),
      translation: (
        <RUS>
          <JP>用いる（もちいる）</JP><br />
          <JP>述べる（のべる）</JP>to state<br />
          <JP>尊敬語（そんけいご）</JP><br />
          <JP>謙譲語（けんじょうご）</JP><br />
        </RUS>
      ),
    },
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <JP>
          ⑴　みんなには行くと行ったが、やはり行きたくない。
        </JP>
      ),
      translation: (
        <RUS>
          Сказал всем, что пойду, но не хочу идти.
        </RUS>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <JP>
          ⑴　みんなには行くと行ったが、やはり行きたくない。
        </JP>
      ),
      translation: (
        <RUS>
          Сказал всем, что пойду, но не хочу идти.
        </RUS>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 3,
      content: (
        <JP>
          ⑴　みんなには行くと行ったが、やはり行きたくない。
        </JP>
      ),
      translation: (
        <RUS>
          Сказал всем, что пойду, но не хочу идти.
        </RUS>
      ),
    }
  ],
};