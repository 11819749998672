import lesson_27_kaiwa from '../../../assets/mnn/27-kaiwa.mp3';
import lesson_27_mondai_1 from '../../../assets/mnn/27-mondai-1.mp3';
import lesson_27_mondai_2 from '../../../assets/mnn/27-mondai-2.mp3';

import { MnnAudioFileType } from "../mnnAudioFiles";

export const mnnLesson27: MnnAudioFileType[] = [
  {
    fileName: '会話',
    file: lesson_27_kaiwa,
    lessonNumber: 27,
    type: '教科書',
  },
  {
    fileName: '問題　１番',
    file: lesson_27_mondai_1,
    lessonNumber: 27,
    type: '教科書',
  },
  {
    fileName: '問題　２番',
    file: lesson_27_mondai_2,
    lessonNumber: 27,
    type: '教科書',
  },
];