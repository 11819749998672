import React from 'react';
import { Main } from "../Main";
import { MainContainer } from "../components/MainContainer";
import config  from "../config/config";
import { AudioPage } from "../components/audio/AudioPage";
import { GrammarPage } from "../components/grammar/GrammarPage";
//import { Words } from "../components/words/Words";
// import { Words } from "../components/words/Words.tsx";
import { Kotoba } from "../components/words/Kotoba";
import { Kanji } from "../components/kanji/Kanji";
import { AccessCheck } from "./AccessCheck";
import { Admin } from "../components/admin/Admin";
import { UsersContextProvider } from "../components/users/UsersContextProvider";
import { Tenki } from "../components/tenki/Tenki";
import { FlashcardContextProvider } from "../components/flashcard/context/FlashcardContextProvider";
import { ArticlesPage } from "../components/articles/ArticlesPage";

const BASE_URL = '/';
const ROOT_PATH = `${config.serverLocation}`;
export type ChildRouteType = {
  path: string;
  element: React.ReactNode;
  childRoutes?: ChildRouteType[];
  hasSuspense: boolean;
};

export type RouteType = {
  path: string;
  roles?: number[];
  title?: string;
  titleParam?: string;
  content: React.ReactNode | null;
  hasSuspense: boolean;
  childRoutes?: ChildRouteType[];
};

enum ROLES {
  STUDENT = 3332,
  ADMIN = 5150,
  EDITOR = 1984,
  USER = 2001,
}

// TODO Add lazy loader
//console.log('ROOT_PATH', ROOT_PATH);
export const routes: RouteType[] = [
  {
    path: `${BASE_URL}`,
    content: (
      <MainContainer />
    ),
    hasSuspense: true,
  },
  {
    path: `admin`,
    roles: [ROLES.ADMIN],
    content: (
      <UsersContextProvider>
        <Admin />
      </UsersContextProvider>
    ),
    hasSuspense: true,
  },
  {
    path: `flashcards`,
    roles: [ROLES.ADMIN, ROLES.STUDENT],
    content: (
      <FlashcardContextProvider
        lesson={1}
        level={1}
        deckName={'Test Name'}
      >
        <Tenki />
      </FlashcardContextProvider>
    ),
    hasSuspense: true,
  },
  {
    path: `audio`,
    roles: [ROLES.STUDENT],
    content: (
      <AudioPage />
    ),
    hasSuspense: true,
  },
  {
    path: 'grammar',
    roles: [ROLES.STUDENT],
    content: (
      <GrammarPage />
    ),
    hasSuspense: true,
  },
  {
    path: 'words',
    roles: [ROLES.STUDENT],
    content: (
      <Kotoba />
    ),
    hasSuspense: true,
  },
  {
    path: 'kanji',
    roles: [ROLES.STUDENT],
    content: (
      <Kanji />
    ),
    hasSuspense: true,
  },
  {
    path: 'articles',
    roles: [ROLES.STUDENT],
    content: (
      <ArticlesPage />
    ),
    hasSuspense: true,
  }
];
//console.log('routes', routes);
const defaultContext = {
  routes,
};

interface RoutesContextInterface {
  routes: RouteType[];
}

const RoutesContext = React.createContext<RoutesContextInterface>(defaultContext);

export { RoutesContext };