import React from 'react';
import { Box } from "@mui/material";
import { Users } from "../users/Users";

export const Admin: React.FC = () => {
  return (
    <>
      <Box
        sx={{
          padding: '0 2vh 0 2vh',
          // paddingTop: '0',
          //backgroundColor: 'rgba(255,255,255,0.9)',
          backgroundColor: 'transparent',
          borderRadius: '15px',
        }}
      >
        <Users />
      </Box>
    </>
  );
};