import React, {useState, useEffect, useCallback} from 'react';
import { Grid } from "@mui/material";
import dayjs, { Dayjs } from 'dayjs';
import _find from 'lodash/find';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useRefreshToken from "../../hooks/useRefreshToken";
import { RUS, RUS_2 } from "../../style";
import {LessonButton, StyledRow} from "./style";
import { axiosPrivate } from "../../api/axios";
import { useUsersContext } from "./UsersContext";
import { Lessons } from "../lessons/Lessons";
import { LessonDialogue } from "../lessons/LessonDialogue";

const CREATE_LESSON_URL = '/lessons/lesson';

export const Users: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState<string | null>(null);
  const axiosPrivate = useAxiosPrivate();
  const { users, fetchUsers, setUsers } = useUsersContext();

  const handleCreateNewLesson = useCallback(async (
    userId: string,
    selectedDate: Dayjs,
  ): Promise<void> => {
    const controller = new AbortController();
    console.log('Wants to create a lesson');
    console.log('selectedDate', selectedDate);
    try {
      const lesson = {
        userId: userId,
        lessonName: 'Test Lesson 0',
        lessonDate: selectedDate, // new Date(),
      }
      const response = await axiosPrivate.post(CREATE_LESSON_URL, {
        userId: lesson.userId,
        lessonName: lesson.lessonName,
        lessonDate: lesson.lessonDate,
      },{
        signal: controller.signal,
      });
      console.log('response', response);
      // TODO isMounted &&
    } catch (err: any) {
      console.log('Could not create a new lesson');
      console.log(err);
    }
  }, [axiosPrivate]);



  useEffect(() => {
    let isMounted = true;
    // Previously cancellationToken,
    // in order to cancel a request if the component unmounts
    const controller = new AbortController();

    fetchUsers(controller).then(
      users => {
        console.log('Users', users);
        isMounted && setUsers(users);
      },
      error => {
        console.error(error);
      }
    )
    // It will receive 401 until it sends accessToken

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, []);

  const handleOpen = (userId: string): void => {
    setOpen(true);
    setUserId(userId);
    // () => handleCreateNewLesson(user?.id)
  };

  const handleClose = (): void => {
    setUserId(null);
    setOpen(false);
  };

  const create = useCallback((selectedDate: Dayjs): void => {
    if (userId) {
      handleCreateNewLesson(userId, selectedDate).catch(console.error);
    } else {
      console.error('Cannot create a lesson, there is no user');
    }
  }, [userId]);

  return (
    <article>
      <LessonDialogue open={open} onClose={handleClose} create={create} />
      <Lessons />
      <h4>Users</h4>

      <Grid container>
      </Grid>
      {users?.length
        ? (
          <Grid container flexDirection='row' rowSpacing={1}>
            <Grid item xs={12}>
              <Grid item xs={2}>
                <RUS><b>Имя</b></RUS>
              </Grid>
            </Grid>
            {users.map((user, i) => {
              return (
                <StyledRow key={i} item xs={12}>
                  <Grid item xs={12} sm={2}>
                    {user?.username}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {
                      user?.roles &&
                      Object.keys(user?.roles).map((role, i) => {
                        return (
                          <Grid key={i} item xs={12}>
                            {role} [{user?.roles?.[role]}]
                          </Grid>
                        );
                      })
                    }
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <LessonButton onClick={() => handleOpen(user?.id)}>Add Lesson</LessonButton>
                  </Grid>
                </StyledRow>
              );
            })}
          </Grid>
        ) : (
          <p>No users to display.</p>
        )
      }
    </article>
  )
};