import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from "@mui/material";
import { Player } from "./Audio";
import { mnnAudioFiles, MnnAudioFileType } from "./mnnAudioFiles";
import { SelectInput } from "../select-input/SelectInput";
import { JP, RUS } from "../../style";

export const AudioPage: React.FC = () => {
  const [selectedLessonNumber, setSelectedLessonNumber] = useState<number>(mnnAudioFiles[0]?.lessonNumber || 0);
  const [visibleLessons, setVisibleLessons] = useState<MnnAudioFileType[]>([]);

  const handleSelectedLessonNumberChange = (value: string) => {
    setSelectedLessonNumber(parseInt(value));
  };

  useEffect(() => {
    const list: MnnAudioFileType[] = [];
    mnnAudioFiles.map((item) => {
      if (item.lessonNumber === selectedLessonNumber) {
        list.push(item);
      }
    });
    setVisibleLessons(list);
  }, [selectedLessonNumber]);

  const onlyUnique = (value: string | number, index: number, list: string[] | number[]): boolean => {
    return list.indexOf(value as never) === index;
  };

  const lessonNumbers = useMemo(() => {
    const lessonNumbers = mnnAudioFiles.map((item) => {
      return item.lessonNumber;
    });
    return lessonNumbers.filter(onlyUnique);
  }, [mnnAudioFiles]);

  return (
    <Grid
      container
      alignItems='center'
      columnSpacing={2}
      rowSpacing={2}
    >
      <Grid item xs={12}>
        <JP>
          みんなの日本語　教科書　２版［２０１３年３月２１日
        </JP>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={2}>
          <SelectInput
            label='Урок'
            list={lessonNumbers}
            selectedValue={selectedLessonNumber.toString()}
            handleSelectedValueChange={handleSelectedLessonNumberChange}
            suffix='課'
          />
        </Grid>
      </Grid>
      {
        visibleLessons.length === 0 &&
        <Grid item xs={12} style={{ border: '0px solid red' }}>
          <RUS>
            Файлов не выбрано. <br />Выберите урок.
          </RUS>
        </Grid>
      }
      {
        visibleLessons.map((item, i) => {
          return (
            <Grid item xs={12} style={{ border: '0px solid red' }} key={`audio-${i}`}>
              <Grid
                container
                alignItems='center'
                justifyContent='flex-start'
                columnSpacing={2}
                rowSpacing={1}
              >
                <Grid item>
                <JP>
                  {item.lessonNumber}課［<span style={{ color: 'blue' }}>{item.type}</span>］{item.fileName}
                </JP>
                </Grid>
                <Grid item>
                  <Player
                    url={
                      item.file
                      // 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3'
                      // 'http://localhost:3000/src/assets/mnn/26-2-mondai.mp3'
                    } />
                </Grid>
              </Grid>
            </Grid>
          );
        })
      }
      {
        visibleLessons.length > 0 &&
        <Grid item xs={12} style={{ border: '0px solid red' }}>
          <RUS>
            Найдено файлов: {visibleLessons.length} <br />
          </RUS>
        </Grid>
      }
      <Grid item xs={12}>
        <JP>
          教科書　きょうかしょ{' '}
          <RUS>
            учебник<br />
          </RUS>
        </JP>
        <JP>
          聴解　ちょうかい{' '}
          <RUS>
            аудирование<br />
          </RUS>
        </JP>
      </Grid>
    </Grid>
  );
};