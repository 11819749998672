import React, {useMemo, useState, useCallback, useEffect} from 'react';
import _find from 'lodash/find';
import { Grid, ThemeProvider } from '@mui/material';
import { SelectInput } from "../select-input/SelectInput";
import {GrammarType} from "../grammar/types";
import {grammarList} from "../grammar/grammar-all/grammar";
import { selectTheme } from "./style";

type Props = {
  getFlashcards: (textbookName: string, lessonNumber: number) => Promise<void>;
  resetFlashcards: () => void;
}

type TextbookType = {
  name: string;
  numberOfLessons: number;
}

export const FlashcardsMenu: React.FC<Props> = (props) => {
  const [selectedTextbookName, setSelectedTextbookName] = useState<string | null>(null);
  const [selectedLessonNumber, setSelectedLessonNumber] = useState<number | null>(null);
  const { getFlashcards, resetFlashcards } = props;

  const textbooks: TextbookType[] = useMemo(() => {
    return [
      {
        name: 'みんなの日本語　１〜２５課',
        numberOfLessons: 25,
      },
      {
        name: '耳から覚えるN3　語彙',
        numberOfLessons: 2,
      },
      {
      name: '総まとめN2　語彙',
      numberOfLessons: 5,
    },{
      name: '総まとめN3　漢字',
      numberOfLessons: 5,
    }];
  }, []);

  const textbookNames = useMemo(() => {
    return textbooks.map((textbook) => textbook.name);
  }, [textbooks]);

  const lessonNumbers = useMemo(() => {
    if (selectedTextbookName) {
      const found = _find(textbooks, (textbook) => textbook.name === selectedTextbookName);
      if (found) {
        let list: number[] = [];
        for (let i=1;i<found.numberOfLessons+1;i++){
          list.push(i);
        }
        return list;
      } else return [];
    } else return [];
  }, [textbooks, selectedTextbookName]);

  useEffect(() => {
    if (selectedTextbookName && selectedLessonNumber) {
      getFlashcards(selectedTextbookName, selectedLessonNumber).then(
        () => {
          // Fetched successfully
        },
        error => {
          console.error(error);
        },
      );
    } else {
      resetFlashcards();
    }
  }, [selectedTextbookName, selectedLessonNumber]);


  const handleTextbookNameChange = useCallback((value: string): void => {
    const found = _find(textbookNames, (name) => name === value);
    const newSelectedTextbookName = found ? found : '';
    setSelectedTextbookName(newSelectedTextbookName);
    setSelectedLessonNumber(null);
  }, [textbookNames]);

  const handleLessonNumberChange = useCallback((value: string): void => {
    const found = _find(lessonNumbers, (lessonNumber) => lessonNumber === parseInt(value));
    const newSelectedLessonNumber = found ? found : null;
    setSelectedLessonNumber(newSelectedLessonNumber);
  }, [lessonNumbers]);

  return (
    <ThemeProvider theme={selectTheme}>
      <Grid
        container
        alignItems='center'
        columnSpacing={2}
        rowSpacing={2}
      >
        <Grid item xs={6}>
          <SelectInput
            label='Название учебника'
            list={textbookNames}
            selectedValue={selectedTextbookName || ''}
            handleSelectedValueChange={handleTextbookNameChange}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label='Номер урока'
            list={lessonNumbers}
            selectedValue={selectedLessonNumber?.toString() || ''}
            handleSelectedValueChange={handleLessonNumberChange}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};