import React, {useState} from 'react';
import {Box, Container, Grid} from "@mui/material";
import { BackgroundImageStyle } from "../style";
import {Header} from "./Header";
import {Card} from "./card/Card";
import {LessonType1} from "./card/LessonType1";
import {LessonType2} from "./card/LessonType2";
import { LessonType3 } from "./card/LessonType3";
import {AdvantageLine1} from "./card/AdvantageLine1";
import {AdvantageCard} from "./card/AdvantageCard";
import tenki from "../assets/tenki.jpeg";
import { JP } from "../style";
import { Users } from "./users/Users";

type Props = {
  children: React.ReactNode;
};

const LINE_HEIGHT = 1.4;

export const MainContainer: React.FC = () => {
  const [isReadMore, setIsReadMore] = useState(false);

  const handleReadMore = (): void => {
    setIsReadMore(!isReadMore);
  };

  return (
    <>
      <Grid container justifyContent='flex-start'>
        <Grid item style={{ }}>
          <Box
            sx={{
              padding: '0vh 2vh 0 2vh',
              // paddingTop: '0',
              //backgroundColor: 'rgba(255,255,255,0.9)',
              backgroundColor: 'transparent',
              borderRadius: '15px',
            }}
          >
            <div style={{
              fontFamily: 'Arial',
              fontSize: '16px', lineHeight: LINE_HEIGHT, }}>
              Школа японского языка "Оодзора". <br />
              Сайт в разработке.
            </div>
          </Box>
        </Grid>
      </Grid>

      <br />

      <Grid item xs={12}>
        <Header
          lineHeight={LINE_HEIGHT}
          heading='Типы занятий'
        />
      </Grid>
      <br />
      <Grid
        container
        flexWrap='wrap'
        columnSpacing={3}
        rowSpacing={3}
        alignItems='stretch'
      >
        <Grid item md={6} style={{ border: '0px solid red' }}>
          <Card
            lineHeight={LINE_HEIGHT}
            lessonTitleJp='プライベートレッスン'
            lessonTitleRus='Индивидуальные занятия'
            lessonDescription={<LessonType1 />}
            lessonTime='60 или 90 минут'
            backgroundColor={'rgba(97,218,251,0.1)'}
            padding={'3vh'}
          />
        </Grid>
        <Grid item md={6} style={{ border: '0px solid red' }}>
          <Card
            lineHeight={LINE_HEIGHT}
            lessonTitleJp='会話練習'
            lessonTitleRus='Языковая практика'
            lessonDescription={<LessonType2 />}
            lessonTime='30 минут'
            backgroundColor={'rgba(97,218,251,0.1)'}
            padding={'3vh'}
          />
        </Grid>
        <Grid item md={6} style={{ border: '0px solid red' }}>
          <Card
            lineHeight={LINE_HEIGHT}
            lessonTitleJp='日本語能力試験'
            lessonTitleRus='Подготовка к сдаче нихонго норёку сикэн'
            lessonDescription={<LessonType3 />}
            lessonTime='60 или 90 минут'
            backgroundColor={'rgba(97,218,251,0.1)'}
            padding={'3vh'}
          />
        </Grid>
        <Grid item xs={12}>
          <Header
            lineHeight={LINE_HEIGHT}
            heading='Что получают студенты?'
          />
        </Grid>
        <Grid item md={6}>
          <div style={{
            // padding: '5vh',
            paddingTop: '0vh',
            fontFamily: 'Arial',
            fontSize: '16px', lineHeight: LINE_HEIGHT, }}>
            <Grid
              container
              alignItems='center'
              flexWrap='wrap'
              columnSpacing={2}
              rowSpacing={1}
            >
              <Grid item xs={12}>
                <AdvantageLine1
                  heading='Правильное японское произношение'
                />
              </Grid>
              <Grid item xs={12}>
                <AdvantageCard
                  backgroundColor={'rgba(191,210,140, 0.3)'}
                  padding={'0vh 3vh 2vh 3vh'}
                  handleReadMore={handleReadMore}
                  isReadMore={isReadMore}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item md={6}>
          <div style={{
            // padding: '5vh',
            paddingTop: '0vh',
            fontFamily: 'Arial',
            fontSize: '16px', lineHeight: LINE_HEIGHT, }}>
            <Grid
              container
              alignItems='flex-start'
              flexWrap='wrap'
              columnSpacing={2}
              rowSpacing={1}
            >
              <Grid item xs={12}>
                <AdvantageLine1
                  heading='Аутентичные японские материалы'
                />
              </Grid>
              <Grid item xs={12}>
                <AdvantageLine1
                  heading='Подготовка к нихонго норёку сикэн'
                />
              </Grid>
              <Grid item xs={12}>
                <AdvantageLine1
                  heading='Последовательное изучение'
                />
              </Grid>
              <Grid item xs={12}>
                <AdvantageLine1
                  heading='Навык переводить в обе стороны (ЯП→РУС, РУС→ЯП)'
                />
              </Grid>
              <Grid item xs={12}>
                <AdvantageLine1
                  heading='Грамматический справочник и словарь (на сайте, доступны через личный кабинет)'
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Header
            lineHeight={LINE_HEIGHT}
            heading='Специальные курсы'
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid
            container
            flexWrap='wrap'
            justifyItems='stretch'
            columnSpacing={1}
            rowSpacing={1}
            style={{ border: '0px solid red'}}
          >
            <Grid item xs={12} sm={12} md={4} style={{ border: '0px solid red'}}>
              <img src={tenki} width={'100%'}/>
            </Grid>
            <Grid item xs={12} sm={12} md={8} style={{ border: '0px solid blue'}}>
              <Card
                lineHeight={LINE_HEIGHT}
                lessonTitleJp='天気の子'
                lessonTitleRus='Дитя погоды'
                lessonDescription={
                  <div>
                    Чтение книги на японском
                    языке с разбором грамматики и лексики. 320 страниц.<br /><br />
                    Это очень красивая книга.
                    В ней много слов, описывающих погоду, особенно дождь.
                    Японцы любят обращать внимание на детали природы и у них есть много слов
                    для передачи увиденного. Благодаря этому
                    удаётся прочувствовать атмосферу.
                    Главного героя зовут Ходака, он убежал из дома и приехал в Токио.
                    За окном уже который день льёт дождь.
                    Случайным образом Ходака знакомится с девушкой по имени Хина-сан...<br /><br />
                    Аннотация к книге:
                    <br />
                    <JP>
                      僕は帆高（ほだか）。家出して東京にきた。<br />
                      外はもう何日も雨ばかり。<br />
                      この天気のせいか、雑誌の取材のバイトで“晴れ女”を探すことになって……<br />
                      そんな人、いるわけないと思っていたけど、僕は、ふしぎな少女、陽菜（ひな）さんに出会った。<br />
                      「ねえ、今から晴れるよ」<br />
                      そう言って陽菜さんが祈ると、空から光がさしてきて……<br />
                      もしかして、本物の“晴れ女”！？<br />
                      僕と陽菜さんの、特別な夏がはじめる！
                    </JP>
                    {
                      /*
                      <Player
                      url={
                        test
                      // 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3'
                        // 'http://localhost:3000/src/assets/mnn/26-2-mondai.mp3'
                    } />
                       */
                    }
                  </div>
                }
                lessonTime='Уровень книги н2+, наш курс составлен специально, чтобы могли понять ученики с н4+'
                backgroundColor={'rgba(9,124,193,0.08)'}
                padding={'3vh'}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};