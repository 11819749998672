import React, { useEffect, useState, useMemo } from 'react';
import { Grid, Button } from "@mui/material";
import { JP, RUS, Accent, GrammarTable, StyledDialogTitle } from "../../style";
import { SelectInput } from "../select-input/SelectInput";
import { grammarList } from "./grammar-all/grammar";
import { GrammarType } from "./types";
import { GrammarTableModal } from "./GrammarTableModal";

export const GrammarPage: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedGrammar, setSelectedGrammar] = useState<GrammarType | null>(grammarList[0] || null);
  const [visibleGrammar, setVisibleGrammar] = useState<GrammarType[]>(grammarList[0] ? [grammarList[0]] : []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectedGrammarChange = (value: string): void => {
    const list: GrammarType[] = [];
    grammarList.map((item) => {
      if (item.name === value) {
        list.push(item);
      }
    });
    setSelectedGrammar(list[0]);
    setVisibleGrammar(list);
  };

  const handleSelectedLevelChange = (value: string): void => {
    const list: GrammarType[] = [];
    grammarList.map((item) => {
      if (item.level === value) {
        list.push(item);
      }
    });
    setSelectedGrammar(list[0]);
    setVisibleGrammar(list);
  };

  const onlyUnique = (value: string | number, index: number, list: string[] | number[]): boolean => {
    return list.indexOf(value as never) === index;
  };

  const grammarNames = useMemo(() => {
    const list: string[] = [];
    const grammarNames = grammarList.map((item) => {
      if (item.level === selectedGrammar?.level) {
        list.push(item.name);
      }
    });
    return list.filter(onlyUnique);
  }, [selectedGrammar]);

  const grammarLevels = useMemo(() => {
    const grammarLevels = grammarList.map((item) => {
      return item.level;
    });
    return grammarLevels.filter(onlyUnique);
  }, []);
  //console.log('grammarList', grammarList);
  //console.log('visibleGrammar', visibleGrammar);
  //console.log('selectedGrammar', selectedGrammar);

  const tableGrammar = useMemo(() => {
    const list: any[] = [];
    grammarList.map((item) => {
      if (item.level === selectedGrammar?.level) {
        list.push({
          name: item.name,
          level: item.level,
        });
      }
    });
    return list;
  }, [selectedGrammar]);
  return (
    <Grid
      container
      alignItems='center'
      columnSpacing={2}
      rowSpacing={2}
    >
      <Grid item xs={12}>
        <StyledDialogTitle onClick={handleClickOpen}>
          Список грамматики {selectedGrammar?.level}
        </StyledDialogTitle>
        <GrammarTableModal
          open={open}
          onClose={handleClose}
          content={
            <GrammarTable>
              <thead>
              <tr>
                <th><JP>文法</JP></th>
                <th><JP>級</JP></th>
              </tr>
              </thead>
              <tbody>
              {
                tableGrammar.map((item, i) => {
                  return (
                    <tr key={`grammar-${i}`}>
                      <td><JP>{item.name}</JP></td>
                      <td><>{item.level}</></td>
                    </tr>
                  );
                })
              }
              </tbody>
            </GrammarTable>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          alignItems='center'
          columnSpacing={2}
          rowSpacing={2}
        >
          <Grid item xs={5}>
            <SelectInput
              label='文法'
              list={grammarNames}
              selectedValue={selectedGrammar?.name || ''}
              handleSelectedValueChange={handleSelectedGrammarChange}
            />
          </Grid>
          <Grid item xs={5}>
            <SelectInput
              label='級'
              list={grammarLevels.sort()}
              selectedValue={selectedGrammar?.level || ''}
              handleSelectedValueChange={handleSelectedLevelChange}
            />
          </Grid>
        </Grid>
      </Grid>
      {
        selectedGrammar &&
        <Grid item xs={12} md={12} key={`grammar-${selectedGrammar.name}`}>
          <Grid item xs={12}>
            <JP>
              {selectedGrammar.name}
            </JP>
            <br/><br/>
          </Grid>
          <Grid item xs={12}>
            {selectedGrammar.content}
          </Grid>
        </Grid>
      }
    </Grid>
  );
};