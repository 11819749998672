import React from 'react';
import { useAuthContext } from "../context/AuthProvider";
import { useLocation, Navigate, Outlet } from "react-router-dom";

type Props = {
  allowedRoles?: number[];
}

export const RequireAuth: React.FC<Props> = ({ allowedRoles }) => {
  const { auth } = useAuthContext();
  const location = useLocation();

  if (auth?.roles?.find(role => allowedRoles?.includes(role))) {
    return <Outlet />;
  } else if (auth?.user) {
    // state={{ from: location }} replace is required for Back button to work
    // return <Navigate to='/unauthorized' state={{ from: location }} replace />;
    return <>
      Вы авторизованы, но у Вас нет доступа к данной странице.<br />
      Попросите преподавателя дать Вам доступ.
    </>;
  } else {
    return <>
      Войдите в личный кабинет.
    </>;
  }
};