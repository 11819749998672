import React from "react";
import { Accent, JP, RUS } from "../../../style";
import { OneGrammarType } from "../types";

export const totomoni: OneGrammarType = {
  name: '〜とともに',
  level: 'N2',
  meanings: [
    {
      meaningNumber: 1,
      content: (
        <RUS>
          Сущ. + <JP>とともに</JP><br /><br />
          Присоединяется к <b>существительному</b> (<JP>名詞　めいし</JP>), которое обозначает
          человека или организацию.
          Переводится как <b>делать что-то вместе (совместно) или
          в сотрудничестве с кем-либо</b>.<br />
          Используется в основном в письменной речи.
          <br /><br />
        </RUS>
      ),
    }
  ],
  examples: [
    {
      meaningNumber: 1,
      exampleNumber: 1,
      content: (
        <>
          <JP>①　仲間<Accent>とともに</Accent>作業に励んでいる。</JP><br />
          <JP>仲間　なかま</JP><br />
          <JP>作業　さぎょう</JP><br />
          <JP>励む　はげむ</JP><br />
        </>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 2,
      content: (
        <JP>
          ②　夫<Accent>とともに</Accent>幸せな人生を歩んできた。<br />
          <JP>人生　じんせい</JP><br />
          <JP>歩む　あゆむ</JP><br />
        </JP>
      ),
    },
    {
      meaningNumber: 1,
      exampleNumber: 3,
      content: (
        <JP>
          ③　隣国<Accent>とともに</Accent>地域経済の発展に努めている。<br />
          <JP>隣国　りんごく</JP><br />
          <JP>発展　はってん</JP><br />
        </JP>
      ),
    },
  ],
};