import React, {useState} from "react";
import { Grid, Button } from "@mui/material";
import { KanjiType } from "./types";
import yomu from "../../assets/kanji/yomu.gif";
import {kanjiList} from "./list";
import {Line} from "./Line";
import yomuFrames from "../../assets/kanji/yomu_frames.png";
import { colors } from "../../utils/palette";
import { JP, Accent, RUS, KanjiButton } from "../../style";
import { KanjiDialogue } from "./KanjiDialogue";
import ZoomImage from "../zoom-image";

type Props = {
  index: number;
  totalLength: number;
} & KanjiType;

export const KanjiCard: React.FC<Props> = (props) => {
  const [open, setOpen] = useState<boolean>(false);
  const { index, totalLength, words, kanjiImage, kanjiImageFrames, kun, on } = props;
  console.log('props',props);
  return (
    <Grid item xs={12} md={6}>
      <Grid
        container
        style={{
           borderRadius: '4px',
          backgroundColor: 'white', // '#dbf3da',
        }}
        alignItems='center'
        columnSpacing={1}
        rowSpacing={0}
      >
        <Grid item xs={12}>
          <Grid
            container
            justifyContent='space-between'
            alignItems='stretch'
          >
            <Grid item style={{ padding: '1vh' }}>
              <RUS>{index+1} из {totalLength}</RUS><br />
            </Grid>
            <Grid item>
              <Grid
                container
                justifyContent='flex-end'
                alignItems='flex-start'
              >
                {/*
                  (kun && kun.length > 0) &&
                  <Grid item
                        style={{
                          backgroundColor: 'yellowgreen',
                          color: 'white',
                          padding: '1vh',
                          //marginRight: '5px'
                        }}
                  >
                    <JP style={{ fontFamily: '"KosugiMaru-Regular", sans-serif', fontSize: '18px'}}>
                      {
                        kun.map((item, i) => {
                          return <div key={i}>{item}</div>;
                        })
                      }
                    </JP>
                  </Grid>
               */}
                {/*
                  (on && on.length > 0) &&
                  <Grid item
                        style={{
                          backgroundColor: 'forestgreen',
                          color: 'white',
                          padding: '1vh',
                          //marginRight: '5px',
                        }}
                  >
                    <JP style={{ fontFamily: '"KosugiMaru-Regular", sans-serif', fontSize: '18px'}}>
                      {
                        on.map((item, i) => {
                          return <div key={i}>{item}</div>;
                        })
                      }
                    </JP>
                  </Grid>
                */}
                <Grid item
                      style={{
                        backgroundColor: '#dbf3da', // 'rgba(145, 166, 255,0.9)',// '#dbf3da',
                        color: '#84ac84',
                        padding: '1vh'
                      }}
                >
                  <JP style={{ fontFamily: '"KosugiMaru-Regular", sans-serif', fontSize: '18px'}}>N{props.level}</JP><br />
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
        {
          /*
          <Grid item xs={12}
              style={{
                background: 'linear-gradient(to right, rgba(145, 166, 255,0.5), rgba(145, 166, 255,0.2))',
                color: 'white',// '#2d80fc',
                border: `1px solid rgba(145, 166, 255,0.9)`,
                padding: '1vh',
                //marginRight: '5px',
              }}
        >
          <Grid container justifyContent={'center'}>
            <Grid item>
              <JP style={{ fontFamily: '"KosugiMaru-Regular", sans-serif', fontSize: '138px'}}>
                {props.kanji}
              </JP>
            </Grid>
          </Grid>
        </Grid>
           */
        }
        <Grid item xs={12} lg={12} style={{ display: 'flex', justifyContent: 'center'}}>
          <img src={kanjiImage} width={'100%'} style={{ maxWidth: '180px' }} />
        </Grid>
        <Grid item xs={12} sm={12}　style={{ overflow: 'hidden', overflowX: 'scroll' }}>
          <img src={kanjiImageFrames} width={'100%'} style={{ height: '55px', width: 'auto'}} />
        </Grid>

        <Grid item xs={12}>
          {
            words.map((item, i) => {
              return (
                <Line key={i} word={item.word} level={item.level} translation={item.translation} />
              );
            })
          }
        </Grid>
        {
          (props.images && props.images?.length > 0) &&
          <Grid item xs={12}>
            <br/>
            <KanjiButton onClick={() => setOpen(true)}>
              <p style={{ padding: '2px', fontSize: '28px', fontFamily: `"KosugiMaru-Regular", sans-serif;`}}>{props.kanji}</p>
            </KanjiButton>
          </Grid>
        }
        <KanjiDialogue
          kanji={props.kanji}
          images={props.images || []}
          open={open}
          onClose={() => setOpen(false)}
        />
      </Grid>
    </Grid>
  );
};