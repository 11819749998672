import React, {useContext, Dispatch, SetStateAction} from 'react';

export type FlashcardType = {
  id: number; // auto
  lesson: number;
  // textbook: string; // Textbook name
  name: string; // ??? for kanji only
  front: string; // front side
  backJp?: string[]; // back side
  backRus?: string[]; // back side
  size?: number;
  isOpen?: boolean; //
  sentenceNumber?: number;
  jlptLevel?: number; //
}

export type ReadingType = {
  name: string;
  list: string[];
}

export type MnnListType = {
  id: number;
  level: number;
  lesson: number;
  name: string;
  label: string;
  info?: string;
  flashcards: FlashcardType[];
  readings?: ReadingType[];
}

export type DataType = {
  count: number;
  list: FlashcardType[];
}

export type PaginationType = {
  count: number;
  from: number;
  to: number;
}

interface FlashcardContextInterface {
  pageSize: number;
  level?: number; // book 1 or book 2
  lesson?: number;
  deckName?: string;
  grammarName?: string;
  content?: React.ReactNode;
  data: DataType | null;
  updateData: (id: number, isOpen: boolean) => void;
  addFlashcardUrl: string;
  pagination: PaginationType;
  setPagination: Dispatch<PaginationType>;
  handlePageChange: (e: any, page: number) => void,
  fetchFlashcards: (textbookName: string | null, lessonNumber: number | null, controller: AbortController) => Promise<FlashcardType[]>;
  setFlashcards: React.Dispatch<SetStateAction<FlashcardType[]>>
}

const FlashcardContext = React.createContext<FlashcardContextInterface | null>(null);

const useFlashcardContext = (): FlashcardContextInterface => {
  const context = useContext(FlashcardContext);
  if (!context) {
    throw new Error(
      'useFlashcardContext must be used within FlashcardContextProvider.'
    );
  }
  return context;
}

export { FlashcardContext, useFlashcardContext };