import React, {useCallback, useEffect, useRef, useState} from 'react';
import { Grid, Box, Pagination } from "@mui/material";
import { JP, JP_2, JPM, RUS, RUS_2, RUS_3 } from "../../style";
import { FlashcardType } from "../flashcard/context/FlashcardContext";
import { Flashcard } from "../flashcard/Flashcard";
import { Flashcards } from "../flashcard/Flashcards";
import { useFlashcardContext } from "../flashcard/context/FlashcardContext";
import { KANJI_BOOK } from "../flashcard/context/kanji_book";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { FlashcardsMenu } from "../flashcard/FlashcardsMenu";

export const Tenki: React.FC = () => {
  const isMounted = useRef(false);
  const [getFlashcardsController, setFlashcardsController] = useState<AbortController>(new AbortController());
  const {
    fetchFlashcards,
    updateData,
    data,
    pagination,
    handlePageChange,
    pageSize,
    setFlashcards,
  } = useFlashcardContext();

  useEffect(() => {
    isMounted.current = true;
    isMounted.current && setFlashcardsController(new AbortController());

    return () => {
      isMounted.current = false;
      getFlashcardsController.abort();
    };
  }, []);

  const getFlashcards = useCallback(async (
    textbookName: string,
    lessonNumber: number,
  ): Promise<void> => {
    fetchFlashcards(textbookName, lessonNumber, getFlashcardsController).catch(console.error);
  }, [isMounted, getFlashcardsController]);

  const resetFlashcards = (): void => {
    setFlashcards([]);
  };

  // console.log('test', pagination.count / pageSize);
  // console.log('pagination', pagination);

  return (
    <>
      <Grid
        container
        alignItems='center'
        columnSpacing={2}
        rowSpacing={2}
      >
        <Grid item xs={12}>
          <FlashcardsMenu
            getFlashcards={getFlashcards}
            resetFlashcards={resetFlashcards}
          />
        </Grid>
        <Grid item xs={12}>
          <Flashcards
            flashcards={data?.list || []}
            updateData={updateData} />
        </Grid>
        {
          data && data?.list.length > 0 &&
          <Grid item xs={12}>
            <Box
              justifyContent={'center'}
              alignItems={'center'}
              display={'flex'}
              sx={{
                margin: '20px 0px',
              }}
            >
              <Pagination
                count={Math.ceil(pagination.count / pageSize)}
                onChange={handlePageChange}
              />
            </Box>
          </Grid>
        }
        {
          /*
          <Grid item xs={12}>
          <JP>
            雨宿り（あまやどり）が出来て（できて）、一晩（ひとばん）すごせそうな場所。
            そういう公園（こうえん）の東屋（あずまや）やガード下（した）の軒先（のきさき）には、
            しかし必ず（かならず）先客（せんきゃく）がいた。
          </JP>
          <br /><br />
          <br /><JP>あずまや</JP><RUS_3>{' '}беседка</RUS_3><JP>（東屋）</JP>
          <br /><br />
          <JP>
            僕（ぼく）は全財産（ぜんざいさん）の
            入った（はいった）重い（おもい）リュックを雨合羽（あまガッパ）のしたに背負い（せおい）、
            もう二時間（にじかん）以上（いじょう）も街（まち）をさまよっている。
          </JP>
          <br /><br />
          <br /><JP>ぜんざいさん</JP><RUS_3>{' '}everything one owns</RUS_3><JP>（全財産）</JP>
          <br /><JP>せおう</JP><RUS_3>{' '}to carry on one's back / нести что-то на спине</RUS_3><JP>（背負う）</JP>
          <br /><JP>リュックをせおう</JP><RUS_3>{' '}с рюкзаком на спине</RUS_3>
          <br /><JP>リュック</JP><RUS_3>{' '}rucksack / рюкзак</RUS_3>
          <br /><JP>あまガッパ</JP><RUS_3>{' '}raincoat / пальто-дождевик</RUS_3><JP>（雨合羽）</JP>
          <br /><JP>まち　を　さまよう</JP><RUS_3>{' '}шататься по городу</RUS_3><JP>（街を彷徨う）</JP>
          <br /><br />
          <JP>
            長時間（ちょうじかん）を過ごせて（すごせて）居心地（いごこち）の好い（よい）デパートや本屋（ほんや）や
            CDショップは、夜（よる）九時（くじ）を過ぎて（すぎて）既に（すでに）
            閉まって（しまって）しまっている。
          </JP>
          <br /><br />
          <br /><JP>いごこち</JP><RUS_3>{' '}комфорт</RUS_3><JP>（居心地）</JP>
          <br /><JP>いごこち　の</JP><RUS_3>{' '}комфортный</RUS_3><JP>（居心地の）</JP>
          <br /><JP>すでに</JP><RUS_3>{' '}already / уже</RUS_3><JP>（既に）</JP>
          <br /><br />
          <JP>
            駅（えき）の構内（こうない）も家電（かでん）量販店（りょうはんてん）も、
            壁際（かべぎわ）に座り込んで（すわりこんで）いたりするとすぐに警備員（けいびいん）が声（こえ）をかけてくる。
          </JP>
          <br /><br />
          <br /><JP>こうない</JP><RUS_3>{' '}premises</RUS_3><JP>（構内）</JP>
          <br /><JP>かでん</JP><RUS_3>{' '}household (electrical) appliances</RUS_3><JP>（家電）</JP>
          <br /><JP>りょうはんてん</JP><RUS_3>{' '}volume retailer</RUS_3><JP>（量販店）</JP>
          <br /><JP>けいびいん</JP><RUS_3>{' '}security guard</RUS_3><JP>（警備員）</JP>
          <br /><br />
          <JP>
            だから僕（ぼく）はもう路上（ろじょう）に居場所（いばしょ）を見つける（みつける）
            他（ほか）になく、しかしそんな場所（ばしょ）は一向（いっこう）に見つからず（みつからず）、
            かといって駅（えき）から離れ（はなれ）すぎるのもなんだか不安（ふあん）で結局（けっきょく）は
            同じ（おなじ）場所（ばしょ）をぐるぐる廻って（まわって）しまっていて、だから
            派手（はで）な電飾（でんしょく）の
          </JP>
          <br /><br />
          <br /><JP>ぐるぐる</JP><RUS_3>{' '}in circles / кругами</RUS_3>
          <br /><JP>でんしょく</JP><RUS_3>{' '}decorative lights</RUS_3><JP>（電飾）</JP>
        </Grid>
           */
        }

      </Grid>
    </>
  );
};