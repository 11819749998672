import React, {useEffect, useState} from 'react';
import { Select, FormControl, InputLabel, MenuItem, Box, SelectChangeEvent } from "@mui/material";
import { JP, RUS } from "../../style";

type Props = {
  label: string;
  list: number[] | string[];
  selectedValue: string;
  handleSelectedValueChange: (value: string) => void;
  suffix?: string;
}

export const SelectInput: React.FC<Props> = (props) => {
  const { label, list, handleSelectedValueChange, suffix } = props;
  const [value, setValue] = useState(props.selectedValue || ''); // Local value

  const handleChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value as string;
    setValue(newValue as string);
    handleSelectedValueChange(newValue as string);
  };

  useEffect(() => {
    setValue(props.selectedValue);
  }, [props.selectedValue]);
//console.log('props.selectedValue', props.selectedValue)
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={handleChange}
          MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
        >
          {
            list.map((value, i) => {
              return (
                <MenuItem value={value} key={`menu-item-${i}`}>
                  <JP>
                    {value}{suffix || ''}
                  </JP>
                </MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
    </Box>
  );
};