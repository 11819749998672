import styled, { css } from 'styled-components';
import { ThemeProvider, createTheme, Theme} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Card } from "@mui/material";
import green from './test40.jpg';
import {colors} from "../../utils/palette";
type CardStyled = {
  item: any;
}

export const selectTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(145, 166, 255,0.2)',
          borderColor: 'rgba(145, 166, 255,1)',
          border: 0,

        },
        input: {
          '&.Mui-focused': {
            outline: '1px solid red'
          }
        },
      }
    }
  },
});

export const CardStyled = styled.div((props: CardStyled) => {
  return `
    
    background-color: rgba(255, 255, 255, .3); // rgba(100, 149, 237, .3);
    padding: 3vh;

    transition-property: background-color;
    transition-duration: 1s;
    
    display: flex;
    align-items: center;
    
    &:not(:last-child) {
      margin-right: 5px;
    }
   
    
    &:hover {
      background-color: darkseagreen; // #29B6F6;
      cursor: pointer;

      transition-property: background-color;
      transition-duration: 1s;
    }
  `;
});

export const EditIconStyled = styled(EditIcon)`
  color: cornflowerblue;
  //filter: drop-shadow(0 0 2px rgba(100, 149, 237, .7));

  position: absolute;
  bottom: 10px;
  right: 38px;

  &:hover {
    cursor: pointer;
    color: darkblue;
  }
`;

export const AddIconStyled_2 = styled.div`
  color: black; // cornflowerblue;
  //filter: drop-shadow(0 0 2px rgba(100, 149, 237, .7));
  
  position: absolute;
  bottom: 0.2em;
  left: 0.5em;
  
  //border: 1px solid red;
  font-size: 1.3rem;
  
  &:hover {
    cursor: pointer;
    color: darkblue;
  }
`;

export const AddIconStyled = styled(AddIcon)`
  color: deepskyblue;
  //filter: drop-shadow(0 0 2px rgba(100, 149, 237, .7));
  
  position: absolute;
  bottom: 5px;
  right: 5px;
  
  &:hover {
    cursor: pointer;
    color: darkblue;
  }
`;

export interface FlashcardWrapperStyleInterface {
    size?: number;
}

export const FlashcardWrapperStyle = styled.div<FlashcardWrapperStyleInterface>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  //margin: 5px;
  
  //border: 1px solid red;

  //height: 250px;
  //min-width: 250px;


  //box-sizing: border-box;
  //flex-grow: ${({ size }) => size ? size : 0};
  //flex-shrink: 1;
  //flex-basis: auto;
  
  //border: 1px solid salmon;
  
  &:hover {
    cursor: pointer;
  }
`;

export const FlashcardWrapperItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  //border: 1px solid red;

  //height: 350px;
  
  flex: 0 1 100%;

  position: relative;
  
  &:nth-child(2) {
    padding: 5px;
  }
  
`;

export interface FlashcardStyleInterface {
    size?: number;
}

export const FlashcardStyle = styled.div<FlashcardStyleInterface>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;

  //margin: 5px;

  // pink #ffaaab

  color: black;
  border-width: 1px;
  border-color: rgba(145, 166, 255,1); // #cc8b8b; // forestgreen; // cornflowerblue; // rgba(177, 227, 248, .5)
  border-style: solid;

  border-radius: 4px;
  // box-shadow: 0 0 5px 2px rgba(100, 149, 237, .3);
  // rgba(191,210,140, 1)
  box-shadow: 0 0 5px 2px rgba(145, 166, 255,0.2);//rgba(191,210,140, .1); // 145, 166, 255

  // #74F2CE, #7CFFCB
  background: linear-gradient(to right, rgba(145, 166, 255,0.5), rgba(145, 166, 255,0.2)); // #91A6FF
  // background-image: url(${green});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  //background-color: white;
  //color: ${colors.apple.pink.dark};

  // background-color: aliceblue; rgba(194, 220, 219, 1);
  // #ebf6f5; // #C2DCDB; // 
  //background-color: aliceblue;

  height: 155px;
  min-width: ${({size}) => size ? size === 1 ? '230px' : '400px' : '230px'};
  //width: 100%;


  box-sizing: border-box;
  flex-grow: ${({size}) => size ? size : 1};
  flex-shrink: 1;
  flex-basis: auto;

  transform-style: preserve-3d;
  transition: 1500ms;
  transition-duration: 1000ms;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0)) translateY(var(--translate-y, 0));

  cursor: pointer;

  &.answer {
    --rotate-y: 180deg;
  }

  & .front,
  & .back {
    position: absolute;
    padding: 1rem;
    backface-visibility: hidden;

    //border: 1px solid red;


  }

  & .icon {
    backface-visibility: hidden;
  }

  & .back {
    transform: rotateY(180deg);
  }

  &:hover {
    box-shadow: 0 0 5px 2px  ${'rgba(191,210,140, .2)'}; // rgba(87, 139, 232, 0.3);
    //background-color: ${colors.apple.pink.light}; // lightblue;
    --translate-y: -2px;
  }
`;